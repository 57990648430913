import {Component, OnInit, ViewChild} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import {PricingService} from "../../../shared/services/pricing.service";
import {SpecialPricingGroupFormComponent} from "../special-pricing-group-form/special-pricing-group-form.component";

@Component({
    selector: 'app-special-pricing-group-update',
    templateUrl: './special-pricing-group-update.component.html',
})

export class SpecialPricingGroupUpdateComponent implements OnInit {

    @ViewChild(SpecialPricingGroupFormComponent, {static: false}) form: SpecialPricingGroupFormComponent;

    loading = false;

    constructor(
        public pricingService: PricingService,
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        titleService: Title
    ) {
        titleService.setTitle('Special group update');
    }

    afterSave() {
        this.router.navigate(['/pricing-special']).then();
    }

    ngOnInit() {
        let id = parseInt(this.route.snapshot.params['id']);
        this.loading = true;
        this.pricingService.oneSpecialGroup(id).subscribe(group => {
            group.bundles = [];
            group.tiers = [];
            const users = group.users;
            group.users = [];
            const errorHandler = (err) => {
                this.loading = false;
                this.notificationService.error({
                    title: 'General pricing',
                    message: 'An error occurred while loading the bundles/tiers',
                    serviceName: 'GATEWAY',
                    requestMessage: err.statusText,
                    requestCode: err.status,
                    ts: err.timestamp ? err.timestamp : null
                });
            };
            if (group.paymentType === 'PREPAID') {
                this.pricingService.bundles(id).subscribe(items => {
                    group.bundles = items.map(item => {
                        item.serviceType = item.serviceType === 'VOICE' ? 'CLI' : item.serviceType;
                        return item;
                    });;
                    this.form.model = group;
                    this.form.updatePackagesCount();
                    // @TODO check ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'false'. Current value: 'true'
                    setTimeout(() => {
                        users.map(_ => this.form.onSelectUser(_));
                    }, 100)
                    this.loading = false;
                }, errorHandler);
            } else {
                this.pricingService.tiers(id).subscribe(items => {
                    group.tiers = items.map(item => {
                        item.serviceType = item.serviceType === 'VOICE' ? 'CLI' : item.serviceType;
                        return item;
                    });;
                    this.form.model = group;
                    this.form.updatePackagesCount();
                    // @TODO check ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'false'. Current value: 'true'
                    setTimeout(() => {
                        users.map(_ => this.form.onSelectUser(_));
                    }, 100)
                    this.loading = false;
                }, errorHandler);
            }

        }, error => {
            this.loading = false;
            this.notificationService.error({
                title: 'Special pricing',
                message: 'An error occurred while loading the pricing group',
                serviceName: 'GATEWAY',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
        })
    }
}
