import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ScheduledTasksCollection,
    ScheduledTask,
    ScheduledTaskReportRule,
    ReportsCollection,
    Report
} from '../models/scheduled-task.model';
import { getItemLabel, MESSAGE_STATES, LIVE_NUMBER_TEST_STATUSES } from '../models/test-group.model';
import { RestUtils } from './rest-utils';
import { map, switchMap } from "rxjs/operators";
import { LocalStorageService } from "./localStorage.service";
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { TestCaseTemplatesService } from './test-case-template.service';
import { TestCaseTemplate } from '../models/test-case-template.model';
import { CustomUtils } from './custom-utils';
declare var moment: any;

@Injectable()
export class SchedulerService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    private actionSubject: Subject<SchedulerTaskActionData> = new Subject<SchedulerTaskActionData>();
    action$ = this.actionSubject.asObservable();

    static SCHEDULER_STATUSES = ['RUNNING', 'SCHEDULED', 'STOPPED', 'FINISHED'];
    static DAYS = [
        {
            label: 'S',
            value: 'SUNDAY'
        },
        {
            label: 'M',
            value: 'MONDAY'
        },
        {
            label: 'T',
            value: 'TUESDAY'
        },
        {
            label: 'W',
            value: 'WEDNESDAY'
        },
        {
            label: 'T',
            value: 'THURSDAY'
        },
        {
            label: 'F',
            value: 'FRIDAY'
        },
        {
            label: 'S',
            value: 'SATURDAY'
        }
    ];

    static HIDDEN_STORAGE_KEY = 'sch-hidden-Mode'
    static SHOW_MY_TASKS_FLAG_KEY = 'sch-show-my-tasks-flag'

    constructor(http: HttpClient, public storage: LocalStorageService, private testCaseTemplateService: TestCaseTemplatesService) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    announceAction(action: SchedulerTaskActionData) {
        this.actionSubject.next(action);
    }

    static handleReportRuleDto(currentRow: Report) {
        const reportRuleDto: ScheduledTaskReportRule = currentRow.reportRuleDto;
        const messageState = reportRuleDto.messageState ?? '';
        const testStatus = reportRuleDto.testStatus ?? '';
        const repetitionsTarget = reportRuleDto.repetitionsTarget ?? '-';

        let messageStateLabel = MESSAGE_STATES.find(state => state.id === messageState)?.label ?? '-';
        let testStatusLabel = LIVE_NUMBER_TEST_STATUSES.find(state => state.id === testStatus)?.label ?? '-';

        if (reportRuleDto.messageStateWaitingTime && messageStateLabel !== '-') {
            const messageStateTime = SchedulerService.secondsToHuman(reportRuleDto.messageStateWaitingTime);
            messageStateLabel += (' >= ' + messageStateTime.value + messageStateTime.unit);
        }
        if (reportRuleDto.testStatusWaitingTime && testStatusLabel !== '-') {
            const testStatusTime = SchedulerService.secondsToHuman(reportRuleDto.testStatusWaitingTime);
            testStatusLabel += (' >= ' + testStatusTime.value + testStatusTime.unit);
        }

        const messageStateIcon = messageState ? `<i class="fs-3 me-0_5 ${CustomUtils.getIconForStatus(messageState)}"></i>` : '';
        const testStatusIcon = testStatus ? `<i class="fs-3 mx-0_5 ${CustomUtils.getIconForStatus(testStatus)}"></i>` : '';

        return `
        <div class="d-flex justify-content-between align-items-center">
            ${messageStateIcon}${messageStateLabel} /
            ${testStatusIcon}${testStatusLabel} / 
            ${repetitionsTarget} test reps
        </div>`;
    }

    static formatReportRule(rule: ScheduledTaskReportRule): string {
        const messageState = rule.messageState;
        const testStatus = rule.testStatus;
        const repetitionsTarget = rule.repetitionsTarget ?? '1';

        let messageStateLabel = MESSAGE_STATES.find(state => state.id === messageState)?.label ?? '-';
        let messageStateIcon = messageState ? `<i class="fs-3 me-0_5 ${CustomUtils.getIconForStatus(messageState)}"></i>` : '';

        let testStatusLabel = LIVE_NUMBER_TEST_STATUSES.find(state => state.id === testStatus)?.label ?? '-';
        let testStatusIcon = testStatus ? `<i class="fs-3 me-0_5 ${CustomUtils.getIconForStatus(testStatus)}"></i>` : '';

        if (rule.messageStateWaitingTime && messageStateLabel !== '-') {
            const messageStateTime = SchedulerService.secondsToHuman(rule.messageStateWaitingTime);
            messageStateLabel += (' >= ' + messageStateTime.value + messageStateTime.unit);
        }
        if (rule.testStatusWaitingTime && testStatusLabel !== '-') {
            const testStatusTime = SchedulerService.secondsToHuman(rule.testStatusWaitingTime);
            testStatusLabel += (' >= ' + testStatusTime.value + testStatusTime.unit);
        }
        const messageStatePill = `
        <div class="pill me-10">
            ${messageStateIcon}
            <span class="text-neutral-n9"> ${messageStateLabel}</span>
        </div>
        `;
        const testStatusPill = `
        <div class="pill me-10">
                ${testStatusIcon}
                <span class="text-neutral-n9"> ${testStatusLabel}</span>
            </div>
        `;
        return `
        <div class="d-flex justify-content-start align-items-center mb-10">
            ${messageState ? messageStatePill : ''}
            ${messageState && testStatus ? '<span class="me-10">AND</span>' : ''}
            ${testStatus ? testStatusPill : ''}
            x ${repetitionsTarget}
        </div>
        `;
    }

    all(params: SchedulerRequestParams) {
        let queryParams = {
            page: params.page,
            size: params.size,
            hidden: params.hidden ? 'true' : 'false',
            ownTasksOnly: params.ownTasksOnly ? 'true' : 'false',
            searchString: params.search ? params.search : '',
            sort: params.sort.length ? params.sort : [],
        };

        let url = this.utils.buildUrl('ROLE/sch/tasks', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<ScheduledTasksCollection>(url, options).pipe(map(_ => {
            const collection = _;
            collection.content.map(task => {
                if (task.finishAt === '1970-01-01T00:00:00Z') {
                    task.finishAt = null;
                }
                if (task.daysOfWeek === null) {
                    task.daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
                }
                return task;
            });
            return collection;
        }));
    }

    search(params: SchedulerRequestParams) {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: params.sort.length ? params.sort : [],
        };
        let url = this.utils.buildUrl('ROLE/sch/tasks/search', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        const searchData = params.searchData ? params.searchData : this.getEmptySearchObject();

        return this.http.post<ScheduledTasksCollection>(url, searchData, options).pipe(map(_ => {
            const collection = _;
            collection.content.map(task => {
                if (task.finishAt === '1970-01-01T00:00:00Z') {
                    task.finishAt = null;
                }
                if (task.daysOfWeek === null) {
                    task.daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
                }
                return task;
            });
            return collection;
        }));
    }

    reportsAll(params: SchedulerReportRequestParams) {
        let queryParams = {
            page: params.page,
            size: params.size,
            searchString: params.search ? params.search : '',
            sort: (params.sort && params.sort.length) ? params.sort : []
        };

        let url = this.utils.buildUrl('ROLE/sch/reports', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        if (params.searchData) {
            const isSearchDataPresent = Object.values(params.searchData).filter(v => v !== null && v?.length > 0).length > 0;
            if (isSearchDataPresent) {
                return this.http.post<ReportsCollection>(url, params.searchData, options);
            }
        }
        return this.http.get<ReportsCollection>(url, options);
    }

    one(id: number) {
        let url = this.utils.buildUrl(`ROLE/sch/tasks/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<ScheduledTask>(url, options).pipe(map(_ => {
            const task = _;
            if (task.finishAt === '1970-01-01T00:00:00Z') {
                task.finishAt = null;
            }
            if (task.daysOfWeek === null) {
                task.daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
            }
            return task;
        }));
    }

    save(task: ScheduledTask, startNow = false) {
        let url = this.utils.buildUrl(`ROLE/sch/tasks`, { startNow: startNow ? 'true' : 'false' });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<ScheduledTask>(url, task, options);
    }

    hidden(taskId: number, hidden: boolean) {
        let url = this.utils.buildUrl(`ROLE/sch/tasks/${taskId}/hidden`, { hidden: hidden ? 'true' : 'false' });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, { hidden: hidden }, options);
    }

    stop(taskId: number) {
        let url = this.utils.buildUrl(`ROLE/sch/tasks/${taskId}/stop`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, {}, options);
    }

    schedule(taskId: number) {
        let url = this.utils.buildUrl(`ROLE/sch/tasks/${taskId}/schedule`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, {}, options);
    }

    getTaskSettings(scheduledTaskId: number): Observable<{ task: ScheduledTask, template: TestCaseTemplate }> {
        const scheduleTaskId = scheduledTaskId;
        return this.one(scheduleTaskId).pipe(
            switchMap((task: ScheduledTask) => {
                return forkJoin({
                    task: of(task),
                    template: this.testCaseTemplateService.one(task.testCaseTemplateId)
                });
            })
        );
    }

    formatDuration(row: ScheduledTask) {
        if (row.sendOnlyOnce) {
            return '';
        }
        if (row.randomized) {
            return `<div class="two-lines">Randomized/(${row.repeatsPerDayRandomized} per day)</div>`;
        }
        if (row.repeatEvery) {
            let t = SchedulerService.secondsToHuman(row.repeatEvery);
            const map = {
                d: 'days',
                m: 'minutes',
                h: 'hours',
                s: 'seconds'
            };
            // Generate human-readable duration and remove articles
            let humanized = moment.duration(t.value, map[t.unit]).humanize();
            humanized = humanized.replace(/\ban?\s/, ''); // Remove "a " or "an "
            return humanized;
        }
        return '';
    }

    consolidateTaskSettings(task: ScheduledTask, template: TestCaseTemplate, report: Report) {
        const sortedDays = CustomUtils.sortDays(task.daysOfWeek);
        const taskOverview: TaskSettingDetail = {
            id: 'taskOverview',
            title: 'Task overview',
            data: [
                ['Task ID', task.id],
                ['Task name', task.title],
                ['Created date', moment(task.createdAt).format('DD MMM HH:mm')],
                ['Description', task.description],
                ['Start date', moment(task.startAt).format('DD MMM HH:mm')],
                ['Finish date', task.runUntilDisabled ? 'Run until disabled' : moment(task.finishAt).format('DD MMM HH:mm')],
                ['Status', this.formatState(task)],
                ['Repeat every', this.formatDuration(task)],
                ['Repeat days', sortedDays.length === 7 ? 'Everyday' : sortedDays.join(', ')],
                ['Time (From-Till)', this.getTimeValues(task)],
            ]
        }
        if (task.sendOnlyOnce) {
            taskOverview.data = taskOverview.data.filter(_ => !(['Finish date', 'Repeat every', 'Repeat days', 'Time (From-Till)'].includes(_[0])));
        }
        let contentText = template.contentTexts[0];
        if (!contentText) {
            const telqTextDetails = template.dynamicTexts[0];
            contentText = {
                senderId: template.senders[0],
                text: telqTextDetails.text,
                telqIdType: telqTextDetails.telqIdType,
                telqIdCase: telqTextDetails.telqIdCase,
                telqIdLength: telqTextDetails.telqIdLength
            }
        }
        const contentTemplateOverView: TaskSettingDetail = {
            id: 'contentTemplateOverView',
            title: 'Content template',
            data: [
                ['Sender', contentText.senderId],
                ['Text', contentText.text],
                ['TelQ ID type', contentText.telqIdType],
                ['TelQ ID case', contentText.telqIdCase],
                ['TelQ ID length', contentText.telqIdLength],
            ]
        }
        const destinationDtos = template.destinationDtos;
        const countriesOverView: TaskSettingDetail = {
            id: 'contriesOverView',
            title: 'Country/Network',
            data: [
                [
                    'Country (MCC) / Network (MNC)',
                    destinationDtos.map(dto => {
                        if (dto.manualNumber) {
                            return `<span title="Manual number">${dto.phone}</span>`;
                        }
                        return `${dto.countryName} (${dto.mcc}) / ${dto.providerName} (${dto.mnc})`;
                    }).join('<br>')
                ],
            ]
        };
        if (report) {
            const supplierDto = report.supplierDto;
            const supplierOverView: TaskSettingDetail = {
                id: 'supplierOverView',
                title: 'Supplier',
                data: [
                    ['Name', `${supplierDto.title} (${supplierDto.id})`],
                ]
            }
            const reasonForReportOverView: TaskSettingDetail = {
                id: 'reasonForReportOverView',
                title: 'Reason for report',
                data: [
                    ["Reason for report", SchedulerService.formatReportRule(report.reportRuleDto)]
                ]
            }
            return [taskOverview, contentTemplateOverView, countriesOverView, supplierOverView, reasonForReportOverView];
        } else {
            const reasonForReportOverView: TaskSettingDetail = {
                id: 'reasonForReportOverView',
                title: 'Reason for report',
                data: [
                    ["Reason for report", this.getReportRulesForTask(task.reportRuleDtos)],
                    ["Report emails", task.reportEmails.join(', ')]
                ]
            }
            return [taskOverview, contentTemplateOverView, countriesOverView, reasonForReportOverView];
        }
    }

    getTimeValues(task: ScheduledTask): string {
        let value = `${CustomUtils.decimalToTimeFormat(Number(task.fromHour))} - ${CustomUtils.decimalToTimeFormat(Number(task.tillHour))}`
        if (!CustomUtils.isOffsetInBrowserTimezone(task.zoneOffsetSec)) {
            value += '<span class="icon-help-1 text-neutral-n6 ms-10 fs-3" title="The timezone where this task was created is different."></span>';
        }
        return `<div class="d-flex align-items-center"> ${value}</div>`;
    }

    getReportRulesForTask(reportRules: ScheduledTaskReportRule[]): string {
        return reportRules.map(rule => SchedulerService.formatReportRule(rule)).join('');
    }

    formatState(task: ScheduledTask): string {
        switch (task.state) {
            case "SCHEDULED":
                return `<div class="one-line text-accent-a6"><i class="fas fa-history me-0_8"></i>Scheduled</div>`;
            case "WAIT":
                return `<div class="one-line text-primary-p4"><i class="icon-wait me-0_8"></i>Waiting</div>`;
            case "RUNNING":
                return `<div class="one-line text-primary-p4"><i class="icon-wait me-0_8"></i>Running</div>`;
            case "STOPPED":
                return `<div class="one-line text-error-r2"><i class="fas fa-stop me-0_9"></i>Stopped</div>`;
            case "PAUSED":
                return `<div class="one-line text-warning-p4"><i class="fas fa-pause me-0_8"></i>Paused</div>`;
            case "FINISHED":
                return `<div class="one-line"><i class="icon-check me-0_7"></i>Completed</div>`;
            default:
                break;
        }
    }

    create(): ScheduledTask {
        return {
            id: null,
            title: '',
            sendOnlyOnce: true,
            state: null,
            repeatEvery: 60 * 5,
            reportFrequency: 'ALL',
            reportEmails: [],
            daysOfWeek: [],
            reportRules: [],
            userId: null,
            testCaseTemplateId: null,
            fromHour: null,
            tillHour: null,
            reportPerAlertEnabled: true,
            allAlertXlsReportEnabled: false,
            repetitionAlertXlsReportEnabled: false,
            allTestXlsReportEnabled: false,
            testRepeatsPerIteration: 1,
            callbackUrl: null
        };
    }

    getHiddenMode(): boolean {
        return this.storage.get(SchedulerService.HIDDEN_STORAGE_KEY, false);
    }

    saveHiddenMode(hidden: boolean) {
        this.storage.set(SchedulerService.HIDDEN_STORAGE_KEY, hidden);
    }

    getShowMyTasksFlag(): boolean {
        return this.storage.get(SchedulerService.SHOW_MY_TASKS_FLAG_KEY, false);
    }

    saveShowMyTasksFlag(showMyTasks: boolean) {
        this.storage.set(SchedulerService.SHOW_MY_TASKS_FLAG_KEY, showMyTasks);
    }

    getEmptySearchObject(): SchedulerSearchEvent {
        return {
            ids: null,
            statuses: null,
            names: null,
            startDateFrom: null,
            startDateTo: null,
            finishDateFrom: null,
            finishDateTo: null,
            countryNetworks: null,
            suppliers: null,
            schedulerUserIds: null,
            hidden: null,
            ownTasksOnly: null,
        };
    }

    static repeatEveryToSeconds(value: number, unit: string): number {
        if (unit === 'unlimited') {
            return null;
        }

        const map = {
            s: 1,
            m: 60,
            h: 3600,
            d: 3600 * 24
        };

        return value * map[unit];
    }

    static secondsToHuman(seconds: number) {
        if (seconds === null) {
            return { value: null, unit: 'unlimited' };
        }
        if (seconds < 60) {
            return { value: seconds, unit: 's' };
        }
        if (seconds < 3600) {
            return { value: seconds / 60, unit: 'm' };
        }
        if (seconds < (3600 * 24)) {
            return { value: seconds / 3600, unit: 'h' };
        }

        return { value: seconds / (3600 * 24), unit: 'd' };
    }

    static calculateSchedule(task: ScheduledTask, countTests: number = 0, limit: number = 10): TaskScheduleDef {
        let result = {
            countTasks: 0,
            countTests: 0,
            history: []
        };

        if (task.sendOnlyOnce && !task.startAt) {
            return result;
        }

        if (task.startAt && !task.finishAt) {
            const startAt = typeof task.startAt === 'number' ? task.startAt : Date.parse(task.startAt) / 1000;
            return {
                countTasks: 1,
                countTests: countTests,
                history: [
                    new Date(startAt * 1000)
                ]
            };
        }

        if (!parseInt(String(task.repeatEvery)) || !task.startAt || !task.finishAt || !task.daysOfWeek || !task.daysOfWeek.length) {
            return result;
        }

        const startAt = typeof task.startAt === 'number' ? task.startAt : Date.parse(task.startAt) / 1000;
        const finishAt = typeof task.finishAt === 'number' ? task.finishAt : Date.parse(task.finishAt) / 1000;
        const now = Date.now() / 1000;

        task.fromHour = task.fromHour || 0;
        task.tillHour = task.tillHour || 24;
        const taskFromHour = typeof task.fromHour === 'number' ? task.fromHour : (+ (task.fromHour.replace(':', '.')));
        const taskTillHour = typeof task.tillHour === 'number' ? task.tillHour : (+ (task.tillHour.replace(':', '.')));
        const hoursCheck = taskFromHour >= 0 && taskTillHour >= 0;

        if (finishAt <= now) {
            return result;
        }

        const dayMap = {
            SUNDAY: 0,
            MONDAY: 1,
            TUESDAY: 2,
            WEDNESDAY: 3,
            THURSDAY: 4,
            FRIDAY: 5,
            SATURDAY: 6
        };

        let repeatEveryRecalculated = task.repeatEvery;
        const validDays = task.daysOfWeek.map(_ => dayMap[_]);

        // Calculating total task number for randomized schelued task
        if (task.randomized) {
            const fromHoursMinutes = task.fromHour.toString().split(':').map(_ => _.padStart(2, '0'));
            const tillHoursMinutes = task.tillHour.toString().split(':').map(_ => _.padStart(2, '0'));

            const currentTime = new Date();
            const configuredStart = new Date(startAt * 1000);

            // checks if the configured start time actually earlier than the current time
            // in this case the actual start should be chosen as current time  
            const start = configuredStart < currentTime ? currentTime : configuredStart;
            const finish = new Date(finishAt * 1000);

            let tillHourToday = new Date(start.getTime());
            tillHourToday.setHours(parseInt(tillHoursMinutes[0]));
            tillHourToday.setMinutes(parseInt(tillHoursMinutes.length === 1 ? '0' : tillHoursMinutes[1]));
            tillHourToday.setSeconds(0);
            tillHourToday.setMilliseconds(0);

            let fromHourToday = new Date(start.getTime());
            fromHourToday.setHours(parseInt(fromHoursMinutes[0]));
            fromHourToday.setMinutes(parseInt(fromHoursMinutes.length === 1 ? '0' : fromHoursMinutes[1]));
            fromHourToday.setSeconds(0);
            fromHourToday.setMilliseconds(0);

            // We choose from hour as start time if it is later than the current time. 
            // This means that the test is expected to start later than scheduled. 
            let lastScheduledTime = new Date(fromHourToday.getTime());

            // calculate periods between tests in seconds
            repeatEveryRecalculated = ((tillHourToday.getTime() - fromHourToday.getTime()) / 1000) / task.repeatsPerDayRandomized;

            const differenceMinutes = (tillHourToday.getTime() - fromHourToday.getTime()) / (60 * 1000);
            if (task.repeatsPerDayRandomized > differenceMinutes) {
                return result;
            }

            if (currentTime >= finish) {
                return result;
            }

            const ONE_DAY_MILLIS = 24 * 3600 * 1000;

            let lastScheduledTimeMillis = lastScheduledTime.getTime();
            let fromHourTodayMillis = fromHourToday.getTime();
            let tillHourTodayMillis = tillHourToday.getTime();

            while (lastScheduledTimeMillis < finish.getTime()) {

                // Skip calculating values in the past 
                if (lastScheduledTimeMillis < start.getTime()) {
                    lastScheduledTimeMillis += repeatEveryRecalculated * 1000;
                    continue;
                }

                // Check if the current day should not be considered and the time of the last scheduled task
                // is not later than the till hour, otherwise skips calculations for the current day and shifts to the next day 
                if (lastScheduledTimeMillis >= tillHourTodayMillis ||
                    validDays.indexOf(new Date(lastScheduledTimeMillis).getDay()) === -1
                ) {
                    fromHourTodayMillis += ONE_DAY_MILLIS;
                    tillHourTodayMillis += ONE_DAY_MILLIS;
                    lastScheduledTimeMillis = fromHourTodayMillis;
                    continue;
                }

                // if latest scheduled task is before the tillHour and finish time 
                // we increase counter of tests and tasks 
                if (lastScheduledTimeMillis < tillHourTodayMillis) {
                    result.countTasks++
                    result.countTests += countTests;
                    lastScheduledTimeMillis += repeatEveryRecalculated * 1000;
                }

                if (task.runUntilDisabled) {
                    return {
                        countTasks: result.countTasks > 0 ? 'Unlimited' : 0,
                        countTests: result.countTests > 0 ? 'Unlimited' : 0,
                        history: []
                    }
                }
            }
            return result;
        }

        let next = (task: ScheduledTask, start: number): Date => {
            while (true) {
                start += repeatEveryRecalculated;
                if (start >= finishAt) {
                    break;
                }
                let startDate = new Date(start * 1000);
                if (validDays.indexOf(startDate.getDay()) === -1) {
                    continue;
                }
                let h = startDate.getHours();
                let m = String(startDate.getMinutes()).padStart(2, '0');
                let hm = +(`${h}.${m}`);
                if (hoursCheck && !(hm >= taskFromHour && hm <= taskTillHour)) {
                    continue;
                }
                return startDate;
            }
            return null;
        };

        let startNow = now <= startAt ? startAt - repeatEveryRecalculated : startAt, nextAt: Date = null;
        while (true) {
            nextAt = next(task, startNow);
            if (nextAt === null) {
                break;
            }
            startNow = nextAt.getTime() / 1000;
            if (now >= startNow) {
                continue;
            }
            if (limit && result.history.length < limit) {
                result.history.push(nextAt);
            }
            result.countTasks++;
            if (countTests) {
                result.countTests += countTests;
            }
            if (task.runUntilDisabled && result.history.length >= limit) {
                break;
            }
        }
        if (task.runUntilDisabled) {
            return {
                countTasks: result.history.length > 0 ? 'Unlimited' : 0,
                countTests: result.history.length > 0 ? 'Unlimited' : 0,
                history: result.history
            }
        }
        return result;
    }
}



export interface TaskScheduleDef {
    countTasks: number | string,
    countTests: number | string,
    history: Date[]
}

export interface CombinationRuleItem {
    id: number;
    messageState: string;
    messageStateWait: number;
    messageStatusDropdownShow?: boolean;
    testStatus: string;
    testStatusDropdownShow?: boolean;
    testStatusWait: number;
    repetitionsTarget: number;
    repetitionsTargetDropdownShow?: boolean;
    isConsecutive: boolean;
    isConsecutiveDropdownShow?: boolean;
}

export interface StatusItem {
    id: string;
    isSelectedInCombination?: boolean;
    label: string;
    selected?: boolean;
    wait?: number;
}

export class AllRequestParams {
}

export class SchedulerRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;
    searchData: SchedulerSearchEvent;

    hidden: boolean = false;
    ownTasksOnly: boolean = false;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

export class SchedulerReportRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;
    searchData: SchedulerReportSearchEvent;

    hidden: boolean = false;
    ownTasksOnly: boolean = false;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

export interface SchedulerSearchEvent {
    ids: string[];
    statuses: string[];
    names: string[];
    startDateFrom: string;
    startDateTo: string;
    finishDateFrom: string;
    finishDateTo: string;
    countryNetworks: string[];
    suppliers: string[];
    schedulerUserIds: number[];
    hidden: boolean;
    ownTasksOnly: boolean;
}


export interface SchedulerReportSearchEvent {
    ids: string[];
    testIds: string[];
    title: string;
    scheduleName: string;
    testNumbers: string[];
    createAtFrom: string;
    createdAtTo: string;
}

export interface SchedulerTaskActionData {
    name: string;
    row: ScheduledTask;
    column: string;
}

export interface SchedulerStatus {
    label: string;
    value: string;
    isChecked: boolean;
    icon: string;
}

export interface TaskSettingDetail {
    id: string,
    title: string,
    data: any
}
