import { Component, OnDestroy } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { VoiceTestResult } from '../../../../shared/models/voice-testing.model';
import { CustomUtils } from '../../../../shared/services/custom-utils';
import { VoiceTestingService } from '../../../../shared/services/voice-testing.service';

@Component({
    selector: 'app-vt-results-table-status',
    templateUrl: './vt-results-table-status.component.html',
    styleUrls: ['./vt-results-table-status.component.scss']
})
export class VtResultsTableStatusComponent implements OnDestroy {

    constructor(private service: VoiceTestingService) { }

    private intervalId: any;
    timeInSeconds: number = 0;
    currentRow: VoiceTestResult;

    agInit(params: ICellRendererParams): void {
        this.currentRow = params.data;
        const differenceInSeconds = this.calculateDifferenceInSeconds(this.currentRow.createdAt);
        this.timeInSeconds = this.currentRow.testGroupTtl - differenceInSeconds;
        if (this.timeInSeconds > 0) {
            this.startTimer();
        }
    }

    ngOnDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    startTimer() {
        this.intervalId = setInterval(() => {
            if (this.timeInSeconds > 0) {
                this.timeInSeconds--;
            } else {
                clearInterval(this.intervalId);
            }
        }, 1000);
    }

    formatStatus() {
        const _ = this.currentRow;
        if (_.testStatus === 'WAIT') {
            if (this.timeInSeconds <= 0) {
                return `<div class="h-100 fs-2 icon" style="width:20px;height:20px"><span class="far fa-frown text-error-r3"></span>`;
            }
            return `<div class="one-line d-flex justify-content-center align-items-center ${this.timeInSeconds < 30 ? 'text-error-r3' : ''} ">
                        <span class="icon-wait spin fs-3 me-0_5"></span> 
                        ${this.formatTime()}
                    </div>`;
        }
        const statusDef = {
            wait: `<span class="${CustomUtils.getIconForStatus('WAIT')}" title="Wait"></span>`,
            received: `<span class="${CustomUtils.getIconForStatus('RECEIVED')}" title="Received"></span>`,
            number_offline: `<span class="${CustomUtils.getIconForStatus('NUMBER_OFFLINE')}" title="Test number offline"></span>`,
            network_offline: `<span class="${CustomUtils.getIconForStatus('NETWORK_OFFLINE')}" title="Network offline"></span>`,
            expired: `<span class="${CustomUtils.getIconForStatus('EXPIRED')}" title="Expired"></span>`,
            def: `<span class="${CustomUtils.getIconForStatus('WAIT')}" title="Wait"></span>`,
            internal_error: `<span class="${CustomUtils.getIconForStatus('INTERNAL_ERROR')}" title="Internal error happend during test execution, you won\'t be charged. Try to re-execute the test."></span>`,
        };
        const key = typeof statusDef[_.testStatus.toLowerCase()] !== 'undefined' ? _.testStatus.toLowerCase() : 'def';
        return `<div class="h-100 fs-2 icon" style="width:20px;height:20px">${statusDef[key]}</div>`;
    }

    formatTime(): string {
        const minutes: number = Math.floor(this.timeInSeconds / 60);
        const remainingSeconds: number = this.timeInSeconds % 60;
        const minutesString = minutes < 10 ? '0' + minutes : minutes.toString();
        const secondsString = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds.toString();
        return minutesString + ':' + secondsString;
    }

    calculateDifferenceInSeconds(timestamp: string): number {
        const timestampDate = new Date(timestamp);
        const now = new Date();
        const differenceInMilliseconds = now.getTime() - timestampDate.getTime();
        return Math.floor(differenceInMilliseconds / 1000);
    }

}
