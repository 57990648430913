import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Filter } from '../../../shared/filter/filter';
import { BasicUser } from '../../../shared/models/user.model';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { SpecialPricingSearchEvent } from '../../../shared/services/pricing.service';
import { UsersService } from '../../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';
import { UsersSelectComponent } from '../../../users/users-select/users-select.component';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';

@Component({
    selector: 'app-special-pricing-group-search',
    templateUrl: './special-pricing-group-search.component.html',
    styleUrls: ['./special-pricing-group-search.component.scss']
})
export class SpecialPricingGroupSearchComponent implements OnInit {

    @Output() onSubmit = new EventEmitter<SpecialPricingSearchEvent>();
    @ViewChild(UsersSelectComponent, { static: false }) userComponent: UsersSelectComponent;

    searchFormControl: FormControl = new FormControl();
    searchSubscription: Subscription;
    isBeingDragged: boolean = false;
    filters: Filter[] = [
        { filterKey: 'id', title: 'ID', show: true },
        { filterKey: 'userEmail', title: 'Customers', show: true },
        { filterKey: 'paymentType', title: 'Type', show: true },
        { filterKey: 'title', title: 'Title', show: true },
        { filterKey: 'serviceList', title: 'Products Active', show: true },
        { filterKey: 'enabled', title: 'Deactivate/ revive offer', show: true },
        { filterKey: 'scheduledChange', title: 'New pricing scheduled', show: true },
        { filterKey: 'status', title: 'Status', show: true },
    ];
    form: FormGroup;

    constructor(
        private localStorage: LocalStorageService,
        private usersService: UsersService,
        public validationService: ValidationService,
        private formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            id: [null, Vld.compose([Vld.digitsAndSpaces(true), Vld.maxLength(255)])],
            title: [null],
            paymentType: [null],
            enabled: [null],
            userEmail: [null],
            serviceList: [null],
            status: [null],
            scheduledChange: [null],
        });
        this.searchSubscription = this.searchFormControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(_ => {
            this.onSubmitClick();
        });
    }

    get serviceList(): string[] {
        return this.form.get('serviceList').value || [];
    }

    get paymentType(): string[] {
        return this.form.get('paymentType').value || [];
    }

    get status(): string[] {
        return this.form.get('status').value || [];
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.filters, event.previousIndex, event.currentIndex);
        this.localStorage.set(`special_pricing_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    getPeekForSelectedValues(filter): number | string | null {
        switch (filter.filterKey) {
            case 'userEmail':
                return this.form.get('userEmail').value?.length;
            default:
                return null;
        }
    }

    isFilterActive(filter: Filter): boolean {
        switch (filter.filterKey) {
            case 'id':
                return this.form.get('id').value !== null;
            case 'title':
                return this.form.get('title').value !== null;
            case 'paymentType':
                return this.form.get('paymentType').value?.length > 0;
            case 'enabled':
                return this.form.get('enabled').value !== null;
            case 'userEmail':
                return this.form.get('userEmail').value?.length > 0;
            case 'serviceList':
                return this.form.get('serviceList').value?.length > 0;
            case 'scheduledChange':
                return this.form.get('scheduledChange').value !== null;
            case 'status':
                return this.form.get('status').value?.length > 0;
            default:
                return false;
        }
    }

    onClear(filterKey: string) {
        switch (filterKey) {
            case 'id':
                this.form.get('id').setValue(null);
                break;
            case 'title':
                this.form.get('title').setValue(null);
                break;
            case 'paymentType':
                this.form.get('paymentType').setValue(null);
                break;
            case 'enabled':
                this.form.get('enabled').setValue(null);
                break;
            case 'userEmail':
                this.form.get('userEmail').setValue(null);
                this.userComponent?.resetAll();
                break;
            case 'serviceList':
                this.form.get('serviceList').setValue(null);
                break;
            case 'scheduledChange':
                this.form.get('scheduledChange').setValue(null);
                break;
            case 'status':
                this.form.get('status').setValue(null);
                break;
            default:
                break;
        }
        this.onSubmitClick();
    }

    onSubmitClick(filterKey: string = null) {
        let searchData = this.form.getRawValue();
        searchData.id = searchData.id ? searchData.id.split(',') : null;
        searchData.title = searchData.title ? searchData.title.split(',') : null;
        searchData.userEmail = searchData.userEmail?.length ? searchData.userEmail.map((user: BasicUser) => user.email) : null;
        searchData.paymentType = searchData.paymentType?.length ? searchData.paymentType : null;
        searchData.serviceList = searchData.serviceList?.length ? searchData.serviceList : null;
        searchData.status = searchData.status?.length ? searchData.status : null;
        searchData.searchString = this.searchFormControl.value;
        this.onSubmit.emit(searchData);
    }

    resetAll(): void {
        this.form.reset();
        this.userComponent?.resetAll();
        this.searchFormControl.setValue(null);
    }

    shouldAddFilterBeShown(): boolean {
        const hiddenFilters = this.filters.filter(_ => _.show === false);
        return hiddenFilters.length > 0;
    }

    deleteItem(event: CdkDragDrop<string[]>) {
        const indexToHide = event.previousIndex;
        this.filters[indexToHide].show = false;
        this.onClear(this.filters[indexToHide].filterKey);
        this.localStorage.set(`special_pricing_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    onAdditionalFilterClick(filter): void {
        filter.show = !filter.show;
        this.localStorage.set(`special_pricing_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    onClearAllFilter(): void {
        this.localStorage.set(`special_pricing_${this.usersService.authUser.id}_filter_state`, this.filters);
        this.resetAll();
    }

    onSelectUser(user: BasicUser) {
        const userControl: FormControl = this.form.get('userEmail') as FormControl;
        const selectedUsers = userControl.value || [];
        if (!selectedUsers.includes(user)) {
            userControl.setValue([...selectedUsers, user]);
        }
        this.onSubmitClick();
    }

    onUnSelectUser(user: BasicUser) {
        const userControl: FormControl = this.form.get('userEmail') as FormControl;
        const selectedUsers = userControl.value || [];
        userControl.setValue(selectedUsers.filter(_ => _.id !== user.id));
        this.onSubmitClick();
    }

    onChangePaymentSelection(paymentType: string): void {
        const paymentTypeFormControl: FormControl = this.form.get('paymentType') as FormControl;
        const selectedPaymentTypeValue = paymentTypeFormControl.value ? paymentTypeFormControl.value : [];
        if (selectedPaymentTypeValue.includes(paymentType)) {
            paymentTypeFormControl.setValue(selectedPaymentTypeValue.filter(_ => _ !== paymentType));
        } else {
            paymentTypeFormControl.setValue([...selectedPaymentTypeValue, paymentType]);
        }
        this.onSubmitClick();
    }

    onClickedEnabled(): void {
        const enabledFormControl: FormControl = this.form.get('enabled') as FormControl;
        enabledFormControl.setValue(enabledFormControl.value ? null : true);
        this.onSubmitClick();
    }

    onClickDisabled(): void {
        const enabledFormControl: FormControl = this.form.get('enabled') as FormControl;
        enabledFormControl.setValue(enabledFormControl.value === false ? null : false);
        this.onSubmitClick();
    }

    onChangeProducts(productName: string) {
        const serviceListFormControl: FormControl = this.form.get('serviceList') as FormControl;
        const serviceListValue = serviceListFormControl.value || [];
        if (serviceListValue.includes(productName)) {
            serviceListFormControl.setValue(serviceListValue.filter(_ => _ !== productName));
        } else {
            serviceListFormControl.setValue([...serviceListValue, productName]);
        }
        this.onSubmitClick();
    }

    onNewPricingScheduleChange(value: boolean) {
        const scheduledChangeFormControl: FormControl = this.form.get('scheduledChange') as FormControl;
        const previousValue = scheduledChangeFormControl.value;
        if (previousValue === value) {
            scheduledChangeFormControl.setValue(null);
        } else {
            scheduledChangeFormControl.setValue(value);
        }
        this.onSubmitClick();
    }

    onChangeStatus(status: string): void {
        const statusFormControl: FormControl = this.form.get('status') as FormControl;
        const selectedStatusValue = statusFormControl.value ? statusFormControl.value : [];
        if (selectedStatusValue.includes(status)) {
            statusFormControl.setValue(selectedStatusValue.filter(_ => _ !== status));
        } else {
            statusFormControl.setValue([...selectedStatusValue, status]);
        }
        this.onSubmitClick();
    }

    ngOnDestroy(): void {
        this.searchSubscription?.unsubscribe();
    }
}
