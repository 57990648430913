import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppscriptsSettingService } from "../../../shared/services/appscripts-setting.service";
import {
    AbstractControl,
    AbstractControlOptions,
    FormControl,
    FormGroup, ValidationErrors, ValidatorFn,
} from "@angular/forms";
import { ValidationService, Validators as V } from '../../../shared/services/validation.service';
import {
    AppscriptsSettingParsed, AppscriptsSettingUpdate, parsedValueToString,
    parseValue
} from "../../../shared/models/appscripts-setting.model";
import { Temporal } from "temporal-polyfill";
import { catchError, throwError } from "rxjs";
import { NotificationService } from "../../../shared/services/notification.service";
import { ModalService } from "../../../shared/services/modal.service";


@Component({
    selector: 'app-appscripts-setting-index-component',
    templateUrl: './appscripts-setting-index.component.html',
    styleUrls: ['./appscripts-setting-index.component.scss'],
})

export class AppscriptsSettingIndexComponent implements OnInit {

    loading = false;

    form: FormGroup
    settingsMap: {[key: string]: AppscriptsSettingParsed} = {};
    durationTotalMap: {[key: string]: Temporal.TotalUnit<Temporal.DateTimeUnit>} = {
        "networks-popularity.numbers-allowed-offset": 'days',
        'deactivation-campaign.last-sms-offset': 'hours'
    };

    layout = LAYOUT;
    changesCount = 0;

    @ViewChild('saveModalTpl', { read: TemplateRef, static: false }) saveModalTpl: any;

    constructor(
        private service: AppscriptsSettingService,
        titleService: Title,
        private notificationService: NotificationService,
        public validationService: ValidationService,
        public modal: ModalService
    ) {
        titleService.setTitle('Appscripts settings');
    }

    ngOnInit() {
        this.update();
    }

    update() {
        this.loading = true;
        this.service.all().pipe(
            catchError(e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Settings',
                    message: 'An error occurred while loading settings',
                    serviceName: 'APPSCRIPTS',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
                return throwError(() => e);
            })
        ).subscribe(settings => {
            let controls: {[key: string]: FormControl} = {};
            const controlOptions = this.getControlOptions();
            settings.forEach(s => {
                const parsed = {
                    id: s.id,
                    value: s.value,
                    newValue: s.value,
                    valueType: s.valueType,
                    valueParsed: parseValue(s.value, s.valueType),
                    hasChanges: false,
                }
                this.settingsMap[s.id] = parsed;
                if (!controlOptions[s.id]) {
                    console.error("Not found validators " + s.id);
                }
                controls[s.id] = new FormControl(this.extractValue(parsed), controlOptions[s.id])
            });
            this.form = new FormGroup(controls);
            this.loading = false;
        });
    }

    onClickSave() {
        let dialogRef = this.modal.alert().size('sm').component(this.saveModalTpl).open();
        dialogRef.result.then(result => {
            if (result) {
                this.saveChanges();
            }
        }).catch(_ => { });
    }

    private saveChanges() {
        const updates: AppscriptsSettingUpdate[] = Object.values(this.settingsMap)
            .filter(_ => _.hasChanges)
            .map(s => {
                return {
                    id: s.id,
                    value: s.newValue
                }
            });
        this.loading = true;
        this.service.save(updates).pipe(
            catchError(e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Settings',
                    message: 'An error occurred while saving settings',
                    serviceName: 'APPSCRIPTS',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
                return throwError(() => e);
            })
        ).subscribe(() => {
            this.loading = false;
            this.notificationService.success('Setting updated', 'Appscripts');
            this.changesCount = 0;
            Object.values(this.settingsMap).forEach(s => {
                s.hasChanges = false;
                s.value = s.newValue;
            });
        });
    }

    onChangeSetting(model: AppscriptsSettingParsed, formControl: AbstractControl) {
        if (!formControl.valid) {
            console.log(ValidationService.getInvalidControls(this.form))
            return;
        }
        let newValueParsed = formControl.value;
        if (model.valueType === "DURATION") {
            const unit = this.durationTotalMap[model.id];
            const params = {}
            params[unit] = parseInt(newValueParsed);
            newValueParsed = Temporal.Duration.from(params);
        }
        const newValue = parsedValueToString(newValueParsed, model.valueType);
        if (newValue !== model.value) {
            model.hasChanges = true;
            model.newValue = newValue;
        } else {
            model.hasChanges = false;
            model.newValue = model.value;
        }
        const changes = Object.values(this.settingsMap)
            .filter(_ => _.hasChanges);
        this.changesCount = changes.length;
    }

    private extractValue(parsed: AppscriptsSettingParsed): any {
        if (parsed.valueType === "DURATION") {
            const val = parsed.valueParsed as Temporal.Duration;
            return val.total({unit: this.durationTotalMap[parsed.id]});
        }
        return parsed.valueParsed;
    }

    private getControlOptions(): {[key: string]: AbstractControlOptions} {
        return {
            "networks-popularity.min-users": {validators: [V.required, V.min(1), this.maxValidator("networks-popularity.max-users")]},
            "networks-popularity.max-users": {validators: [V.required, V.min(10), V.max(1000), this.minValidator("networks-popularity.min-users")]},
            "networks-popularity.numbers-allowed-offset": {validators: [V.required, V.min(7), V.max(365)]},
            "networks-popularity.numbers-allowed-multiplier": {validators: [V.required, V.min(0.1), V.max(3)]},
            "activation-campaign.ai-min": {validators: [V.required, V.min(1), V.max(180)]},
            "maintenance-campaign.max-tests-by-network": {validators: [V.required, V.min(10), V.max(100)]},
            "deactivation-campaign.last-sms-offset": {validators: [V.required, V.min(24), V.max(168)]},
            "phone-blocker.wi-rules.1.numbers": {validators: [V.required, V.min(10), this.maxValidator("phone-blocker.wi-rules.2.numbers")]},
            "phone-blocker.wi-rules.1.wi": {validators: [V.required, this.minValidator("phone-blocker.wi-rules.2.wi")]},
            "phone-blocker.wi-rules.2.numbers": {validators: [V.required, this.minValidator("phone-blocker.wi-rules.1.numbers"), this.maxValidator("phone-blocker.wi-rules.3.numbers")]},
            "phone-blocker.wi-rules.2.wi": {validators: [V.required, this.maxValidator("phone-blocker.wi-rules.1.wi"), this.minValidator("phone-blocker.wi-rules.3.wi")]},
            "phone-blocker.wi-rules.3.numbers": {validators: [V.required, V.max(100), this.minValidator("phone-blocker.wi-rules.2.numbers")]},
            "phone-blocker.wi-rules.3.wi": {validators: [V.required, V.min(100), this.maxValidator("phone-blocker.wi-rules.2.wi")]},
        }
    }

    getChangesSettings(): ChangesSettings[] {
        let changesSettings: ChangesSettings[] = [];
        this.layout.forEach(pane => {
            const paneTitle = pane.title;
            let controls = [];
            pane.columns.forEach(column => {
                let labelMap: {[key: string]: string} = {};
                column.controls.forEach(c => labelMap[c.id] = c.label);
                let changes = Object.keys(labelMap).map(id => this.settingsMap[id])
                    .filter(_ => _.hasChanges);
                if (changes.length) {
                    changes.forEach(c => {
                        controls.push({
                            label: labelMap[c.id],
                            parsed: c
                        })
                    });
                }
            });
            if (controls.length) {
                changesSettings.push({
                    title: paneTitle,
                    controls: controls
                });
            }
        });
        return changesSettings;
    }

    private minValidator = (relatedControlName: string, skipEmpty?: boolean): ValidatorFn => {
        return (control: FormControl): ValidationErrors => {
            if (!this.form) {return  null}
            const related = this.form.controls[relatedControlName]
            const min = Number(related.value);
            let v: number = Number(control.value);
            return ((skipEmpty && !v) || v > min) ? null : { min: { min: min + 1} };
        }
    }

    private maxValidator = (relatedControlName: string, skipEmpty?: boolean): ValidatorFn  => {
        return (control: FormControl): ValidationErrors => {
            if (!this.form) {return null}
            const related = this.form.controls[relatedControlName]
            const max = Number(related.value);
            let v: number = Number(control.value);
            return ((skipEmpty && !v) || v < max) ? null : { max: { max: max - 1 } };
        }
    }
}

interface ChangesSettings {
    title: string;
    controls: {label: string, parsed: AppscriptsSettingParsed}[]
}

interface LayoutPane {
    className?: string,
    title: string,
    columns: LayoutColumn[]
}

interface LayoutColumn {
    className?: string,
    controls: LayoutControl[]
}

interface LayoutControl {
    className?: string,
    id: string,
    label: string
}

const LAYOUT: LayoutPane[] =  [
    {
        title: "Registrations and limits",
        columns: [
            {
                className: "col-4",
                controls: [
                    {
                        id: "networks-popularity.min-users",
                        label: "Min users"
                    },
                    {
                        id: "networks-popularity.max-users",
                        label: "Max users"
                    }
                ]
            },
            {
                className: "col-4",
                controls: [
                    {
                        id: "networks-popularity.numbers-allowed-offset",
                        label: "Days to count tests for limits"
                    },
                    {
                        id: "networks-popularity.numbers-allowed-multiplier",
                        label: "Multiplier for limits"
                    }
                ]
            }
        ]
    },
    {
        title: "Activation campaign",
        columns: [
            {
                className: "col-4",
                controls: [
                    {
                        id: "activation-campaign.ai-min",
                        label: "Min AI for activation"
                    },
                ]
            },
        ]
    },
    {
        title: "Maintenance campaign",
        columns: [
            {
                className: "col-4",
                controls: [
                    {
                        id: "maintenance-campaign.max-tests-by-network",
                        label: "Messages limit per network"
                    },
                ]
            },
        ]
    },
    {
        title: "Deactivation campaign",
        columns: [
            {
                className: "col-4",
                controls: [
                    {
                        id: "deactivation-campaign.last-sms-offset",
                        label: "Period for number deactivation (hours)"
                    },
                ]
            },
        ]
    },
    {
        title: "Blocking algorithm",
        columns: [
            {
                className: "col-4",
                controls: [
                    {
                        id: "phone-blocker.wi-rules.1.numbers",
                        label: "Numbers threshold level 1"
                    },
                    {
                        id: "phone-blocker.wi-rules.1.wi",
                        label: "WI threshold level 1"
                    },
                ]
            },
            {
                className: "col-4",
                controls: [
                    {
                        id: "phone-blocker.wi-rules.2.numbers",
                        label: "Numbers threshold level 2"
                    },
                    {
                        id: "phone-blocker.wi-rules.2.wi",
                        label: "WI threshold level 2"
                    },
                ]
            },
            {
                className: "col-4",
                controls: [
                    {
                        id: "phone-blocker.wi-rules.3.numbers",
                        label: "Numbers threshold level 3"
                    },
                    {
                        id: "phone-blocker.wi-rules.3.wi",
                        label: "WI threshold level 3"
                    },
                ]
            }
        ]
    }
];