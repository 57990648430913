import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
    AnalyticsTariff,
    Bundle,
    MyTiers,
    PostpaidCurrentPlan, PostpaidCurrentPlanItem,
    PostpaidCurrentPlanView,
    PostpaidPurchase,
    PrepaidCurrentPlan,
    PrepaidCurrentPlanItem,
    PrepaidPurchase,
    PricingGroup,
    PricingGroupCollection,
    SpecialPricingGroup,
    SpecialPricingGroupCollection,
    Tier
} from "../models/pricing.model";
import { RestUtils } from './rest-utils';
import { SchedulerService } from "./scheduler.service";
import { PaymentType } from '../models/user.model';

@Injectable()
export class PricingService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    private actionSubject: Subject<PricingGroupActionData> = new Subject<PricingGroupActionData>();
    action$ = this.actionSubject.asObservable();

    paymentTypesHtml = [
        { id: 'PREPAID', title: 'Prepaid' },
        { id: 'POSTPAID', title: 'Postpaid' }
    ]

    specialGroupStatusesHtml = [
        { id: 'PENDING', title: 'Pending' },
        { id: 'ACTIVE', title: 'Active' },
        { id: 'EXPIRED', title: 'Expired' }
    ]

    static readonly services = ['MT', 'MO', "CLI"];

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    announcePricingGroupAction(action: PricingGroupActionData) {
        this.actionSubject.next(action);
    }

    allServices() {
        let url = this.utils.buildUrl('admin/usr/services');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<string[]>(url, options);
    }

    changeEnabledGroup(id: number, enabled: boolean) {
        let url = this.utils.buildUrl('admin/usr/pricing-groups/' + id + '/enabled', { enabled: enabled ? 'true' : 'false' });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, {}, options).pipe(map(_ => id));
    }

    changeVisibleAllGroup(id: number, visibleAll: boolean) {
        let url = this.utils.buildUrl('admin/usr/pricing-groups/' + id + '/visible-all', { visibleAll: visibleAll ? 'true' : 'false' });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, {}, options).pipe(map(_ => id));
    }

    saveGroup(data) {
        let url = this.utils.buildUrl('admin/usr/pricing-groups');
        let options = this.utils.getHttpHeaderOptions(this.headers);
        let model = Object.assign({}, data);
        if (data.id) {
            url = this.utils.buildUrl('admin/usr/pricing-groups/' + data.id);
            return this.http.put(url, this.prepareDatesBeforeSave(model), options);
        }
        return this.http.post(url, this.prepareDatesBeforeSave(model), options);
    }

    deleteGroup(id: number) {
        let url = this.utils.buildUrl('admin/usr/pricing-groups/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options).pipe(map(_ => id));
    }

    bundles(gId: number) {
        let url = this.utils.buildUrl(`admin/usr/pricing-groups/${gId}/bundles`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<Bundle[]>(url, options).pipe(map(_ => {
            return _.map(_ => {
                if (_.visibleUntil && typeof _.visibleUntil === "string" && _.visibleUntil.indexOf('9999') !== -1) {
                    _.visibleUntil = null;
                }
                return _;
            });
        }));
    }

    tiers(gId: number): Observable<Tier[]> {
        let url = this.utils.buildUrl(`admin/usr/pricing-groups/${gId}/tiers`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<Tier[]>(url, options).pipe(map(tiers => {
            return tiers.map(_ => {
                if (_.visibleUntil && typeof _.visibleUntil === "string" && _.visibleUntil.indexOf('9999') !== -1) {
                    _.visibleUntil = null;
                }
                return _;
            });
        }));
    }

    myTiers() {
        let url = this.utils.buildUrl(`ROLE/usr/postpaid/users/self/pricing-groups`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<MyTiers>(url, options);
    }

    myPrepaidPricingGroups() {
        let url = this.utils.buildUrl(`ROLE/usr/prepaid/users/self/pricing-groups`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<PricingGroup[]>(url, options);
    }

    myPrepaidActivePricingGroup() {
        let url = this.utils.buildUrl(`ROLE/usr/prepaid/users/self/pricing-group/latest-purchase`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<number | null>(url, options).pipe(map(_ => {
            const text = _;
            if (text) {
                return parseInt(String(text));
            }
            return null;
        }));
    }

    analyticsTariffs(gId: number, type: 'general'|'special') {
        const prefix = type === 'general' ? 'pricing-groups' : 'special-pricing-groups';
        let url = this.utils.buildUrl(`ROLE/usr/${prefix}/${gId}/analytics-tariffs`);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<AnalyticsTariff[]>(url, options).pipe(
            catchError((err, caught) => {
                return of([]);
            }),
            map(tariffs => {
                return tariffs.map(t => {
                    if (t.visibleUntil && typeof t.visibleUntil === "string" && t.visibleUntil.indexOf('9999') !== -1) {
                        t.visibleUntil = null;
                    }
                    return t;
                });
            })
        );
    }

    allGroups(params: AllRequestParams) {
        let queryParams = {
            page: params.page,
            size: params.size,
            search: params.search ? params.search : '',
            sort: params.sort && params.sort.length ? params.sort : []
        };

        let url = this.utils.buildUrl('admin/usr/pricing-groups', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<PricingGroupCollection>(url, options);
    }

    oneGroup(id): Observable<PricingGroup> {
        let url = this.utils.buildUrl(`admin/usr/pricing-groups/${id}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return forkJoin([
            this.http.get<PricingGroup>(url, options),
            this.analyticsTariffs(id, 'general')
        ]).pipe(
            map(results => {
                let group = results[0];
                group.analyticsTariffs = [];
                if (results.length > 1 && results[1].length) {
                    group.analyticsTariffs = results[1];
                }
                return group;
            })
        );
    }

    prepareDatesBeforeSave(group) {
        group.tiers = group.tiers.map(tier => {
            if (tier.visibleFrom === null) {
                delete tier.visibleFrom;
            }
            if (tier.visibleUntil === null) {
                delete tier.visibleUntil;
            }
            return tier;
        });
        group.bundles = group.bundles.map(bundle => {
            if (bundle.visibleFrom === null) {
                delete bundle.visibleFrom;
            }
            if (bundle.visibleUntil === null) {
                delete bundle.visibleUntil;
            }
            return bundle;
        });

        return group;
    }

    saveSpecialGroup(data) {
        let url = this.utils.buildUrl('admin/usr/special-pricing-groups');
        let options = this.utils.getHttpHeaderOptions(this.headers);
        let model = Object.assign({}, data);
        if (data.id) {
            url = this.utils.buildUrl('admin/usr/special-pricing-groups/' + data.id);
            return this.http.put(url, this.prepareDatesBeforeSave(model), options);
        }
        return this.http.post(url, this.prepareDatesBeforeSave(model), options);
    }

    oneSpecialGroup(id) {
        let url = this.utils.buildUrl(`admin/usr/special-pricing-groups/${id}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return forkJoin([
            this.http.get<SpecialPricingGroup>(url, options),
            this.analyticsTariffs(id, 'special')
        ]).pipe(
            map(results => {
                let group = results[0];
                group.analyticsTariffs = [];
                if (results.length > 1 && results[1]) {
                    group.analyticsTariffs = results[1];
                }
                return group;
            })
        );
    }

    allSpecialGroups(params: SpecialPricingRequestParams): Observable<SpecialPricingGroupCollection> {
        const queryParams = {
            page: params.page,
            size: params.size,
            sort: (params.sort && params.sort.length) ? params.sort : [],
            search: params.searchData?.searchString ?? null
        };
        const searchData = params.searchData;
        let url = this.utils.buildUrl('admin/usr/special-pricing-groups/search', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<SpecialPricingGroupCollection>(url, searchData, options);
    }

    deleteSpecialGroup(id: number) {
        let url = this.utils.buildUrl('admin/usr/special-pricing-groups/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options).pipe(map(_ => id));
    }

    changeStatusSpecialGroup(id: number, status: string) {
        let url = this.utils.buildUrl('admin/usr/special-pricing-groups/' + id + '/status', { status: status });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, {}, options).pipe(map(_ => id));
    }

    changeEnabledSpecialGroup(id: number, enabled: boolean) {
        let url = this.utils.buildUrl('admin/usr/special-pricing-groups/' + id + '/enabled', { enabled: enabled ? 'true' : 'false' });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, {}, options).pipe(map(_ => id));
    }

    changeScheduledSpecialGroup(id: number, scheduled: boolean) {
        let url = this.utils.buildUrl('admin/usr/special-pricing-groups/' + id + '/scheduled', { scheduled: scheduled ? 'true' : 'false' });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, {}, options).pipe(map(_ => id));
    }

    groupsByUserId(userId: number) {
        let url = this.utils.buildUrl('admin/usr/users/' + userId + '/pricing-groups');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<PricingGroup[]>(url, options);
    }

    getTestPrices(testCount: number, serviceType: string = null): Observable<number> {
        let url = this.utils.buildUrl(`ROLE/usr/prices`, { testCount, serviceType });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<number>(url, options);
    }

    getPrepaidPurchases(): Observable<PrepaidPurchase[]> {
        let url = this.utils.buildUrl(`ROLE/usr/prepaid/users/self/latest-purchases`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<PrepaidPurchase[]>(url, options).pipe(
            map(purchases => {
                return purchases.map(p => {
                    if (p.amountPaid && p.commitment !== p.amountPaid) {
                        p.commitment = p.amountPaid;
                    }
                    if (!p.commitment || p.commitmentDecrementCounter > p.commitment) {
                        p.commitment = p.commitmentDecrementCounter;
                    }
                    return p;
                })
            })
        );
    }

    getPostpaidCurrentPurchase(): Observable<PostpaidCurrentPlan> {
        let url = this.utils.buildUrl(`ROLE/usr/postpaid/users/self/current-purchase`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<PostpaidCurrentPlan>(url, options);
    }

    getPrepaidPlan(): Observable<PrepaidCurrentPlan> {
        return this.getPrepaidPurchases().pipe(
            map(purchases => {
                let availableTests = 0;
                let items: PrepaidCurrentPlanItem[] = [];
                purchases.forEach(p => {
                    const totalTests = p.commitment / p.ratePerUnit;
                    const used = p.commitment - p.commitmentDecrementCounter;
                    const usedTests = p.commitmentDecrementCounter === p.commitment ? 0 : used / p.ratePerUnit;
                    items.push({
                        pricingGroupId: p.pricingGroupId,
                        used: used,
                        total: p.commitment,
                        ratePerTest: p.ratePerUnit,
                        testsCount: totalTests,
                        progressPercent: (used / p.commitment) * 100
                    });
                    availableTests += Math.floor(totalTests - usedTests);
                });
                return {
                    availableTests: availableTests,
                    items: items
                }
            })
        );
    }

    getPostpaidPlan(): Observable<PostpaidCurrentPlanView> {
        return forkJoin([
            this.myTiers(),
            this.getPostpaidCurrentPurchase()
        ]).pipe(
            map(data => {
                const purchase: PostpaidPurchase = data[1].purchase;
                let tiersMo: Tier[] = [];
                let tiersMt: Tier[] = [];
                let tiersVoice: Tier[] = [];
                data[0].tierDtos.forEach(t => {
                    switch (t.serviceType.toUpperCase()) {
                        case 'MT': tiersMt.push(t); break;
                        case 'MO': tiersMo.push(t); break;
                        case 'VOICE': tiersVoice.push(t); break;
                    }
                }); 

                const testsCount = purchase ? purchase.paidTestCount + purchase.paidTestCountMo + purchase.paidTestCountVoice : 0;

                return {
                    plan: data[1],
                    tiers: data[0].tierDtos,
                    testsCount: testsCount,
                    itemsMo: this.tiersToPostpaidPlanItems(tiersMo, purchase ? purchase.paidTestCountMo: 0),
                    itemsMt: this.tiersToPostpaidPlanItems(tiersMt, purchase ? purchase.paidTestCount : 0),
                    itemsVoice: this.tiersToPostpaidPlanItems(tiersVoice, purchase ? purchase.paidTestCountVoice : 0)
                }
            })
        )
    }

    private tiersToPostpaidPlanItems(tiers: Tier[], tests: number) {
        if (!tiers.length) {return  [];}

        tiers.sort((a, b) => (a.quota > b.quota) ? 1 : -1)

        const quotas = tiers.map(_ => _.quota);
        if (tests) {quotas.push(tests);}

        return tiers.map((t, index) => {
            const min = t.quota;
            const max = tiers[index + 1] ? tiers[index + 1].quota : Infinity;
            let item: PostpaidCurrentPlanItem = {
                ratePerTest: t.ratePerUnit,
                usageTests: 0,
                usagePercent: 0,
                min: min,
                max: max,
                isLast: !isFinite(max)
            };
            const usageTests = max - min;
            if (tests >= usageTests) {
                item.usagePercent = 100;
                item.usageTests = item.isLast ? tests : usageTests;
            } else if (tests > 0) {
                item.usageTests = tests;
                item.usagePercent = isFinite(max) ? (tests / usageTests) * 100 : 100;
            }
            tests -= usageTests;
            return item;
        });
    }

    static formatValidForSeconds(sec) {
        const time = SchedulerService.secondsToHuman(sec)
        if (time.unit === 'unlimited') {
            return 'Unlimited'
        }
        const map = {
            h: ['hour', 'hours'],
            d: ['day', 'days']
        };
        const labels = map[time.unit];

        return time.value + ' ' + labels[time.value > 1 ? 1 : 0];
    }

    createGroup(): PricingGroup {
        return {
            id: null,
            paymentType: 'PREPAID',
            title: '',
            visibleAll: true,
            enabled: true,
            bundles: [],
            tiers: [],
            analyticsTariffs: []
        }
    }

    createSpecialGroup(): SpecialPricingGroup {
        return {
            createdAt: "",
            lastModified: "",
            id: null,
            paymentType: 'PREPAID',
            title: '',
            companyName: '',
            enabled: true,
            bundles: [],
            tiers: [],
            users: [],
            analyticsTariffs: []
        }
    }

    createBundle(): Bundle {
        return {
            id: null,
            serviceType: null,
            ratePerUnit: null,
            commitment: null,
            visibleFrom: null,
            validForSeconds: null,
            hidden: false,
            visibleUntil: null
        }
    }

    createTier(): Tier {
        return {
            id: null,
            serviceType: null,
            ratePerUnit: null,
            commitment: null,
            visibleFrom: null,
            hidden: false,
            quota: 0,
            visibleUntil: null
        }
    }

    createAnalyticsTariff(): AnalyticsTariff {
        return {
            id: null,
            rate: null,
            visibleFrom: null,
            hidden: false,
            visibleUntil: null
        }
    }
}
export class AllRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;
    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

export class SpecialPricingRequestParams {

    size: number = 20;
    page: number = 1;
    searchData: SpecialPricingSearchEvent;
    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

export interface SpecialPricingSearchEvent {
    id: number[];
    title: string[];
    companyName: string[];
    userEmail: string[];
    paymentType: PaymentType;
    enabled: boolean;
    scheduledChange: boolean;
    serviceList: string[];
    searchString?: string;
}

export interface PricingGroupActionData {
    name: string;
    row: PricingGroup;
    column: string;
}
