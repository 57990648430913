import { Component, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { NotificationService } from '../../shared/services/notification.service';
import { StatisticSummary } from '../../shared/models/statistics.model';
import { StatisticsService } from '../../shared/services/statistics.service';
import { UsersService } from '../../shared/services/users.service';
import * as Highcharts from 'highcharts';
import { ModalService } from "../../shared/services/modal.service";
import { DatePeriodRanges } from "../../shared/components/input/input.search.datePeriod";
import { DashboardTestCountWidgetComponent } from "../dashboard-test-count-widget/dashboard-test-count-widget.component";
import { DashboardTestCountServiceWidgetComponent } from "../dashboard-test-count-service-widget/dashboard-test-count-service-widget.component";
import { DashboardTestCountUserWidgetComponent } from "../dashboard-test-count-user-widget/dashboard-test-count-user-widget.component";
import { AuthUser } from "../../shared/models/user.model";
import { DashboardProfitWidgetComponent } from "../dashboard-profit-widget/dashboard-profit-widget.component";
import { DashboardTestCountWorldWidgetComponent } from "../dashboard-test-count-world-widget/dashboard-test-count-world-widget.component";
import { SettingService } from '../../shared/services/setting.service';
import { animate, style, transition, trigger } from '@angular/animations';
declare var moment: any;

@Component({
    selector: 'app-dashboard-index',
    templateUrl: './dashboard-index.component.html',
    styleUrls: ['./dashboard-index.component.scss'],
    animations: [
        trigger('slideInFadeIn', [
            transition(':enter', [
                style({ transform: 'translateY(-100%)', opacity: 0 }), // Start off-screen and transparent
                animate('500ms ease-in', style({ transform: 'translateY(0)', opacity: 1 })) // Slide down and fade in
            ])
        ])
    ]
})

export class DashboardIndexComponent implements OnDestroy, AfterViewInit {

    @ViewChild(DashboardTestCountWidgetComponent, { static: false }) testCountWidget: DashboardTestCountWidgetComponent;
    @ViewChild(DashboardTestCountServiceWidgetComponent, { static: false }) testCountServiceWidget: DashboardTestCountServiceWidgetComponent;
    @ViewChild(DashboardTestCountUserWidgetComponent, { static: false }) testCountUserWidget: DashboardTestCountUserWidgetComponent;
    @ViewChild(DashboardTestCountWorldWidgetComponent, { static: false }) testCountWorldWidget: DashboardTestCountWorldWidgetComponent;
    @ViewChild(DashboardProfitWidgetComponent, { static: false }) profitWidget: DashboardProfitWidgetComponent;

    Highcharts: typeof Highcharts = Highcharts;

    summary: StatisticSummary;

    scheduler: any;
    lastUpdate: number = 0;
    updateInterval = 60;
    updateIntervalItems = [
        { id: 0, title: 'Disabled auto-update' },
        { id: 60, title: 'Update every 1m' },
        { id: 60 * 5, title: 'Update every 5m' },
        { id: 60 * 10, title: 'Update every 10m' },
    ]

    from: string;
    to: string;

    rangeStart: number;
    rangeEnd: number;
    ranges: DatePeriodRanges = {
        'Last 7 Days': [moment().subtract(7, 'days'), moment().set({ hour: 23, minute: 59 })],
        'Last 30 Days': [moment().subtract(30, 'days'), moment().set({ hour: 23, minute: 59 })],
        'Last 90 Days': [moment().subtract(90, 'days'), moment().set({ hour: 23, minute: 59 })],
        'Last 365 days': [moment().subtract(365, 'days'), moment().set({ hour: 23, minute: 59 })],
    };
    rangesInStorage = {
        'Last 7 Days': '7days',
        'Last 30 Days': '30days',
        'Last 90 Days': '90days',
        'Last 365 days': 'year'
    };
    minDate: string;
    maxDate: string;

    showTests = false;
    showProfit = false;
    showUsers = false;
    showServices = false;
    showWorld = false;

    rangeLabel = '';

    mfaEnabled = true;
    user: AuthUser;

    constructor(
        public statistics: StatisticsService,
        public userService: UsersService,
        public router: Router,
        public notificationService: NotificationService,
        public localStorage: LocalStorageService,
        public modal: ModalService,
        private settingService: SettingService,
        titleService: Title
    ) {
        titleService.setTitle('Dashboard');

        this.minDate = this.ranges['Last 365 days'][0].format('DD/MM/YYYY');
        this.maxDate = this.ranges['Last 365 days'][1].format('DD/MM/YYYY');

        // select update interval
        //this.updateInterval = localStorage.get('dashboard_update_interval', 60);

        const range = this.getRange();
        this.rangeStart = range[0].clone().unix();
        this.rangeEnd = range[1].clone().unix();
        this.setRange(range[0].format('YYYY-MM-DD'), range[1].format('YYYY-MM-DD'));
    }

    private getRange(): any[] {
        let rangeInStorage = this.localStorage.get('dashboard_dates', '7days');
        let range = null;
        for (let rangeName in this.ranges) {
            if (this.rangesInStorage[rangeName] === rangeInStorage) {
                range = rangeName;
                break;
            }
        }
        if (!range) { range = Object.keys(this.ranges)[0]; } else { this.rangeLabel = range; }
        return [
            this.ranges[range][0],
            this.ranges[range][1]
        ];
    }

    // select update interval
    setUpdateInterval(v) {
        const interval = parseInt(v);
        this.updateInterval = interval;
        this.localStorage.set('dashboard_update_interval', interval);
    }

    ngAfterViewInit() {
        if (!this.userService.isAuth) {
            return;
        }
        this.userService.getAuthUser().then(user => {
            // select update interval
            /*this.scheduler = setInterval(() => {
                const now = Date.now();
                const diff = now - this.lastUpdate;
                if (diff >= (this.updateInterval * 1000)) {
                    this.lastUpdate = now;
                    if (this.updateInterval) {
                        this.updateSummary();
                        this.updateWidgets();
                    }
                }
            }, 1000 * 10);*/
            this.user = user;
            this.scheduler = setInterval(() => {
                this.updateSummary();
            }, 1000 * this.updateInterval);
            this.lastUpdate = Date.now();
            this.updateSummary();
        });
        this.settingService.getSidebarState().subscribe(state => {
            setTimeout(() => this.resizeWidgets(), 500);
        });
    }

    private setUser() {
        this.showTests = true;
        this.showServices = true;
        this.showWorld = true;
        this.showProfit = this.user.role === 'admin';
        if (this.user.role === 'subaccount') {
            this.showUsers = this.user.showAllResults;
        } else {
            this.showUsers = this.user.role !== 'admin';
        }
        this.mfaEnabled = this.user.mfaEnabled;
    }

    updateSummary() {
        this.setUser();
        this.statistics.summary().subscribe(_ => {
            this.summary = _;
        }, error => {
            this.notificationService.error({
                title: 'Dashboard',
                message: 'An error occurred while loading statistics',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
        });
    }

    onChangeDatesRange(dates) {
        if (!dates.startStr) {
            return;
        }
        const start = dates.startStr.split('T')[0];
        const end = dates.endStr.split('T')[0];
        let find = false;
        let rangeName = ''
        for (rangeName in this.ranges) {
            const rangeStart = this.ranges[rangeName][0].clone().format('YYYY-MM-DD');
            const rangeEnd = this.ranges[rangeName][1].clone().format('YYYY-MM-DD');
            if (start === rangeStart && end === rangeEnd) {
                find = true;
                this.localStorage.set('dashboard_dates', this.rangesInStorage[rangeName]);
                break;
            }
        }
        this.rangeLabel = find ? rangeName : '';
        this.setRange(start, end);
        this.lastUpdate = Date.now();

    }

    setRange(from: string, to: string) {
        let needUpdate = from !== this.from || to !== this.to;
        this.from = from;
        this.to = to;
        return needUpdate;
    }

    updateWidgets() {
        if (this.profitWidget) { this.profitWidget.update(); }
        if (this.testCountWidget) { this.testCountWidget.update(); }
        if (this.testCountUserWidget) { this.testCountUserWidget.update(); }
        if (this.testCountServiceWidget) { this.testCountServiceWidget.update(); }
        if (this.testCountWorldWidget) { this.testCountWorldWidget.update(); }
    }

    resizeWidgets() {
        this.profitWidget?.resize();
        this.testCountWidget?.resize();
        this.testCountUserWidget?.resize();
        this.testCountServiceWidget?.resize();
        this.testCountWorldWidget?.resize();
    }

    ngOnDestroy() {
        clearInterval(this.scheduler);
    }
}
