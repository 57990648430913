import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Filter } from '../../shared/filter/filter';
import { Role, UserSearchEvent } from '../../shared/models/user.model';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { UsersService } from '../../shared/services/users.service';
import { ValidationService } from '../../shared/services/validation.service';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

interface RoleObject { id: string, label: string, isSelected: boolean }

@Component({
    selector: 'app-users-search',
    templateUrl: './users-search.component.html',
    styleUrls: ['./users-search.component.scss']
})

export class UsersSearchComponent implements OnInit {

    @Output() onSubmit = new EventEmitter<UserSearchEvent>();

    searchFormControl: FormControl = new FormControl();
    searchSubscription: Subscription;

    isBeingDragged: boolean = false;
    filters: Filter[] = [
        { filterKey: 'enabled', title: 'Enabled', show: true },
        { filterKey: 'roles', title: 'Roles', show: true },
        { filterKey: 'owner', title: 'Owner', show: true },
        { filterKey: 'company', title: 'Company', show: true },
        { filterKey: 'paymentType', title: 'Payment Type', show: true },
        { filterKey: 'comment', title: 'Comment', show: true },
    ];

    roles: RoleObject[] = [
        { id: Role.ADMIN, label: 'Admin user', isSelected: false },
        { id: Role.MAIN, label: 'Main account', isSelected: false },
        { id: Role.DEPUTY, label: 'Deputy account', isSelected: false },
        { id: Role.SUB, label: 'Subaccount', isSelected: false },
    ];


    form: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        public validationService: ValidationService,
        private usersService: UsersService,
        private localStorage: LocalStorageService
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            enabled: [null],
            roles: [[]],
            owner: [null],
            company: [null],
            paymentType: [null],
            comment: [null]
        });
        this.searchSubscription = this.searchFormControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(_ => {
            this.onSubmitClick();
        });
    }

    setSearchFormControlValue(value: string): void {
        this.searchFormControl.setValue(value);
    }

    getEnableValue(): boolean | null {
        return this.form.get('enabled')?.value;
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.filters, event.previousIndex, event.currentIndex);
        this.localStorage.set(`users_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    getIconForRole(role: string): string {
        switch (role) {
            case Role.ADMIN:
                return 'icon-admin';
            case Role.MAIN:
                return 'icon-chess-king';
            case Role.DEPUTY:
                return 'icon-chess-queen';
            case Role.SUB:
                return 'icon-chess-pawn';
            default:
                return '';
        }
    }

    onClickedEnabled(): void {
        const enabledFormControl: FormControl = this.form.get('enabled') as FormControl;
        enabledFormControl.setValue(enabledFormControl.value ? null : true);
        this.onSubmitClick();
    }

    onClickDisabled(): void {
        const enabledFormControl: FormControl = this.form.get('enabled') as FormControl;
        enabledFormControl.setValue(enabledFormControl.value === false ? null : false);
        this.onSubmitClick();
    }

    onChangeRoleSelection(selectedRole: RoleObject): void {
        const roleFormControl: FormControl = this.form.get('roles') as FormControl;
        selectedRole.isSelected = !selectedRole.isSelected;
        roleFormControl.setValue(this.roles.filter(role => role.isSelected).map(role => role.id));
        this.onSubmitClick();
    }

    onChangePaymentSelection(paymentType: string): void {
        const paymentTypeFormControl: FormControl = this.form.get('paymentType') as FormControl;
        const previousPaymentType = paymentTypeFormControl.value;
        paymentTypeFormControl.setValue(previousPaymentType === paymentType ? null : paymentType);
        this.onSubmitClick();
    }

    getPeekForSelectedValues(filter): number | string | null {
        switch (filter.filterKey) {
            case 'roles':
                return this.roles.filter(role => role.isSelected).length;
            default:
                return null;
        }
    }

    isFilterActive(filter: Filter): boolean {
        switch (filter.filterKey) {
            case 'enabled':
                return this.form.get('enabled')?.value !== null;
            case 'roles':
                return this.roles.filter(role => role.isSelected).length > 0;
            case 'owner':
                return this.form.get('owner').value?.length > 0;
            case 'company':
                return this.form.get('company').value?.length > 0;
            case 'paymentType':
                return this.form.get('paymentType').value?.length > 0;
            case 'comment':
                return this.form.get('comment').value?.length > 0;
            default:
                return false;
        }
    }

    onClear(filterKey: string) {
        switch (filterKey) {
            case 'owner':
                this.form.get('owner').reset();
                break;
            case 'company':
                this.form.get('company').reset();
                break;
            case 'comment':
                this.form.get('comment').reset();
                break;
            default:
                break;
        }
        this.onSubmitClick();
    }

    onSubmitClick(filterKey: string = null) {
        const searchData: UserSearchEvent = {
            searchString: this.searchFormControl.value,
            ...this.form.getRawValue()
        }
        this.onSubmit.emit(searchData);
    }

    resetAll(): void {
        this.form.reset();
        this.roles.forEach(role => role.isSelected = false);
        this.searchFormControl.reset();
    }

    deleteItem(event: CdkDragDrop<string[]>) {
        const indexToHide = event.previousIndex;
        this.filters[indexToHide].show = false;
        this.onClear(this.filters[indexToHide].filterKey);
        this.localStorage.set(`users_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    onClearAllFilter(): void {
        this.localStorage.set(`users_${this.usersService.authUser.id}_filter_state`, this.filters);
        this.resetAll();
        this.onSubmitClick();
    }

    ngOnDestroy(): void {
        this.searchSubscription?.unsubscribe();
    }
}
