import {EnvDef, defaults} from "./model";

export const environment: EnvDef = {
    production: false,
    releaseVersion: '5590',
    apiUrl: 'https://api.dev.telqtele.com',
    proxyApiUrl: 'https://api-proxy.dev.telqtele.com',
    storagePrefix: defaults.storagePrefix,
    appscriptsUrl: defaults.appscriptsUrl,
    statusPageId: defaults.statusPageId,
    networksOnlineUrl: 'https://sse.dev.telqtele.com/networks/online',
    networkUpdatesUrl: 'https://sse.dev.telqtele.com/networks/updates',
    moNetworksOnlineUrl: 'https://sse.dev.telqtele.com/networks/mo-online',
    moNetworkUpdatesUrl: 'https://sse.dev.telqtele.com/networks/mo-updates',
    voiceNetworksOnlineUrl: 'https://sse.dev.telqtele.com/networks/voice-online',
    voiceNetworkUpdatesUrl: 'https://sse.dev.telqtele.com/networks/voice-updates',
    agGridLicenseKey: 'Using_this_{AG_Grid}_Enterprise_key_{AG-054301}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{TelQ_Telecom_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{TelQ}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{TelQ}_need_to_be_licensed___{TelQ}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{1_March_2025}____[v3]_[01]_MTc0MDc4NzIwMDAwMA==199d388cab59de4d3aef6f7e24806265',
    forcedUpdate: false,
    postponeUpdates: ['/live-testing', '/manual-testing', '/scheduler/tasks/create'],
    recaptchaSiteKey: defaults.recaptchaSiteKey,
    recaptchaBaseUrl: defaults.recaptchaBaseUrl,
    countriesApiUrl: 'https://api.dev.moneysmsapp.com/v2.4/platform/countries',
    ignoreUpdateUserIds: new Set([
        2752,
        2694,
        2672,
        2671,
        2670,
        2554,
        2553,
        2552,
        2551,
        2971,
        2979,
        2980,
        2989,
        2990,
        2991,
        2992,
        3001,
        3002,
        3003,
        3004,
        3005,
        3006
    ]),
    backendChannelResetTag: 1,
    showInvoiceDeleteToUserIds: new Set([
        849,
        852
    ])
};