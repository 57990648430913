
import { DatePipe } from '@angular/common';
import {
    Component,
    EventEmitter, OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { CellClickedEvent, ColDef, ColumnMovedEvent, ColumnResizedEvent, ColumnVisibleEvent, GetContextMenuItemsParams, GridReadyEvent, ICellRendererParams, MenuItemDef, SortChangedEvent } from 'ag-grid-community';
import { catchError, debounceTime, throttleTime } from "rxjs/operators";
import { AGTableBase } from '../../shared/components/table/ag-table-base';
import {
    ScheduledTask,
    ScheduledTaskReportRule,
    ScheduledTasksCollection
} from '../../shared/models/scheduled-task.model';
import { TestCaseTemplate } from '../../shared/models/test-case-template.model';
import { Role } from '../../shared/models/user.model';
import { CustomUtils } from '../../shared/services/custom-utils';
import { ExportService } from '../../shared/services/export.service';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { SchedulerRequestParams, SchedulerService, SchedulerTaskActionData, TaskSettingDetail } from '../../shared/services/scheduler.service';
import { TestCaseTemplatesService } from '../../shared/services/test-case-template.service';
import { UsersService } from '../../shared/services/users.service';
import { LntResultsTableComponent } from '../../test/lnt/lnt-results-table/lnt-results-table.component';
import { SchedulerTableActionsComponent } from './scheduler-table-actions/scheduler-table-actions.component';
declare var moment: any;

@Component({
    selector: 'app-scheduler-table',
    templateUrl: './scheduler-table.component.html',
    styleUrls: ['./scheduler-table.component.scss'],
})

export class SchedulerTableComponent extends AGTableBase implements OnInit, OnDestroy {

    @Output() actions = new EventEmitter<SchedulerTaskActionData>();
    @ViewChild(LntResultsTableComponent, { static: false }) results: LntResultsTableComponent;
    @ViewChild('resultsModalTpl', { read: TemplateRef, static: false }) resultsModalTpl: TemplateRef<HTMLTemplateElement>;
    @ViewChild('detailsModalTpl', { read: TemplateRef, static: false }) detailsModalTpl: TemplateRef<HTMLTemplateElement>;
    detailsModal: DialogRef;
    @ViewChild('exportModalTpl', { read: TemplateRef, static: false }) exportModalTpl: any;

    requestParams = new SchedulerRequestParams();
    exportSize: string | number = 'Custom';
    customExportSize = 1000;
    exportSizes = ['Custom', 'All'];
    exportData = {
        url: undefined,
        size: undefined,
        sizeKb: undefined
    };
    exportSpinner = false;

    currentTask: ScheduledTask;
    testCaseTemplate: TestCaseTemplate;
    storageContextName = 'scheduler-schedule-table';
    loading: boolean = false;
    scheduler: ReturnType<typeof setInterval>;
    settingsLoader = false;
    taskSettingsDetails: TaskSettingDetail[] = [];
    currentSection: string = '';

    // AG GRID ...
    columnDefs: ColDef[] = [
        {
            headerName: 'ID', field: 'id',
            maxWidth: 75, minWidth: 75,
            sortable: true, comparator: () => 0, initialSort: 'desc', sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.id}</div>`;
            }
        },
        {
            headerName: 'Name', field: 'title',
            flex: 1.5,
            minWidth: 120,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="two-lines clickable"><span>${_.title}<span></div>`;
            },
            onCellClicked: (params: CellClickedEvent) => this.onCellClick(params)
        },
        {
            headerName: 'Status', field: 'state',
            minWidth: 120, maxWidth: 120,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['asc', 'desc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatState(_);
            }
        },
        {
            headerName: 'Created date', field: 'createdAt',
            minWidth: 150, maxWidth: 150,
            sortable: true, comparator: () => 0, hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatDate(_.createdAt);
            }
        },
        {
            headerName: 'Start date', field: 'startAt',
            minWidth: 150, maxWidth: 150,
            sortable: true, comparator: () => 0,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatDate(_.startAt);
            }
        },
        {
            headerName: 'Finish date', field: 'finishAt',
            minWidth: 150, maxWidth: 150,
            sortable: true, comparator: () => 0,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                if (_.sendOnlyOnce) return '-';
                if (_.runUntilDisabled) return '<div class="two-lines d-flex justify-content-center align-items-center gap-0_5"><i class="icon-infinity"></i> Until Disabled</div>';
                return this.formatDate(_.finishAt);
            }
        },
        {
            headerName: 'Repeat days', field: 'repeatDays',
            minWidth: 120, maxWidth: 150,
            sortable: false,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                if (_.sendOnlyOnce) return 'Sent only once';
                return this.formatDays(_.daysOfWeek);
            }
        },
        {
            headerName: 'Send tests every', field: 'repeatEvery',
            sortable: false,
            minWidth: 150,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return _.sendOnlyOnce ? '-' : this.service.formatDuration(_);
            }
        },
        {
            headerName: 'Run time (from-till)', field: 'fromTillTime',
            minWidth: 150, maxWidth: 150,
            sortable: false, hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${CustomUtils.decimalToTimeFormat(_.fromHour)} - ${CustomUtils.decimalToTimeFormat(_.tillHour)}</div>`;
            }
        },
        {
            headerName: 'Next schedule', field: 'nextSchedule',
            minWidth: 150, maxWidth: 150,
            sortable: false,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatNextSchedule(_);
            }
        },
        {
            headerName: 'User', field: 'user',
            sortable: false, hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                return params.data.user ? params.data.user.username : '';
            }
        },
        {
            headerName: 'Actions', field: 'actions', maxWidth: 120, minWidth: 120,
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
            tooltipField: 'actions',
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: SchedulerTableActionsComponent,
            onCellClicked: (event: CellClickedEvent) => this.onActionClick(event),
        }

    ];
    rowData!: ScheduledTask[];
    // AG GRID ...

    constructor(
        public notificationService: NotificationService,
        public service: SchedulerService,
        public templateService: TestCaseTemplatesService,
        public userService: UsersService,
        public router: Router,
        public modal: ModalService,
        private datePipe: DatePipe,
        private exportService: ExportService,
        private localStorage: LocalStorageService,
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        let hideColumns = [];
        this.userService.getAuthUser().then(data => {
            const userRole = data.role;
            const showAllResults = data.showAllResults;
            if (userRole === Role.SUB && !showAllResults) {
                hideColumns.push('User');
            }
            hideColumns = hideColumns.filter((_, index) => hideColumns.indexOf(_) === index);
            // const finalColumns = columns.filter(c => hideColumns.indexOf(c.title) === -1);
            // this.setColumns(this.createColumns(finalColumns));
            this.requestParams.hidden = this.service.getHiddenMode();
            this.requestParams.ownTasksOnly = this.service.getShowMyTasksFlag();
            this.paginationPageSize = this.localStorage.get(`scheduler_table_component_size_${data.id}`) || 20;
        }).catch(error => {
            this.notificationService.error({
                title: 'Scheduler',
                message: 'An error occurred while displaying task',
                serviceName: 'SCH',
                requestMessage: error.statusText || "",
                requestCode: error.status || "",
                ts: error.timestamp ? error.timestamp : null
            });
        });
        this.actionSubscription = this.service.action$.subscribe(action => {
            this.onAction(action);
        });
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            const currentColumnState = this.gridApi.getColumnState();
            this.localStorage.set(`scheduler_table_state_${this.userService.authUser.id}`, currentColumnState);
        });
        this.scheduler = setInterval(() => {
            this.update(false);
        }, 1000 * 30);
    }

    onAction(event: SchedulerTaskActionData) {
        this.actions.emit(event);
        this.currentTask = event.row;
        if (['schedule', 'stop', 'hidden'].indexOf(event.name) !== -1) {
            this.loading = true;
            this.service[event.name](event.row.id, true).subscribe(() => {
                this.update(true);
            }, error => {
                this.notificationService.error({
                    title: 'Scheduler',
                    message: 'An error occurred while update task',
                    serviceName: 'SCH',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
                this.loading = false;
            });
        }

        if (event.name === 'visible') {
            this.loading = true;
            this.service.hidden(event.row.id, false).subscribe(() => {
                this.update(true);
            }, error => {
                this.notificationService.error({
                    title: 'Scheduler',
                    message: 'An error occurred while update task',
                    serviceName: 'SCH',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
                this.loading = false;
            });
        }

        if (event.name === 'testResults') {
            this.modal.alert().dialogClass('modal-dialog extra-large-modal').component(this.resultsModalTpl).open();
        }

        if (event.name === 'settings') {
            this.showTaskDetails();
        }
    }

    onSortChange(event: SortChangedEvent) {
        const columnState = this.gridApi.getColumnState();
        this.sortState = columnState.map(c => c.sort ? `${c.colId},${c.sort}` : null);
        this.localStorage.set(`scheduler_table_state_${this.userService.authUser.id}`, columnState);
        this.update();
    }

    update(spinner = true) {
        if (spinner) {
            this.loading = true;
        }
        this.requestParams.size = this.paginationPageSize;
        this.requestParams.page = this.currentPageNumber - 1;
        const stateSort = this.sortState.find(_ => _?.startsWith('state'));
        if (stateSort) {
            this.sortState.push("id,desc");
        }
        this.requestParams.sort = this.sortState.length ? this.sortState : ['id,desc'];
        this.service
            .search(this.requestParams)
            .subscribe({
                next: (res: ScheduledTasksCollection) => {
                    this.rowData = res.content;
                    this.totalRowsCount = res.totalElements;
                    this.loading = false;
                },
                error: (error) => {
                    this.loading = false;
                    this.notificationService.error({
                        title: 'Scheduler',
                        message: 'An error occurred while loading tasks',
                        serviceName: 'SCH',
                        requestMessage: error.statusText,
                        requestCode: error.status,
                        ts: error.timestamp ? error.timestamp : null
                    });
                }
            });
    }

    formatDate(timestamp) {
        if (!timestamp) {
            return '';
        }
        const timeStamp = moment(timestamp).format('DD/MM/YYYY, HH:mm');
        return `<div class="two-lines">${timeStamp}</div>`;
    }

    formatNextSchedule(row: ScheduledTask) {
        if (row.state !== 'WAIT' && row.state !== 'SCHEDULED' && row.state !== 'RUNNING') {
            return '';
        }
        if (row.randomized) {
            return '-';
        }
        const h = SchedulerService.calculateSchedule(row, 0, 1);
        if (h.history.length) {
            const date = moment(h.history[0]).format('DD/MM/YYYY, HH:mm');
            return `<div class="two-lines">${date}</div>`;
        }
        return '';
    }

    formatState(row: ScheduledTask): string {
        switch (row.state) {
            case "SCHEDULED":
                return `<div class="one-line text-accent-a6"><i class="fas fa-history me-0_8"></i>Scheduled</div>`;
            case "WAIT":
                return `<div class="one-line text-primary-p4"><i class="icon-wait me-0_8"></i>Waiting</div>`;
            case "RUNNING":
                return `<div class="one-line text-primary-p4"><i class="icon-wait me-0_8"></i>Running</div>`;
            case "STOPPED":
                return `<div class="one-line text-error-r2"><i class="fas fa-stop me-0_9"></i>Stopped</div>`;
            case "PAUSED":
                return `<div class="one-line text-warning-p4"><i class="fas fa-pause me-0_8"></i>Paused</div>`;
            case "FINISHED":
                return `<div class="one-line"><i class="icon-check me-0_7"></i>Completed</div>`;
            default:
                break;
        }
    }

    formatDays(days: string[]): string {
        if (!days || !days.length) {
            return '-';
        }
        if (days.length === 7) return `<div class="two-lines">Everyday</div>`;
        days = CustomUtils.sortDays(days);
        const joinedDays = days.map(d => d.charAt(0).toUpperCase() + d.slice(1, 3).toLowerCase()).join(', ');
        return `<div class="two-lines">${joinedDays}</div>`;
    }

    formatRule(rule: ScheduledTaskReportRule): string {
        return SchedulerService.formatReportRule(rule);
    }

    showTaskDetails() {
        this.settingsLoader = true;
        this.service.getTaskSettings(this.currentTask.id).pipe(
            catchError(error => {
                this.notificationService.error({
                    title: 'Scheduler',
                    message: 'An error occurred while loading task details',
                    serviceName: 'SCH',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
                this.settingsLoader = false;
                return [];
            })
        ).subscribe({
            next: ({ task, template }) => {
                this.taskSettingsDetails = this.service.consolidateTaskSettings(task, template, null);
                this.detailsModal = this.modal.alert().dialogClass('modal-dialog large-modal').component(this.detailsModalTpl).open();
                this.settingsLoader = false;
            },
            error: (error) => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Scheduler',
                    message: 'An error occurred while loading task details',
                    serviceName: 'SCH',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ?? null
                });
            }
        });
    }

    onCellClick(event: CellClickedEvent) {
        this.currentTask = event.data;
        if (event.column.getColId() === 'title') {
            this.showTaskDetails();
        }
    }

    onClickExport() {
        this.exportSize = 0;
        this.exportSpinner = false;
        this.exportData = {
            url: undefined,
            size: undefined,
            sizeKb: undefined
        };
        this.modal.alert().dialogClass('modal-dialog small-modal').component(this.exportModalTpl).open();
    }

    export(limit) {
        if (limit === 'Custom') limit = this.customExportSize;
        this.exportSpinner = true;
        this.exportService.export('lnt', limit === 'All' ? 0 : limit, { taskIds: [this.currentTask.id] }).then(exportData => {
            this.exportData = exportData;
            this.exportSpinner = false;
        }).catch(error => {
            this.notificationService.error({
                title: 'Scheduler',
                message: 'An error occurred while export tests',
                serviceName: 'Scheduler',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
            this.exportSpinner = false;
        });
    }
    shouldShowPlay(row: ScheduledTask): boolean {
        let startAtTimestamp: number;
        if (typeof row.startAt === 'string') {
            startAtTimestamp = Date.parse(row.startAt);
        } else if (typeof row.startAt === 'number') {
            startAtTimestamp = row.startAt;
        } else {
            return false;
        }
        return (row.state === 'PAUSED' || row.state === 'STOPPED') && Date.now() < startAtTimestamp;
    }

    onSectionChange(sectionId: string) {
        this.currentSection = sectionId ? sectionId : this.taskSettingsDetails[0].id;
    }

    scrollTo(sectionId: string) {
        document.querySelector('#' + sectionId).scrollIntoView();
        setTimeout(() => {
            // Set timeout necessary because scrollspy will trigger on section change when document scrollBy is triggered...
            this.onSectionChange(sectionId);
        }, 0);
    }

    // AG GRID ...
    insertMenuItems(result: MenuItemDef[], params: GetContextMenuItemsParams, currentRowClicked: ScheduledTask): void {
        if (currentRowClicked.state === 'WAITING' || currentRowClicked.state === 'SCHEDULED') {
            result.unshift({
                name: 'Stop task',
                icon: '<span class="far fa-stop-circle text-error-r3 fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'stop', row: currentRowClicked, column: 'actions' });
                },
            });
        }
        if (this.shouldShowPlay(currentRowClicked)) {
            result.unshift({
                name: 'Play',
                icon: '<span class="fas fa-play text-primary-p4 fs-6"></span>',
                action: () => {
                    this.onAction({ name: 'play', row: currentRowClicked, column: 'actions' });
                },
            });
        }
    }

    getContextMenuItems = (
        params: GetContextMenuItemsParams
    ): (MenuItemDef)[] => {
        const currentRowClicked = params.node.data;
        let result: (MenuItemDef)[] = [
            {
                name: 'View scheduler settings',
                icon: '<span class="icon-tests-settings fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'settings', row: currentRowClicked, column: 'actions' });
                },
            },
            {
                name: 'View test results',
                icon: '<span class="icon-test-results fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'testResults', row: currentRowClicked, column: 'actions' });
                },
            },
            {
                name: 'Hide',
                icon: '<span class="far fa-eye-slash fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'hide', row: currentRowClicked, column: 'actions' });
                },
            },
            {
                name: 'Clone',
                icon: '<span class="far fa-clone fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'clone', row: currentRowClicked, column: 'actions' });
                },
            }
        ];
        this.insertMenuItems(result, params, currentRowClicked);
        return result;
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        const columnState = this.localStorage.get(`scheduler_table_state_${this.userService.authUser.id}`);
        if (columnState) {
            const idColumn = columnState.find(col => col.colId === 'id');
            if (idColumn?.sort === 'desc') {
                this.update();
            } else {
                this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
            }
        } else {
            this.update();
        }
    }

    // AG GRID ...

    ngOnDestroy() {
        if (this.scheduler) {
            clearInterval(this.scheduler);
        }
        this.actionSubscription.unsubscribe();
    }
}
