
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MoTestingRequest } from "../../../shared/models/mo-testing.model";
import { AuthUser } from "../../../shared/models/user.model";
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { MoTestingService } from "../../../shared/services/mo-testing.service";
import { ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { UsersService } from '../../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';
import { MoTextInputComponent } from "../mnt-text-input/mo-text-input.component";
import { MoNumberInputComponent } from "../mo-number-input/mo-number-input.component";
import { DestinationsSelectComponent } from '../../destinations-select/destinations-select.component';
import { PricingService } from '../../../shared/services/pricing.service';

@Component({
    selector: 'app-mo-form',
    templateUrl: 'mo-form.component.html',
    styleUrls: ['../../mnt/mnt-form/mnt-form.component.scss', 'mo-form.component.scss'],
})

export class MoFormComponent implements OnDestroy, AfterViewInit {

    @ViewChild(DestinationsSelectComponent, { static: false }) countries: DestinationsSelectComponent;
    @ViewChild(MoNumberInputComponent, { static: false }) numberInput: MoNumberInputComponent;
    @ViewChild(MoTextInputComponent, { static: false }) textInput: MoTextInputComponent;

    @ViewChild('confirmModalTpl', { read: TemplateRef, static: false }) confirmModalTpl: any;

    @Input() showButtons: boolean = true;
    @Input() autoRun: boolean = true;
    @Input() pauseUpdate: boolean = false;
    @Output() onAfterSave = new EventEmitter;
    @Output() onChangeCountResults = new EventEmitter;
    @Output() onLoad = new EventEmitter;

    valid: boolean = false;

    loading: boolean = false;

    countResults = 0;
    textsCount = 0;
    numbersCount = 0;

    maxTestsCount = 8000;
    maxTestsError = '';

    form: FormGroup;

    user: AuthUser;

    model = { testRepeatsPerIteration: 1 };

    schedulerUpdate: any;

    country = '';
    shortCodeCountries: string[] = [];
    currentSection: string = '';

    testsPrice: number = 0;

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        public formBuilder: FormBuilder,
        public service: MoTestingService,
        public userService: UsersService,
        public validationService: ValidationService,
        public modal: ModalService,
        public storage: LocalStorageService,
        private pricingService: PricingService
    ) {
        this.form = formBuilder.group({
            newTemplate: ['', Vld.compose([Vld.maxLength(1600)])],
            testRepeatsPerIteration: [''],
            count_results: ['', Vld.compose([Vld.min(1), Vld.max(this.maxTestsCount)])],
        });
    }

    ngAfterViewInit() {
        this.userService.getAuthUser().then(user => {
            this.user = user;
            this.init();
        }).catch(e => {
            this.init();
        });
    }

    init() {
        this.form.statusChanges.subscribe(() => {
            this.valid = this.form.valid;
        });
        this.loading = true;
        this.countries.update().subscribe({
            next: () => {
                this.onLoad.emit();
                this.loading = false;
            },
            error: err => this.loading = false
        });
    }

    onSubmit() {
        let dialogRef = this.modal.alert().component(this.confirmModalTpl).open();
        this.loading = true;
        this.pricingService.getTestPrices(this.countResults, 'MO').subscribe({
            next: (result: number) => {
                this.testsPrice = result;
                this.loading = false;
                dialogRef.result.then(result => {
                    if (result) {
                        this.save();
                    }
                });
            },
            complete: () => {
                this.loading = false;
            }
        });
    }

    private createModel(): MoTestingRequest {
        let model = this.service.create();
        model.destinations = this.numberInput.getNumbers();
        model.dynamicTexts = this.textInput.getTexts();
        model.amountX = this.model.testRepeatsPerIteration;
        model.origins = this.countries.getSelectedModels().map(c => {
            if (c.custom) {
                return {
                    countryName: null,
                    mcc: null,
                    mnc: null,
                    originalMnc: null,
                    originalProviderName: null,
                    providerName: null,
                    phone: c.phonenumber,
                    manualNumber: true
                };
            }
            return {
                countryName: c.countryName,
                mcc: c.mcc,
                mnc: c.mnc,
                originalMnc: c.originalMnc,
                originalProviderName: c.originalProviderName,
                providerName: c.providerName,
                manualNumber: false
            };
        });
        model.ttl = this.user.testTtl ? this.user.testTtl : model.ttl;
        return model;
    }

    private save() {
        let model = this.createModel();
        this.loading = true;
        return this.service.run(model).subscribe({
            next: res => {
                this.notificationService.success('Test case created', 'MO testing');
                this.loading = false;
                this.country = '';
                this.countries.setCountry('');
                this.form.get('testRepeatsPerIteration').patchValue(1);
                this.numberInput.reset();
                this.textInput.reset();
                this.countries.reset();
                this.shortCodeCountries = [];
                this.calculateCountResults();
                this.onAfterSave.emit(res);
                return model;
            },
            error: e => {
                this.notificationService.error({
                    title: 'MO testing',
                    message: 'An error occurred while running the tests',
                    service: 'MOTC',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                this.loading = false;
            }
        });
    }

    calculateCountResults() {
        if (!this.countries) {
            return;
        }
        this.countResults =
            (this.countries.getSelectedModels().length * this.model.testRepeatsPerIteration) *
            (this.textsCount) *
            (this.numbersCount);

        this.form.controls.count_results.patchValue(this.countResults);
        this.onChangeCountResults.emit(this.countResults);
        if (this.countResults > this.maxTestsCount) {
            this.maxTestsError = 'Max ' + this.maxTestsCount + ' tests in one batch';
        } else {
            this.maxTestsError = '';
        }
    }

    reset() {
        this.countries.reset();
        this.calculateCountResults();
    }

    resetAllSelectedValues(): void {
        this.numberInput.reset();
        this.textInput.reset();
        this.country = '';
        this.countries.setCountry('');
        this.countries.reset();
        this.form.get('testRepeatsPerIteration').patchValue(1);
    }

    ngOnDestroy() {
        if (this.schedulerUpdate) {
            clearInterval(this.schedulerUpdate);
        }
    }

    setNumbersCount(count) {
        this.numbersCount = count;
        if (!this.numbersCount) {
            const destinations = this.countries.getSelectedModels();
            if (!destinations.length) {
                this.country = '';
                this.countries.setCountry('');
                this.shortCodeCountries = [];
                this.countries.setShortCodeCountries(this.shortCodeCountries);
            }
        } else {
            const country = this.numberInput.country;
            this.country = country;
            this.countries.setCountry(country);
            this.shortCodeCountries = this.numberInput.shortCodeCountries;
            this.countries.setShortCodeCountries(this.shortCodeCountries);
        }
        this.calculateCountResults();
    }

    setTextsCount(count) {
        this.textsCount = count;
        this.calculateCountResults();
    }

    onChangeDestinations() {
        const destinations = this.countries.getSelectedModels();
        if (!this.country && destinations.length) {
            this.country = destinations[0].countryName;
        } else {
            if (!destinations.length) {
                this.country = this.numberInput.getNumbers().length > 0 ? this.country : '';
            }
        }
        this.countries.setCountry(this.country);
        this.calculateCountResults();
    }

    onSectionChange(sectionId: string) {
        this.currentSection = sectionId ? sectionId : 'phone';
    }

    scrollTo(sectionId: string) {
        document.querySelector('#' + sectionId).scrollIntoView();
        setTimeout(() => {
            // Set timeout necessary because scrollspy will trigger on section change when document scrollBy is triggered...
            this.onSectionChange(sectionId);
        }, 0);
    }
}
