import {
    Component,
    HostListener,
    ViewChild,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges, TemplateRef
} from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { StatisticsService } from '../../shared/services/statistics.service';
import * as Highcharts from 'highcharts';
import { TestCountUserGraph } from "../../shared/models/statistics.model";
import { LocalStorageService } from "../../shared/services/localStorage.service";
import { UsersService } from "../../shared/services/users.service";
import { BasicUser } from "../../shared/models/user.model";
import { ModalService } from "../../shared/services/modal.service";
import { Subscription } from "rxjs";

@Component({
    selector: 'app-dashboard-test-count-user-widget',
    templateUrl: './dashboard-test-count-user-widget.component.html',
    styleUrls: ['dashboard-test-count-user-widget.component.scss', '../shared.scss']
})

export class DashboardTestCountUserWidgetComponent implements AfterViewInit, OnChanges {

    Highcharts: typeof Highcharts = Highcharts;

    @ViewChild('usersModalTpl', {read: TemplateRef, static: false}) usersModalTpl: any;

    @ViewChild('chartContainer', {static: false}) chartContainer;

    chart: Highcharts.Chart;
    chartOptions: Highcharts.Options = {
        chart: {height: 190, style: {fontFamily: StatisticsService.chartFontFamily}},
        title: {text: 'Test sent', x: -20, style: {display: 'none'}},
        colors: StatisticsService.colors,
        xAxis: {
            type: 'datetime',
            gridLineWidth: 1,
            tickWidth: 0,
            allowDecimals: false,
            crosshair: StatisticsService.chartCrosshair
        },
        yAxis: [{title: {text: null}}],
        tooltip: StatisticsService.chartTooltip,
        legend: {align: 'center', borderWidth: 0},
        series: [],
        credits: { enabled: false },
        plotOptions: {
            areaspline: {
                fillOpacity: 0.3,
            }
        }
    };

    chartData: TestCountUserGraph;
    chartItems: 'submitted'|'billed' = 'submitted';
    visibleUsers: string[] = [];

    users: Map<number, BasicUser> = new Map();

    @Input() from: string;
    @Input() to: string;

    loading = true;

    private request: Subscription;

    @HostListener('window:resize') onResize() {this.resize();}
    resize() {
        if (!this.chartContainer || !this.chart) {return;}
        this.chart.setSize(this.chartContainer.nativeElement.offsetWidth, <number>this.chartOptions.chart.height);
    }

    constructor(
        public statistics: StatisticsService,
        public usersService: UsersService,
        public notificationService: NotificationService,
        public modal: ModalService,
        private localStorage: LocalStorageService
    ) {
        const chartItems = localStorage.get('dashboard_users_items', this.chartItems);
        if (chartItems === 'submitted' || chartItems === 'billed') {
            this.chartItems = chartItems;
        }
    }


    ngAfterViewInit() {
        this.resize();
        this.usersService.basicList(500, 1, '', []).subscribe(items => {
            if (items.content.length > 1) {
                items.content.forEach(user => this.users.set(user.id, user));
            }
            this.update();
        });
    }

    update() {
        if (!this.users.size) {return;}
        this.loading = true;
        if (this.request && !this.request.closed) {this.request.unsubscribe();}
        this.request = this.statistics.users(this.from, this.to).subscribe({
            next: data => {
                this.loading = false;
                let deletedUsers = [];
                data.users = data.users.map(u => {
                    const user = this.users.get(u.userId);
                    u.userTitle = user ? user.username : '';
                    return u;
                }).filter(u => {
                    if (u.userTitle === '') {
                        deletedUsers.push(u);
                        return false;
                    }
                    return true;
                });
                if (deletedUsers.length) {
                    let deletedUser = StatisticsService.mergeUsers(deletedUsers);
                    deletedUser.userTitle = 'Unknown users'
                    data.users.push(deletedUser);
                }
                data.users = data.users.sort((a, b) => a.sortValue > b.sortValue ? 1 : -1).reverse();
                this.chartData = data;
                if (this.chart) {
                    this.chart.hideLoading();
                    this.updateChart();
                }
                this.resize();
            },
            error: error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Dashboard',
                    message: 'An error occurred while loading statistics',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
                if (this.chart) {this.chart.showLoading('Error on loading');}
            }
        });
    }

    setChartItems(chartItems: 'submitted'|'billed') {
        if (this.chartItems === chartItems) {return;}
        this.chartItems = chartItems;
        this.localStorage.set('dashboard_users_items', chartItems);
        this.updateChart();
    }

    updateChart() {
        if (!this.chart) {return;}
        const visibleUsers = this.visibleUsers.length ? this.visibleUsers : this.chartData.users.slice(0, 5).map(_ => _.userTitle);
        this.chartData.users.forEach(items => {
            items.visible = visibleUsers.includes(items.userTitle);
        });
        this.chartData.users.forEach(items => {
            if (!items.visible) {
                let hide = this.chart.series.filter(_ => _.name === items.userTitle);
                if (hide.length) {
                    hide[0].remove();
                }
                return;
            }
            if (!this.chart.series.filter(_ => _.name === items.userTitle).length) {
                this.chart.addSeries({
                    type: 'areaspline',
                    name: items.userTitle,
                    lineWidth: 1,
                    marker: {radius: 2},
                    data: []
                });
            }
        });
        this.chart.update({
            xAxis: {tickInterval: this.chartData.tickInterval, labels: {step: this.chartData.labelStep}},
            series: this.chartData.users.filter(_ => _.visible).map(items => {
                let data = this.chartItems === 'submitted' ? items.submitted : items.billed;
                return {type: 'areaspline', name: items.userTitle, data: data.map(_ => _)};
            })
        });
    }

    setChart(chart: Highcharts.Chart) {
        this.chart = chart;
    }

    onClickUser(user) {
        user.visible = !user.visible;
    }

    onClickConfig() {
        this.modal.alert().component(this.usersModalTpl).open().onDestroy.subscribe(() => {
            this.visibleUsers = this.chartData.users.filter(_ => _.visible).map(_ => _.userTitle);
            while (this.chart.series.length) {this.chart.series[0].remove();}
            this.updateChart();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.from && changes.from.previousValue !== undefined) ||
            (changes.to && changes.to.previousValue !== undefined)
        ) {
            while (this.chart.series.length) {this.chart.series[0].remove();}
            this.update();
        }
    }
}
