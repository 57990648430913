import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Filter } from '../../shared/filter/filter';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { UsersService } from '../../shared/services/users.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { SchedulerReportSearchEvent } from '../../shared/services/scheduler.service';
import { RoutesSelectComponent } from '../../test/routes-select/routes-select.component';
import { DestinationsSelectComponent } from '../../test/destinations-select/destinations-select.component';

@Component({
    selector: 'app-scheduler-reports-search',
    templateUrl: './scheduler-reports-search.component.html',
    styleUrls: ['./scheduler-reports-search.component.scss']
})
export class SchedulerReportsSearchComponent implements OnInit {

    @Output() onSubmit = new EventEmitter<SchedulerReportSearchEvent>();

    @ViewChild(DestinationsSelectComponent, { static: false }) destinationComponent: DestinationsSelectComponent;
    @ViewChild(RoutesSelectComponent, { static: false }) supplierComponent: RoutesSelectComponent;

    isBeingDragged: boolean = false;
    filters: Filter[] = [
        { filterKey: 'ids', title: 'ID', show: false },
        { filterKey: 'dateReported', title: 'Date reported', show: true },
        // { filterKey: 'testIds', title: 'Test ID', show: true },
        { filterKey: 'scheduleName', title: 'Schedule Name', show: true },
        // { filterKey: 'testNumbers', title: 'Test Number', show: true },
        // { filterKey: 'countryNetworks', title: 'Country/Network', show: true },
        // { filterKey: 'suppliers', title: 'Supplier', show: true },
        // { filterKey: 'reasonForReport', title: 'Reason for report', show: true },
    ];
    form: FormGroup;

    constructor(
        private localStorage: LocalStorageService,
        private usersService: UsersService,
        private formBuilder: FormBuilder,
        public validationService: ValidationService
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            ids: [null, Vld.compose([Vld.digitsAndSpaces(true), Vld.maxLength(255)])],
            testIds: [null, Vld.compose([Vld.digitsAndSpaces(true), Vld.maxLength(255)])],
            scheduleName: [null, Vld.compose([Vld.maxLength(255)])],
            testNumbers: [null, Vld.compose([Vld.maxLength(255)])],
            createdAtFrom: [null],
            createdAtTo: [null],
            suppliers: [null],
            countries: [null],
            reasonForReport: [null, Vld.compose([Vld.maxLength(255)])],
        });
        const existingFilters = this.localStorage.get(`scheduler_reports_${this.usersService.authUser.id}_filter_state`) || [];
        if (existingFilters.length === this.filters.length) {
            this.filters = existingFilters;
        } else {
            existingFilters.forEach(ef => {
                this.filters.filter(_ => _.filterKey === ef.filterKey).forEach(f => {
                    f.show = ef.show;
                });
            });
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.filters, event.previousIndex, event.currentIndex);
        this.localStorage.set(`scheduler_reports_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    getPeekForSelectedValues(filter): number | string | null {
        switch (filter.filterKey) {
            default:
                return null;
        }
    }

    isFilterActive(filter: Filter): boolean {
        switch (filter.filterKey) {
            case 'ids':
                return this.form.get('ids').value?.length > 0;
            case 'testIds':
                return this.form.get('testIds').value?.length > 0;
            case 'scheduleName':
                return this.form.get('scheduleName').value?.length > 0;
            case 'testNumbers':
                return this.form.get('testNumbers').value?.length > 0;
            case 'suppliers':
                return this.supplierComponent?.getSelectedModels().length > 0;
            case 'countryNetworks':
                return this.destinationComponent?.getSelectedModels().length > 0;
            case 'dateReported':
                return this.form.get('createdAtFrom').value || this.form.get('createdAtTo').value;
            case 'reasonForReport':
                return this.form.get('reasonForReport').value?.length > 0;
            default:
                return false;
        }
    }

    onClear(filterKey: string) {
        switch (filterKey) {
            case 'ids':
                this.form.get('ids').setValue('');
                break;
            case 'testIds':
                this.form.get('testIds').setValue('');
                break;
            case 'scheduleName':
                this.form.get('scheduleName').setValue('');
                break;
            case 'testNumbers':
                this.form.get('testNumbers').setValue('');
                break;
            case 'reasonForReport':
                this.form.get('reasonForReport').setValue('');
                break;
            default:
                break;
        }
        this.onSubmitClick();
    }

    onSubmitClick(filterKey: string = null) {
        let searchData = this.form.getRawValue();
        const ids = searchData.ids?.split(' ').filter(Boolean).map(Number);
        const testNumbers = searchData.testNumbers?.split(' ').filter(Boolean).map(Number);
        searchData = { ...searchData, ids: ids, testNumbers: testNumbers, title: searchData.scheduleName, };
        this.onSubmit.emit(searchData);
    }

    resetAll(): void {
        this.form.reset();
        this.destinationComponent?.reset();
        this.supplierComponent?.reset();
    }

    shouldAddFilterBeShown(): boolean {
        const hiddenFilters = this.filters.filter(_ => _.show === false);
        return hiddenFilters.length > 0;
    }

    deleteItem(event: CdkDragDrop<string[]>) {
        const indexToHide = event.previousIndex;
        this.filters[indexToHide].show = false;
        this.onClear(this.filters[indexToHide].filterKey);
        this.localStorage.set(`scheduler_reports_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    onAdditionalFilterClick(filter): void {
        filter.show = !filter.show;
        this.localStorage.set(`scheduler_reports_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    onClearAllFilter(): void {
        this.localStorage.set(`scheduler_reports_${this.usersService.authUser.id}_filter_state`, this.filters);
        this.resetAll();
        this.onSubmitClick();
    }

    onChangeDate(dates): void {
        const { startIso, endIso } = dates;
        this.form.get('createdAtFrom').setValue(startIso);
        this.form.get('createdAtTo').setValue(endIso);
        this.onSubmitClick();
    }

    onRouteChange(): void {
        const supplierControl: FormControl = this.form.get('suppliers') as FormControl;
        supplierControl.setValue(this.supplierComponent.getSelectedModels());
        this.onSubmitClick();
    }

    onDestinationChange(): void {
        const destinationControl: FormControl = this.form.get('countries') as FormControl;
        destinationControl.setValue(this.destinationComponent.getSelectedModels());
        this.onSubmitClick();
    }
}
