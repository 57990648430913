import {Injectable} from "@angular/core";
import { AuthUser } from "../models/user.model";

@Injectable()
export class FreshChatService {

    static init() {

    }

    static setUser(user: AuthUser) {
        if (window['fcWidget'] !== undefined && !user.impersonated) {
            window['fcWidget'].user.setEmail(user.email)
            window['fcWidget'].user.setProperties({
                email: user.email,
                firstName: user.fullName,
                company: user.companyName,
                userId: user.id,
                platform: location.host.split('.')[0]
            });
        }
    }
}
