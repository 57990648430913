
import { DatePipe } from "@angular/common";
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CellClickedEvent, ColDef, ColumnMovedEvent, ColumnResizedEvent, ColumnVisibleEvent, GridReadyEvent, ICellRendererParams, RowHeightParams } from "ag-grid-community";
import { debounceTime } from "rxjs";
import { AGTableBase } from "../../../shared/components/table/ag-table-base";
import { SpecialPricingGroup, SpecialPricingGroupCollection, Tier } from "../../../shared/models/pricing.model";
import { CustomUtils } from "../../../shared/services/custom-utils";
import { LocalStorageService } from "../../../shared/services/localStorage.service";
import { ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { PricingGroupActionData, PricingService, SpecialPricingRequestParams } from "../../../shared/services/pricing.service";
import { UsersService } from '../../../shared/services/users.service';
import { SpecialPricingGroupTableActionsComponent } from "./special-pricing-group-table-actions/special-pricing-group-table-actions.component";
import { SpecialPricingSubTableComponent } from "./special-pricing-sub-table/special-pricing-sub-table.component";

@Component({
    selector: 'app-special-pricing-group-table',
    templateUrl: './special-pricing-group-table.component.html',
    styleUrls: ['./special-pricing-group-table.component.scss'],
})

export class SpecialPricingGroupTableComponent extends AGTableBase implements OnInit {

    @Output() actions: EventEmitter<PricingGroupActionData> = new EventEmitter();
    @Output() onAfterDelete: EventEmitter<void> = new EventEmitter();
    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;
    @ViewChild('customersModalTpl', { read: TemplateRef, static: false }) customersModalTpl: any;
    isAdmin: boolean = false;
    requestParams = new SpecialPricingRequestParams();
    loading: boolean = false;
    currentRow: SpecialPricingGroup;

    // AG GRID ...
    columnDefs: ColDef[] = [
        {
            headerName: 'ID', field: 'id',
            maxWidth: 90, minWidth: 90,
            sortable: true, comparator: () => 0, initialSort: 'desc', sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line d-flex justify-content-center align-items-center gap-10 ${_.status === 'ACTIVE' ? 'text-sucess-s2' : 'text-error-r3'}"> 
                ${_.status === 'ACTIVE' ? '<i class="far fa-check-circle fs-4"></i>' : '<i class="far fa-times-circle fs-4"></i>'}
                ${_.id}
                </div>`;
            }
        },
        {
            headerName: 'Customers', field: 'users',
            minWidth: 150, maxWidth: 900,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="two-lines clickable cp"><span>${_.users.map(user => user.email).join(', ')}</span></div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event),
        },
        {
            headerName: 'Type', field: 'paymentType',
            maxWidth: 100, hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.paymentType}</div>`;
            }
        },
        {
            headerName: 'Title', field: 'title',
            minWidth: 150, maxWidth: 900,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="two-lines clickable"><span>${_.title}</span></div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event),
        },
        {
            headerName: 'Products active', field: 'serviceList',
            minWidth: 100, maxWidth: 150,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.serviceList.map(_ => _ === 'VOICE' ? 'CLI' : _).join(', ')}</div>`;
            }
        },
        {
            headerName: 'Amount', field: 'totalCommitment',
            minWidth: 100, maxWidth: 100,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line ${_.paymentType === 'PREPAID' ? 'text-sucess-s2' : 'text-error-r3'}" title="${_.paymentType === 'PREPAID' ? 'topup' : 'commitment'}">
                ${_.totalCommitment} EUR
                </div>`;
            }
        },
        {
            headerName: 'Created on', field: 'createdAt',
            minWidth: 90, maxWidth: 90, hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                const date = CustomUtils.formatDate(_.createdAt);
                return `<div class="two-lines"><span>${date[1]} <br>${date[0]}</span></div>`;
            }
        },
        {
            headerName: 'Last modified', field: 'lastModified',
            minWidth: 90, maxWidth: 90,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data; const date = CustomUtils.formatDate(_.lastModified);
                return `<div class="two-lines"><span>${date[1]} <br>${date[0]}</span></div>`;
            }
        },
        {
            headerName: 'Deactivate/ revive offer', field: 'enabled',
            minWidth: 110, maxWidth: 110, headerTooltip: 'Deactivate/ revive offer',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellClass: 'justify-content-center',
            cellRenderer: (params: ICellRendererParams) => {
                const isChecked = params.data.enabled ? 'checked' : '';
                return `<div class="form-check form-switch d-flex justify-content-center">
                            <input type="checkbox" class="form-check-input medium" ${isChecked}/>
                        </div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onDeactivateOfferChange(event),
        },
        {
            headerName: 'New pricing scheduled', field: 'scheduledChange',
            hide: true, maxWidth: 100,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.scheduledChange ? 'Yes' : 'No'}</div>`;
            }
        },
        {
            headerName: 'Actions', field: 'actions', maxWidth: 100, minWidth: 100,
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
            tooltipField: 'actions',
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: SpecialPricingGroupTableActionsComponent,
            onCellClicked: (event: CellClickedEvent) => this.onActionClick(event),
        }
    ];
    rowData!: SpecialPricingGroup[];
    specialSubtable = SpecialPricingSubTableComponent;

    getRowHeight(params: RowHeightParams) {
        const users = params.data.users;
        const lineHeight = 20;
        const padding = 16;
        const customersText = users.map(user => user.email).join(', ');
        const estimatedLines = Math.ceil(customersText.length / 50);
        const height = (estimatedLines * lineHeight) + padding;
        return Math.max(48, height);
    }
    // AG GRID ...

    constructor(
        public notificationService: NotificationService,
        public service: PricingService,
        public userService: UsersService,
        public modal: ModalService,
        private datePipe: DatePipe,
        private localStorage: LocalStorageService,
    ) {
        super();
    }

    ngOnInit() {
        this.actionSubscription = this.service.action$.subscribe(
            action => {
                this.onAction(action);
            }
        );
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            const currentColumnState = this.gridApi.getColumnState();
            this.localStorage.set(`pricing_special_table_state_${this.userService.authUser.id}`, currentColumnState);
        });
        this.paginationPageSize = this.localStorage.get(`special_table_component_size_${this.userService.authUser.id}`) || 20;
    }

    update() {
        this.loading = true;
        this.requestParams.size = this.paginationPageSize;
        this.requestParams.page = this.currentPageNumber - 1;
        this.requestParams.sort = this.sortState.length ? this.sortState : ['id,desc'];

        this.service.allSpecialGroups(this.requestParams)
            .subscribe({
                next: (collection: SpecialPricingGroupCollection) => {
                    this.rowData = collection.content;
                    this.totalRowsCount = collection.totalElements
                    this.loading = false;
                },
                error: err => {
                    this.loading = false;
                    this.notificationService.error({
                        title: 'Special pricing',
                        message: 'An error occurred while loading the pricing groups',
                        serviceName: 'GATEWAY',
                        requestMessage: err.statusText,
                        requestCode: err.status
                    });
                }
            });
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
        this.localStorage.set(`special_table_component_size_${this.userService.authUser.id}`, size);
        this.update();
    }

    onCellClick(event: CellClickedEvent) {
        if (event.column.getColId() === 'title') {
            setTimeout(() => {
                event.node.setExpanded(!event.node.expanded);
            }, 100);
            return;
        }
        if (event.column.getColId() === 'users') {
            this.currentRow = event.data;
            this.modal.alert().dialogClass('modal-dialog small-modal').component(this.customersModalTpl).open();
        }

    }

    private sortTiers(tiers: Tier[]): Tier[] {
        let sortedTiers: Tier[] = [];
        let tiersByService: { [serviceType: string]: Tier[] } = {};
        tiers.forEach(t => {
            if (!tiersByService[t.serviceType]) {
                tiersByService[t.serviceType] = [];
            }
            tiersByService[t.serviceType].push(t);
        });
        for (let serviceType in tiersByService) {
            tiersByService[serviceType].sort((a, b) => {
                if (a.quota > b.quota) { return 1; }
                if (a.quota < b.quota) { return -1; }
                return 0;
            });
            tiersByService[serviceType].forEach(_ => sortedTiers.push(_));
        }

        return sortedTiers;
    }

    private onDeactivateOfferChange(event: CellClickedEvent): void {
        if ((event.event.target as HTMLElement).classList.contains('form-check-input')) {
            this.loading = true;
            this.service
                .changeEnabledGroup(event.data.id, !event.data.enabled)
                .subscribe({
                    next: () => {
                        this.notificationService.success('Pricing group updated', 'General pricing');
                        this.update();
                    },
                    error: error => {
                        this.notificationService.error({
                            title: 'Special pricing',
                            message: 'Pricing group updating an error',
                            serviceName: 'GATEWAY',
                            requestMessage: error.statusText,
                            requestCode: error.status
                        });
                        this.loading = false;
                    }
                });
        }
    }

    onAction(event: PricingGroupActionData) {
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row);
                }
            });
        }
        this.actions.emit(event);
    }

    delete(group) {
        this.loading = true;
        this.service
            .deleteSpecialGroup(group.id)
            .subscribe(() => {
                this.notificationService.success('Pricing group removed', 'Special pricing');
                this.onAfterDelete.emit();
                this.update();
            }, error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Special pricing',
                    message: 'Pricing group deleting an error',
                    serviceName: 'GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            });
    }

    formatTotalCommitment(row) {
        if (!row) {
            return '';
        }
        const pt = row.paymentType === 'PREPAID' ? 'top up' : 'commitment';
        return row.totalCommitment + ' EUR ' + pt;
    }

    formatServices(row): string {
        row.serviceList = row.serviceList.map(_ => _ === 'VOICE' ? 'CLI' : _);
        return row.serviceList.join(', ');
    }

    formatUsers(row): string {
        return row.users.map(_ => _.email).join(', ');
    }

    formatDate(timestamp) {
        if (timestamp === null) {
            return '';
        }
        return this.datePipe.transform(new Date(timestamp), 'MMM d, y');
    }

    displayScheduleChange(row) {
        return row.scheduledChange ? 'Yes' : 'No';
    }

    // AG Grid

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.update();
        const columnState = this.localStorage.get(`pricing_special_table_state_${this.userService.authUser.id}`);
        this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
    }

    // AG Grid

    ngOnDestroy() {
        this.actionSubscription?.unsubscribe();
    }

}
