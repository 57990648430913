import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {
    AppscriptsPaymentRequest,
    AppscriptsPaymentRequestCollection,
    AppscriptsPaymentRequestFilter, AppscriptsPayoutCrypto,
    AppscriptsPayoutPaypal
} from '../models/appscripts-payment-request.model';
import { RestUtils } from './rest-utils';
import { map } from "rxjs/operators";

@Injectable()
export class AppscriptsPaymentRequestService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    private prepareSort(attrs: string[]): string[] {
        const prSortAttrs = ['id', 'createdAt', 'paymentMethodName', 'paymentAddress', 'amount', 'status', 'withdrawalAvailable'];
        return attrs.map(attr => {
            let desc = attr.indexOf('-') === 0 ? '-' : '';
            let attrName = desc ? attr.substring(1) : attr;
            return prSortAttrs.includes(attrName) ? `${desc}${attrName}` : `${desc}${attrName}`;
        });
    }

    all(params: AppscriptsPaymentRequestParams): Observable<AppscriptsPaymentRequestCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: (params.sort && params.sort.length) ? this.prepareSort(params.sort) : [],
        };
        if (params.filter) {
            for (let i in params.filter) {
                queryParams[i] = params.filter[i];
            }
        }
        let url = this.utils.buildUrl('ROLE/appscr/data/payment-requests', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsPaymentRequestCollection>(url, options);
    }

    updateStatus(paymentRequestId: number, status: string): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/payment-requests/${paymentRequestId}/status`, {
            status: status
        });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put(url, null, options);
    }

    updateWithdrawalAvailable(paymentRequestId: number, withdrawalAvailable: boolean): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/payment-requests/${paymentRequestId}/withdrawal-available`, {
            withdrawalAvailable: withdrawalAvailable
        });
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put(url, null, options);
    }

    updateComment(id: number, comment: string): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/payment-requests/${id}/comment`, {
            comment: comment
        });
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.put(url, null, options);
    }

    payoutPaypal(paymentRequestId: number): Observable<AppscriptsPayoutPaypal> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/payment-requests/${paymentRequestId}/payout-paypal`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsPayoutPaypal>(url, options);
    }

    payoutCrypto(paymentRequest: AppscriptsPaymentRequest): Observable<AppscriptsPayoutCrypto> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/payment-requests/${paymentRequest.id}/payout-crypto`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsPayoutCrypto>(url, options).pipe(
            map(p => {
                if (p.transactionFee) {
                    const fee = Number(p.transactionFee);
                    p.transactionFee = fee > 0 ? p.transactionFee : '';
                }
                if (p.transactionHash) {
                    p.transactionLink = this.getBlockchanLink(paymentRequest, p.transactionHash);
                }
                return p;
            })
        );
    }

    private getBlockchanLink(paymentRequest: AppscriptsPaymentRequest, hash: string) {
        const paymentMethodName = paymentRequest.paymentMethodName.toLowerCase();
        if (paymentMethodName === 'bitcoin') {
            return `https://www.blockchain.com/explorer/transactions/btc/${hash}`;
        }
        return '';
    }

    getPayoutType(pr: AppscriptsPaymentRequest): 'paypal'|'crypto' {
        const map = {
            'paypal': 'paypal',
            'bitcoin': 'crypto',
            'litecoin': 'crypto',
            'ethereum': 'crypto',
        }
        const mthod = pr.paymentMethodName.toLowerCase();
        if (!map[mthod]) {
            throw new Error('Unexpected method ' + pr.paymentMethodName)
        }

        return map[mthod]
    }

    delete(paymentRequestId: number): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/payment-requests/${paymentRequestId}`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options);
    }
}

export class AppscriptsPaymentRequestParams {
    size: number = 20;
    page: number = 1;
    filter: AppscriptsPaymentRequestFilter;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

