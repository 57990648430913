import { Component, OnInit } from '@angular/core';
import { PricingGroup } from '../../../../shared/models/pricing.model';
import { ICellRendererParams } from 'ag-grid-community';
import { PricingService } from '../../../../shared/services/pricing.service';

@Component({
    selector: 'app-special-pricing-group-table-actions',
    templateUrl: './special-pricing-group-table-actions.component.html',
    styleUrls: ['./special-pricing-group-table-actions.component.scss']
})
export class SpecialPricingGroupTableActionsComponent {

    currentRow: PricingGroup;

    constructor(private pricingService: PricingService) { }

    agInit(params: ICellRendererParams): void {
        this.currentRow = params.data;
    }

    refresh(params: ICellRendererParams) {
        return true;
    }

    onClick(clickedItem: string) {
        this.pricingService.announcePricingGroupAction({ name: clickedItem, row: this.currentRow, column: 'action' });
    }

}
