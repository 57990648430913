import { Component, OnInit } from '@angular/core';
import { ColDef, ColumnMovedEvent, ColumnResizedEvent, ColumnVisibleEvent, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { debounceTime } from 'rxjs';
import { AGTableBase } from '../../../shared/components/table/ag-table-base';
import { AppscriptsSmsService, AppscriptsVoiceCallsRequestParams } from '../../../shared/services/appscripts-sms.service';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { UsersService } from '../../../shared/services/users.service';

@Component({
    selector: 'app-appscripts-cli-table',
    templateUrl: './appscripts-cli-table.component.html',
    styleUrls: ['./appscripts-cli-table.component.scss'],
})
export class AppscriptsCliTableComponent extends AGTableBase implements OnInit {

    loading: boolean = false;
    requestParams: AppscriptsVoiceCallsRequestParams = new AppscriptsVoiceCallsRequestParams();

    // Ng Grid ...
    columnDefs: ColDef[] = [
        {
            headerName: 'ID', field: 'id',
            flex: 1,
            sortable: true, comparator: () => 0, initialSort: 'desc', sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.id}</div>`;
            }
        },
        {
            headerName: 'Phone', field: 'phoneNumber',
            flex: 1,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.phoneNumber}</div>`;
            }
        },
        {
            headerName: 'CLI', field: 'callerPhoneNumber',
            flex: 1,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.callerPhoneNumber || ''}</div>`;
            }
        },
        {
            headerName: 'Date created', field: 'createdAt',
            flex: 1,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatDate(_.callCreatedAt);
            }
        },
        {
            headerName: 'Date delivered', field: 'resultReceivedAt',
            flex: 1,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            minWidth: 300,
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: false,
            tooltipField: 'actions',
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatDate(_.resultReceivedAt);
            }
        },
    ]

    rowData: any[] = [];
    // Ng Grid ...

    constructor(
        private appscriptsSmsService: AppscriptsSmsService,
        private notificationService: NotificationService,
        private localStorage: LocalStorageService,
        private userService: UsersService
    ) {
        super();
    }

    ngOnInit() {
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            const currentColumnState = this.gridApi.getColumnState();
            this.localStorage.set(`appscripts_cli_table_state_${this.userService.authUser.id}`, currentColumnState);
        });
        this.paginationPageSize = this.localStorage.get(`appscripts_cli_table_component_size_${this.userService.authUser.id}`) || 20;
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
        this.localStorage.set(`appscripts_cli_table_component_size_${this.userService.authUser.id}`, size);
        this.update();
    }

    formatDate(_: string) {
        if (_) {
            return `<div class="one-line"><span>${_[1]} ${_[0]}</span></div>`;
        }
        return '';
    }

    update() {
        this.loading = true;
        this.requestParams.size = this.paginationPageSize;
        this.requestParams.page = this.currentPageNumber - 1;
        this.requestParams.sort = this.sortState;

        this.appscriptsSmsService.allVoiceCall(this.requestParams).subscribe({
            next: res => {
                this.rowData = res.content;
                this.rowData.map(row => row.emailVerified = row.emailVerified === '1' ? true : false);
                this.totalRowsCount = res.totalElements;
                this.loading = false;
            },
            error: error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Users',
                    message: 'Loading users an error',
                    serviceName: 'GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            }
        });
    }

    // Ng Grid ...
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.update();
        const columnState = this.localStorage.get(`appscripts_cli_table_state_${this.userService.authUser.id}`);
        this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
    }

    // Ng Grid ...

}
