import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { InputSearchDatePeriod } from "../../shared/components/input/input.search.datePeriod";
import { Role } from "../../shared/models/user.model";
import { AuditLogService } from "../../shared/services/audit-log.service";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";
import { RestUtils } from "../../shared/services/rest-utils";
import { UsersService } from "../../shared/services/users.service";
import { FormControl } from "@angular/forms";
import { Subscription, debounceTime, distinctUntilChanged } from "rxjs";

@Component({
    selector: "audit-filter-form-component-v2",
    templateUrl: "./audit-filter-form.component.html",
    styleUrls: ["./audit-filter-form.component.scss"],
})
export class AuditFilterFormComponentV2 implements OnInit {
    @Output() onSubmit: EventEmitter<any> = new EventEmitter();
    @ViewChild(InputSearchDatePeriod, { static: false })
    datePeriod: InputSearchDatePeriod;

    usersCollection = [];
    showUsers: boolean = false;

    model = {
        phrase: "",
        from: "",
        to: "",
        testGroupId: "",
    };

    exportSize: string | number = "Custom";
    customExportSize = 1000;
    exportSizes = ["Custom", "All"];
    exportData: any = {};
    exportSpinner = false;

    @ViewChild("exportModalTpl", { read: TemplateRef, static: false })
    exportModalTpl: any;

    loading: boolean = false;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(
        public elementRef: ElementRef,
        public modal: ModalService,
        public userService: UsersService,
        private service: AuditLogService,
        private notificationService: NotificationService,
    ) { }

    ngOnInit() {
        this.userService
            .getAuthUser()
            .then((user) => {
                this.showUsers =
                    user.role === Role.ADMIN ||
                    user.role === Role.MAIN ||
                    user.role === Role.DEPUTY;
            })
            .catch((e) => { });
        this.searchSubscription = this.searchControl.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe((searchValue) => {
                this.model.phrase = searchValue;
                this.onSubmitClick();
            });
    }

    resetAll(): void {
        this.model = {
            phrase: "",
            from: "",
            to: "",
            testGroupId: "",
        };
        this.usersCollection = [];
    }

    onClear(filterKey: "date" | "users" | "testGroupId") {
        switch (filterKey) {
            case "date":
                if (this.datePeriod) {
                    this.datePeriod.model = "";
                    this.datePeriod.control.patchValue("");
                    this.datePeriod.reset();
                }
                this.model.from = "";
                this.model.to = "";
                this.onChangeDate(null);
                break;
            case "users":
                this.usersCollection = [];
                break;
            case "testGroupId":
                this.model.testGroupId = "";
                break;
        }
        this.onSubmitClick();
    }

    onClearAllFilter(): void {
        this.resetAll();
        this.onSubmitClick();
    }

    onChangeDate(dates) {
        if (dates instanceof Event || !dates) {
            return;
        }
        this.model.from = dates.startIso;
        this.model.to = dates.endIso;
        this.onSubmitClick();
    }

    update() {
        this.loading = true;
    }

    onSubmitClick() {
        this.onSubmit.emit({
            search: this.model.phrase,
            createdAtFrom: this.model.from ? this.model.from : "",
            createdAtTo: this.model.to ? this.model.to : "",
            filterUsers: this.usersCollection.map((u) => u.id),
            testGroupId: this.model.testGroupId ? this.model.testGroupId : "",
        });
    }

    onSelectUser(user) {
        this.usersCollection.push(user);
        this.onSubmitClick();
    }

    onUnSelectUser(user) {
        this.usersCollection = this.usersCollection.filter((u) => user.id !== u.id);
        this.onSubmitClick();
    }

    onClickExport() {
        if (this.userService.authUser.role === Role.ADMIN && this.usersCollection.length === 0) {
            this.notificationService.error({
                title: "Audit logs",
                message: "Please select a user from the filter to export audit logs",
                serviceName: "Audit Logs",
            });
            return;
        }
        this.exportSize = "Custom";
        this.exportSpinner = false;
        this.exportData = {};
        this.modal
            .alert()
            .dialogClass("modal-dialog small-modal")
            .component(this.exportModalTpl)
            .open();
    }

    getTooltipContent() {
        if (this.userService.authUser.role === Role.ADMIN && this.usersCollection.length === 0) {
            return "Please select users to export audit logs";
        }
        return 'Export audit logs';
    }

    export(size: number | string) {
        if (size === "Custom") size = this.customExportSize;
        this.exportSpinner = true;
        const params = {
            totalSize: size === "All" ? null : size,
            filter: {
                search: this.model.phrase,
                createdAtFrom: this.model.from ? this.model.from : "",
                createdAtTo: this.model.to ? this.model.to : "",
                userIds: this.usersCollection.map((u) => u.id),
                testGroupId: this.model.testGroupId ? this.model.testGroupId : "",
            },
        };
        this.service
            .export(params)
            .then((exportResponse) => {
                this.exportData.filename = exportResponse.filePath;
                this.exportData.download_url = exportResponse.downloadCallback;
                this.exportSpinner = false;
            })
            .catch((error) => {
                this.exportSpinner = false;
                this.notificationService.error({
                    title: "Audit logs",
                    message: `Error : ${error.response.status}`,
                    serviceName: "Audit Logs",
                });
            });
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }
}
