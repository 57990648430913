import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Report } from '../../../shared/models/scheduled-task.model';
import { CustomUtils } from '../../../shared/services/custom-utils';

@Component({
    selector: 'app-reason-for-report',
    templateUrl: './reason-for-report.component.html',
    styleUrls: ['./reason-for-report.component.scss']
})
export class ReasonForReportComponent {

    currentRow: Report;
    constructor() { }

    agInit(params: ICellRendererParams): void {
        this.currentRow = params.data;
    }

    getIcon(state: string): string {
        const status = this.currentRow?.reportRuleDto?.[state] ?? null;
        if (status) {
            const icon = CustomUtils.getIconForStatus(status);
            return icon;
        }
        return 'fas fa-minus fs-4 mx-0_8 text-neutral-n7';
    }

    getExpectedRepetitionsClass(): string {
        const currentExpectedRepetition = this.currentRow?.reportRuleDto?.repetitionsTarget ?? null;
        if (!(currentExpectedRepetition) || currentExpectedRepetition === 1) {
            return `fas fa-minus text-neutral-n7`;
        }
        return '';
    }

    getExpectedRepetitions(): string {
        const currentExpectedRepetition = this.currentRow?.reportRuleDto?.repetitionsTarget ?? null;
        if (currentExpectedRepetition && currentExpectedRepetition !== 1) {
            return `x ${currentExpectedRepetition} reps`;
        }
    }
}
