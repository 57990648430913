import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ScheduledTask } from '../../../shared/models/scheduled-task.model';
import { SchedulerService } from '../../../shared/services/scheduler.service';

@Component({
    selector: 'app-scheduler-table-actions',
    templateUrl: './scheduler-table-actions.component.html',
    styleUrls: ['./scheduler-table-actions.component.scss']
})
export class SchedulerTableActionsComponent {

    currentRow: ScheduledTask;
    hiddenMode: boolean = false;

    constructor(private schedulderService: SchedulerService) { }

    agInit(params: ICellRendererParams): void {
        this.currentRow = params.data;
        this.hiddenMode = this.schedulderService.getHiddenMode();
    }

    refresh(params: ICellRendererParams) {
        return true;
    }

    onClick(clickedItem: string) {
        this.schedulderService.announceAction({ name: clickedItem, row: this.currentRow, column: 'action' });
    }

    shouldShowPlay(): boolean {
        let startAtTimestamp: number;
        if (typeof this.currentRow.startAt === 'string') {
            startAtTimestamp = Date.parse(this.currentRow.startAt);
        } else if (typeof this.currentRow.startAt === 'number') {
            startAtTimestamp = this.currentRow.startAt;
        } else {
            return false;
        }
        return (this.currentRow.state === 'PAUSED' || this.currentRow.state === 'STOPPED') && Date.now() < startAtTimestamp;
    }


}
