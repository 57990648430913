
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { RowDef } from '../../shared/components/table/table.component';
import { BatchTasks } from '../../shared/services/batch-tasks';
import { ContentTemplateService } from '../../shared/services/content-template.service';
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { TemplatesService } from '../../shared/services/templates.service';
import { ContentTemplatesTableComponent } from '../content-templates-table/content-templates-table.component';
import { Location } from '@angular/common';
import { ContentTemplateImportErrorCollection, ContentTemplate } from '../../shared/models/content-templates';
import { ImportResult } from '../../shared/models/import-result.model';

@Component({
    selector: 'app-content-template-index',
    templateUrl: './content-templates-index.component.html',
    styleUrls: ['./content-templates-index.component.scss'],
})

export class ContentTemplatesIndexComponent {

    @ViewChild(ContentTemplatesTableComponent, { static: false }) table: ContentTemplatesTableComponent;

    @ViewChild('createContentTemplateModalTpl', { read: TemplateRef, static: false }) createContentTemplateModalTpl: any;
    createContentTemplateModal: DialogRef;

    @ViewChild('editContentTemplateModalTpl', { read: TemplateRef, static: false }) editContentTemplateModalTpl: any;
    editContentTemplateModal: DialogRef;

    @ViewChild('importModalTpl', { read: TemplateRef, static: false }) importModalTpl: any;
    importModal: DialogRef;

    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;

    importLoading: boolean = false;
    importFile: File = null;
    importResult: ImportResult;
    currentContentTemplateId: number;
    importTemplatesFileList;
    backendImportErrors: ContentTemplateImportErrorCollection;


    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(
        private contentTemplateService: ContentTemplateService,
        public templates: TemplatesService,
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title,
        public modal: ModalService,
        private location: Location,
    ) {
        titleService.setTitle('Templates');
    }

    ngOnInit() {
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.onChangeInputSearch(searchValue);
        });
    }

    clearUrlQuery(): void {
        // Clear the URL with any ids present ...
        const currentPath = this.location.path();
        const currentHistoryState = this.location.getState();
        const newPath = currentPath.split('?')[0];
        this.location.replaceState(newPath, '', currentHistoryState);
    }

    templateAction(event) {
        if (event.name === 'edit') {
            const currentUrl = this.router.url.split('?')[0];
            const navigationExtras: NavigationExtras = {
                queryParams: { 'id': event.row.data.id },
                queryParamsHandling: 'merge',
                preserveFragment: true,
            };
            this.router.navigate([currentUrl], navigationExtras);
            this.editContentTemplateModal = this.modal.alert().dialogClass('modal-dialog extra-large-modal').component(this.editContentTemplateModalTpl).open();
            this.editContentTemplateModal.result.then((res) => {
                console.log('Edit: ', res);
                this.router.navigate([currentUrl]);
            });
        }
    }

    onClickContentTemplateCreate(): void {
        this.createContentTemplateModal = this.modal.alert().dialogClass('modal-dialog extra-large-modal').component(this.createContentTemplateModalTpl).open();
        this.createContentTemplateModal.result.then((res) => {
            console.log('Create: ', res);
            this.clearUrlQuery();
        });
    }

    onBatchDelete() {
        this.modal.confirm().open().result.then(res => {
            if (!res) { return; }
            this.batchDelete();
        });
    }

    private batchDelete() {
        const tasks = this.selectedRows.map(row => {
            return this.contentTemplateService.delete(row.data.id).pipe(map(() => {
                this.notificationService.success('Content template deleted', 'Content Templates');
                row.batch = false;
                this.completeTask();
            }), catchError((err, caught) => {
                this.notificationService.error('Content template delete error', 'Content Templates');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    onChangeInputSearch(phrase) {
        this.table.requestParams.search = phrase.trim();
        this.table.page = 1;
        this.table.update();
    }

    startTasks(tasks: Observable<any>[]) {
        this.progressShow = true;
        this.batchCompleted = 0;
        this.table.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.table.loading = false;
            this.progressShow = false;
            this.selectedRows = [];
            this.table.update();
        }
    }

    batchClear(): void {
        this.progressShow = false;
        this.selectedRows = [];
        this.table.resetBatch();
    }

    afterSave(data: boolean): void {
        if (data) this.table.update();
        if (this.editContentTemplateModal) this.editContentTemplateModal.close();
        if (this.createContentTemplateModal) this.createContentTemplateModal.close();
    }

    onClickImport() {
        this.importModal = this.modal.alert().component(this.importModalTpl).open();
        this.importModal.result.then(res => {
            this.onClickImportBack();
        });
        this.importLoading = false;
        this.importResult = null;
        this.importFile = null;
    }

    onClickImportBack() {
        this.importLoading = false;
        this.importResult = null;
        this.importFile = null;
        this.backendImportErrors = null;
        this.importTemplatesFileList = null;
    }

    onImportContentTemplates(contentTemplates: any): void {
        const contentTemplatesToSave: ContentTemplate[] = [];
        const senderContentMap = {};
        contentTemplates.forEach(ct => {
            if (ct.senderId in senderContentMap) {
                senderContentMap[ct.senderId].push(ct);
            } else {
                senderContentMap[ct.senderId] = [ct];
            }
        });
        for (let key of Object.keys(senderContentMap)) {
            const template = this.contentTemplateService.create();
            template.name = key;
            template.visibleAll = false;
            senderContentMap[key].forEach(v => {
                template.smsTemplateTextDtos.push({
                    value: v.text,
                    description: v.description,
                    messageType: v.messageType,
                    userFavourite: v.favourite,
                    telqIdType: v.telqIdType,
                    telqIdLength: v.telqIdLength,
                    telqIdCase: v.telqIdCase
                });
            });
            contentTemplatesToSave.push(template);
        }
        this.importContentTemplates(contentTemplatesToSave);
    }

    importContentTemplates(contentTemplates: ContentTemplate[]): void {
        if (contentTemplates.length < 1) {
            this.importModal.close();
            this.table.update();
            return;
        };
        this.importLoading = true;
        this.contentTemplateService.import(contentTemplates).subscribe({
            next: (res) => {
                this.importLoading = false;
                if (res.errors.length > 0) {
                    this.notificationService.warning('Not all rows could be imported.', 'Content Templates');
                    this.backendImportErrors = res;
                } else {
                    this.notificationService.success(
                        `Imported rows: ${res.imported} <br> Skipped rows: ${res.skipped}`,
                        'Content Templates');
                    this.importModal.close();
                    this.table.update();
                }
            }, error: (err) => {
                this.notificationService.error({
                    title: 'Content templates',
                    message: 'An error occurred while import content templates',
                    serviceName: 'NTC',
                    requestMessage: err.statusText,
                    requestCode: err.status,
                    ts: err.timestamp ? err.timestamp : null
                });
                this.importLoading = false;
            }
        });
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }
}
