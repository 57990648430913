import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import {SpecialPricingGroupTableComponent} from "../special-pricing-group-table/special-pricing-group-table.component";
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged, Subscription } from "rxjs";
import { PricingGroupActionData, SpecialPricingSearchEvent } from '../../../shared/services/pricing.service';

@Component({
    selector: 'app-special-pricing-group-index',
    templateUrl: './special-pricing-group-index.component.html',
    styleUrls: ['special-pricing-group-index.component.scss'],
})

export class SpecialPricingGroupIndexComponent implements OnInit {

    @ViewChild(SpecialPricingGroupTableComponent, {static: false}) table: SpecialPricingGroupTableComponent;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title
    ) {
        titleService.setTitle('Special pricing');
    }

    ngOnInit() {
    }

    groupAction(event: PricingGroupActionData) {
        if (event.name === 'edit') {
            this.router.navigate(['pricing-special', event.row.id]).then();
        }
    }

    onSubmitSearchForm(searchData: SpecialPricingSearchEvent) {
        this.table.requestParams.searchData = searchData;
        this.table.currentPageNumber = 1;
        this.table.update();
    }

    ngOnDestroy() {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
    }
}
