import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { BrowserUtils } from "../../shared/services/browser-utils";
import * as he from "he";

@Component({
    selector: 'app-text-template',
    templateUrl: './text-template.component.html',
    styleUrls: ['./text-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})

export class TextTemplateComponent implements OnInit {

    PLACEHOLDER_TEMPLATE = `<span title="Test ID text" class="icon-telq-id id" contenteditable="false"><span class="id-move"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span></span>`;
    PLACEHOLDER_TEMPLATE_MANUAL = `<span title="Test ID text" class="icon-telq-id id id-manual" contenteditable="false"><span class="id-click"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span><span class="id-move"><i class="fas fa-grip-vertical ms-0_2 text-neutral-n6"></i></span></span>`;
    static PLACEHOLDER_MARKER = '{TEST_ID_TEXT}'

    @Input() isDisabled = false;
    @Output() changeText = new EventEmitter();
    @Output() markerClick = new EventEmitter();

    skipInputEvent = false;
    manualMode = false;
    markerPos = 0;
    draggable = false;

    constructor(public elementRef: ElementRef) {
        this.manualMode = BrowserUtils.isSafari();
        if (this.manualMode) {
            this.PLACEHOLDER_TEMPLATE = this.PLACEHOLDER_TEMPLATE_MANUAL;
        }
    }

    getEditor() {
        return this.elementRef.nativeElement.getElementsByClassName('text-template-editor').item(0);
    }

    ngOnInit() {
        const editor = this.getEditor();
        editor.addEventListener('input', e => {
            if (this.skipInputEvent) {
                return;
            }
            this.handleChangeText();
        });
        editor.addEventListener('paste', e => {
            e.preventDefault();
            this.skipInputEvent = true;
            const text = e.clipboardData.getData("text/plain");
            document.execCommand("insertText", false, text);
            this.skipInputEvent = false;
            this.handleChangeText();
        });

        editor.addEventListener('mouseup', e => {
            if (!this.draggable) {
                return;
            }
            this.stopDraggable(editor, e);
        });
    }

    bindDraggables() {
        const editor = this.getEditor();
        const placeholder = editor.getElementsByClassName('id').item(0);
        if (!placeholder) {
            return;
        }
        if (this.manualMode) {
            placeholder.getElementsByClassName('id-click').item(0).addEventListener('click', () => {
                this.markerClick.emit();
            });
            placeholder.getElementsByClassName('id-move').item(0).addEventListener('click', e => {
                this.startDraggable(editor, placeholder);
            });
        } else {
            placeholder.getElementsByClassName('id-move').item(0).addEventListener('mousedown', e => {
                this.startWatchPosition(editor, placeholder);
            });
        }

    }

    /**
     * cursor moves 2 pixels - start draggable
     * cursor is not moved and mouseup - markerClick.emit()
     */
    startWatchPosition(editor, placeholder) {
        let isClick = false;
        let x = null, y = null;
        const shift = 2;
        const upHandler = () => {
            if (!isClick) {
                editor.removeEventListener('mousemove', moveHandler);
                placeholder.removeEventListener('mouseup', upHandler);
                this.markerClick.emit();
                isClick = true;
            }
        }
        const moveHandler = e => {
            if (isClick) {
                editor.removeEventListener('mousemove', moveHandler);
                placeholder.removeEventListener('mouseup', upHandler);
                return;
            }
            let newX = e.clientX;
            let newY = e.clientY;
            if (x === null || y === null) {
                x = newX;
                y = newY;
            }
            let diffX = Math.abs(x - newX);
            let diffY = Math.abs(y - newY);
            if (diffX >= shift || diffY >= shift) {
                editor.removeEventListener('mousemove', moveHandler);
                placeholder.removeEventListener('mouseup', upHandler);
                this.startDraggable(editor, placeholder);
            }
        };
        placeholder.addEventListener('mouseup', upHandler);
        editor.addEventListener('mousemove', moveHandler);
    }

    startDraggable(editor, placeholder) {
        this.draggable = true;
        placeholder.style.position = 'absolute';
        placeholder.style.opacity = 0.5;
        placeholder.style.cursor = 'text';
        placeholder.classList.add('draggable');
        window.getSelection().removeAllRanges();
        editor.classList.add('dragging');
        editor.addEventListener('mousemove', this.onMouseMoveEditor);
    }

    stopDraggable(editor, e) {
        this.draggable = false;
        const pos = this.getCaretPosition(editor, e);
        editor.getElementsByClassName('draggable').item(0).remove();
        editor.classList.remove('dragging');
        const text = this.clearText(editor.innerText);
        let resultText = [
            he.encode(text.slice(0, pos.position > text.length ? text.length : pos.position)),
            this.PLACEHOLDER_TEMPLATE,
            he.encode(pos.position > text.length ? '' : text.slice(pos.position))
        ].join('');
        if (this.markerPos === 0 && resultText[0] === ' ') {
            resultText = resultText.slice(1);
        }
        editor.innerHTML = resultText;
        this.handleChangeText();
        this.bindDraggables();
        this.skipInputEvent = false;
        editor.removeEventListener('mousemove', this.onMouseMoveEditor);
    }

    onMouseMoveEditor = (e) => {
        const pos = e.currentTarget.getBoundingClientRect();
        const placeholder = e.currentTarget.getElementsByClassName('id').item(0);
        placeholder.style.left = (e.clientX - pos.left) + 'px';
        placeholder.style.top = (e.clientY - pos.top) + 'px';

        if (!BrowserUtils.getBrowserName().includes('firefox')) {
            const editor = this.getEditor();
            const position = this.getCaretPosition(editor, e);
            this.updateMarkerPosition(editor, position.position);
            if (position.position === position.textLength) {
                // This is needed because when the placeholder is at the end of the text and
                // instead of moving the cursor it starts selecting ..
                const sel = window.getSelection();
                sel.removeAllRanges();
            }
        }
    }

    updateMarkerPosition(editor, position) {
        const range = document.createRange();
        const sel = window.getSelection();

        try {
            const node = editor.childNodes[0];
            const validPosition = Math.min(position, node.length);

            range.setStart(node, validPosition);
            range.collapse(true);

            sel.removeAllRanges();
            sel.addRange(range);
        } catch (e) {
            // Chances are that the range is invalid, so we ignore it
            console.error('Error updating marker position:', e);
        }
    }

    clearText(text) {
        if (!text) {
            return '';
        }
        return text;
    }

    setEditorContent(html) {
        const editor = this.getEditor();
        html = this.clearText(html);
        if (html.trim() === '') {
            editor.innerHTML = '';
            this.handleChangeText();
            return;
        }
        if (html.indexOf(TextTemplateComponent.PLACEHOLDER_MARKER) === -1) {
            html = TextTemplateComponent.PLACEHOLDER_MARKER + ' ' + html;
        }
        html = html.replace(TextTemplateComponent.PLACEHOLDER_MARKER, this.PLACEHOLDER_TEMPLATE);
        editor.innerHTML = html;
        this.handleChangeText();
        this.bindDraggables();
    }

    handleChangeText() {
        const editor = this.getEditor();
        if (!editor.getElementsByClassName('id').length) {
            editor.innerHTML = this.PLACEHOLDER_TEMPLATE + ' ' + editor.innerHTML;
            this.bindDraggables();
            this.toEnd();
        }

        const el = document.createElement('div');
        el.innerHTML = this.clearHtml(this.clearText(editor.innerHTML));
        el.getElementsByClassName('id').item(0).innerHTML = TextTemplateComponent.PLACEHOLDER_MARKER;
        const text = el.textContent;
        el.remove();
        this.markerPos = text.indexOf(TextTemplateComponent.PLACEHOLDER_MARKER);
        this.changeText.emit(text);
    }

    clearHtml(htmlString: string) {
        let value = htmlString;
        if (value.indexOf('<div>') === 0) {
            value = this.removeLastInstance('</div>', value.replace('<div>', ''));
        }

        value = value.replace(/<div><br><\/div>/gi, '<br>');
        // Convert encoding.
        //value = value.replace(/&nbsp;/gi, ' ');
        value = value.replace(/&amp;/gi, '&');
        value = value.replace(/<br>/gi, '\n');
        // Replace `<div>` (from Chrome).
        value = value.replace(/<div>/gi, '\n');
        // Replace `<p>` (from IE).
        value = value.replace(/<p>/gi, '\n');
        value = this.rtrim(value, "\n");
        // Expose string.
        return value;
    }

    rtrim(str, chr) {
        const rgxtrim = (!chr) ? new RegExp('\\s+$') : new RegExp(chr + '+$');
        return str.replace(rgxtrim, '');
    }

    removeLastInstance(search, str) {
        let pos = str.lastIndexOf(search);
        if (pos < 0) return str;
        let one = str.substring(0, pos);
        let two = str.substring(pos + (search.length));
        return one + two;
    }

    toEnd() {
        const editor = this.getEditor();
        if (editor && document.createRange) {
            let range, selection;
            range = document.createRange();//Create a range (a range is a like the selection but invisible)
            range.selectNodeContents(editor);//Select the entire contents of the element with the range
            range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
            selection = window.getSelection();//get the selection object (allows you to change selection)
            selection.removeAllRanges();//remove any selections already made
            selection.addRange(range);//make the range you have just created the visible selection
        }
    }

    getCaretPosition(editor, e) {
        if (!window.getSelection) {
            throw new Error('Unsupported');
        }

        const selection = window.getSelection();
        if (!selection.rangeCount && !this.manualMode) {
            let rangeEv = null;
            if (document.caretRangeFromPoint) { // Chrome
                rangeEv = document.caretRangeFromPoint(e.clientX, e.clientY);
            } else if (e.rangeParent) { // Firefox
                rangeEv = document.createRange();
                rangeEv.setStart(e.rangeParent, e.rangeOffset);
            }
            if (!rangeEv) {
                return { position: 0, textLength: 0 };
            }
            selection.addRange(rangeEv);
        }

        const range = selection.rangeCount ? selection.getRangeAt(0) : null;
        if (!range) {
            return { position: 0, textLength: 0 };
        }

        let position = range.endOffset;
        let textLength = 0;

        editor.childNodes.forEach(node => {
            if (node.nodeType === 3) { // Text node
                if (node === range.commonAncestorContainer) {
                    position += textLength;
                }
                textLength += node.length;
            }
        });

        return { position, textLength };
    }
}
