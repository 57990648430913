import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MoShortCodeCollection } from '../../../shared/models/mo-shortcode.model';
import { CustomUtils } from "../../../shared/services/custom-utils";
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { MoShortcodeService } from '../../../shared/services/mo-shortcode.service';
import { DialogRef, ModalService } from '../../../shared/services/modal.service';
import { NetInfoService } from "../../../shared/services/net-info.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';

@Component({
    selector: 'app-mo-number-input',
    templateUrl: 'mo-number-input.component.html',
    styleUrls: ['mo-number-input.component.scss']
})

export class MoNumberInputComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() country: string;
    @Input() shortCodeCountries: string[] = [];
    @Input() source: string = '';

    @Output() changeCount: EventEmitter<number> = new EventEmitter();

    @ViewChild('moShortCodeModalTpl', { read: TemplateRef, static: false }) moShortCodeModalTpl: any;
    moShortCodeModal: DialogRef;

    icon: 'loading' | 'save' | '' = '';

    numbers: string[] = [];

    val = ''
    valid = null;
    valCountry = '';

    numbersLength = 0;
    isAddPlusEnabled: boolean = false;

    constructor(
        public validationService: ValidationService,
        public netInfoService: NetInfoService,
        private moShortCodeService: MoShortcodeService,
        public formBuilder: FormBuilder,
        public modal: ModalService,
        public notificationService: NotificationService,
        private localStorage: LocalStorageService,
    ) {
        this.form = formBuilder.group({});
    }

    ngOnInit() {
        if (!this.form.contains('number')) {
            const validator = Vld.globalPhoneNumber(true)
            this.form.addControl('number', new FormControl('', validator))
        }
        this.isAddPlusEnabled = this.localStorage.get('MO_isAddPlusEnabled');
    }

    onChangeSender() {
        this.icon = this.val ? 'save' : '';
        this.valid = null;
        this.valCountry = '';
        this.calculateCount();
    }

    onBlur() {
        const val = String(this.val).trim();
        if (!val || val.length < 1) { return; }
        if (this.valid === null && this.icon !== 'loading') {
            this.addNumber();
        }
    }

    editNumber(number) {
        this.val = number;
        this.removeNumber(number);
        this.valid = null;
        this.valCountry = '';
    }

    getNumbers(): string[] {
        return this.numbers;
    }

    isShortCodeAllowed(): boolean {
        if (this.source === 'mosearch') return true;
        // Hide shortcode popup menu when long codes are present ...
        if (this.numbers.length > 0 && this.shortCodeCountries.length == 0) {
            return false;
        }
        // Hide if multiple countries are associated with on shortcode ...
        if (this.numbers.length < this.shortCodeCountries.length) {
            return false;
        }
        return true;
    }

    addNumber(): void {
        const val = String(this.val).trim();
        if (this.source !== 'mosearch') {
            let exists = this.numbers.filter(_ => _ === val);
            if (exists.length) {
                this.notificationService.error({
                    title: 'MO testing',
                    message: 'Phone number already selected.',
                    service: 'MOTC'
                });
                return;
            }

            // Short and long codes is present already then long code should not be used ...
            // If longcode is present already then short code should not be used ...

            this.calculateCountryForNumber(val);
        } else {
            this.valid = null;
            this.icon = '';
            if (this.isAddPlusEnabled && !val.startsWith('+')) {
                this.val = `+${val}`;
            }
            this.numbers.push(this.val);
            this.val = '';
        }
    }

    addPhoneNumber(phoneNumber: string): boolean {
        let exists = this.numbers.filter(_ => _ === phoneNumber);
        if (exists.length) {
            this.notificationService.error({
                title: 'MO testing',
                message: 'Phone number already selected.',
                service: 'MOTC'
            });
            return false;
        }
        this.icon = '';
        this.numbers.push(phoneNumber);
        this.calculateCount();
    }

    calculateCountryForNumber(val: string): void {
        this.icon = 'loading';
        // First we call MO service to check if number is a shortcode
        // If no content is returned, then we get country info from network service instead
        this.moShortCodeService.getDetailsForShortCode(val).subscribe({
            next: (res: MoShortCodeCollection) => {
                if (res.content.length > 0) {
                    const countriesForShortCodes = res.content.map(r => r.country.replace(/\([^)]*\)/g, "").trim());
                    if (this.source !== 'mosearch' && this.shortCodeCountries.length > 0 && !this.shortCodeCountries.includes(countriesForShortCodes[0])) {
                        this.notificationService.error({
                            title: 'MO testing',
                            message: 'Cannot add this shortcode. Shortcode with different country already present.',
                            service: 'MOTC'
                        });
                        this.icon = 'save';
                    } else if (this.numbers.length > 0 && this.shortCodeCountries.length == 0) {
                        this.notificationService.error({
                            title: 'MO testing',
                            message: 'Cannot use both long and short code.',
                            service: 'MOTC'
                        });
                        this.icon = 'save';
                    } else if (this.numbers.length > 0 && this.shortCodeCountries.length > 0 && this.numbers.length < this.shortCodeCountries.length) {
                        this.notificationService.error({
                            title: 'MO testing',
                            message: 'Cannot add more shortcodes. The existing shortcode is present in multiple countries.',
                            service: 'MOTC'
                        });
                        this.icon = 'save';
                    } else {
                        this.valid = true;
                        this.shortCodeCountries = countriesForShortCodes;
                        this.icon = '';
                        this.val = '';
                        this.country = '';
                        this.numbers.push(val);
                        this.calculateCount();
                    }
                } else {
                    this.callNetworkInfoService(val);
                }
            },
            error: (err) => {
                this.callNetworkInfoService(val);
            }
        });
    }

    callNetworkInfoService(val: string): void {
        if (this.isAddPlusEnabled && !val.startsWith('+')) {
            val = `+${val}`;
        }
        this.netInfoService.countryForNumber(val).subscribe({
            next: r => {
                if (this.numbers.length > 0 && this.shortCodeCountries.length > 0) {
                    this.notificationService.error({
                        title: 'MO testing',
                        message: 'Cannot add a long code to shortcodes.',
                        service: 'MOTC'
                    });
                    this.icon = 'save';
                    return;
                }
                if (!r || !r.name) {
                    this.valid = false;
                    this.valCountry = '';
                    this.icon = 'save';
                }
                const country = r.name;
                if (!this.country) {
                    this.country = country;
                    this.valid = null;
                    this.val = '';
                    this.numbers.push(val);
                    this.calculateCount();
                    return;
                }
                this.valCountry = country;
                this.valid = this.country === country;
                if (this.valid) {
                    this.val = '';
                    this.valid = null;
                    this.icon = '';
                    this.numbers.push(val);
                    this.calculateCount();
                    return;
                } else {
                    this.icon = 'save';
                }
            },
            error: e => {
                this.valid = false;
                this.valCountry = '';
                this.icon = 'save';
            }
        });
    }

    removeNumber(number: string) {
        this.numbers = this.numbers.filter(_ => _ !== number);
        this.calculateCount();
    }

    calculateCount(): void {
        let count = this.numbers.length + (this.val && this.valid ? 1 : 0);
        if (count !== this.numbersLength) {
            this.numbersLength = count;
            this.changeCount.emit(this.numbersLength);
        }
    }

    reset() {
        this.val = '';
        this.valid = null;
        this.valCountry = '';
        this.icon = '';
        this.numbers = [];
        this.calculateCount();
    }

    inputListText(text: string): string {
        return CustomUtils.text(text, 40);
    }

    onKeydownInput(event: KeyboardEvent) {
        if (event.key === 'Enter') { this.addNumber(); event.preventDefault(); }
    }

    openShortCodeList(): void {
        this.moShortCodeModal = this.modal.alert()
            .dialogClass('modal-dialog large-modal')
            .component(this.moShortCodeModalTpl)
            .open();
    }

    onSelectShortCode(event): void {
        this.country = event.country.replace(/\([^)]*\)/g, "").trim();
        this.shortCodeCountries.push(this.country);
        this.addPhoneNumber(event.phoneNumber);
    }

    onUnSelectShortCode(event): void {
        this.country = '';
        this.shortCodeCountries = this.shortCodeCountries.filter(scc => scc !== event.country);
        this.removeNumber(event.phoneNumber);
    }

    onClickAddPlus(): void {
        this.isAddPlusEnabled = !this.isAddPlusEnabled;
        this.localStorage.set("MO_isAddPlusEnabled", this.isAddPlusEnabled);
        this.notificationService.success(`Add '+' ${this.isAddPlusEnabled ? 'enabled' : 'disabled'}`, 'MO testing');
    }

    setNumbers(numbers: string[]): void {
        this.numbers = numbers;
    }

}
