
import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InputSearchDatePeriod } from '../../../shared/components/input/input.search.datePeriod';
import { LIVE_NUMBER_TEST_STATUSES, MESSAGE_STATES } from '../../../shared/models/test-group.model';
import { AuthUser, Role } from '../../../shared/models/user.model';
import { ExportService } from '../../../shared/services/export.service';
import { LiveNumberTestingService, SearchParams } from '../../../shared/services/live-number-testing.service';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { UsersService } from '../../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';
import { UsersSelectComponent } from '../../../users/users-select/users-select.component';
import { DestinationsSelectComponent } from '../../destinations-select/destinations-select.component';
import { RoutesSelectComponent } from '../../routes-select/routes-select.component';
import { UdhTlvInputComponent } from "../../udh-tlv-input/udh-tlv-input.component";
import { CustomUtils } from '../../../shared/services/custom-utils';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Filter } from '../../../shared/filter/filter';
declare var moment: any;

@Component({
    selector: 'app-lnt-search-form',
    templateUrl: 'lnt-search-form.component.html',
    styleUrls: ['lnt-search-form.component.scss'],
})

export class LntSearchFormComponent implements OnInit {
    usersModal: DialogRef;

    @Output() onSubmit = new EventEmitter;
    @Output() onHide = new EventEmitter;

    @ViewChild(DestinationsSelectComponent) countries: DestinationsSelectComponent;
    @ViewChild(RoutesSelectComponent) connections: RoutesSelectComponent;
    @ViewChild(UsersSelectComponent) users: UsersSelectComponent;
    @ViewChild(InputSearchDatePeriod) datePeriod: InputSearchDatePeriod;
    @ViewChildren(InputSearchDatePeriod) childDatePeriods: QueryList<InputSearchDatePeriod>;
    @ViewChild('filterTopBar', { static: false }) filterTopBar: ElementRef;
    @ViewChild('tlvListComponent', { read: UdhTlvInputComponent, static: false }) tlvListComponent: UdhTlvInputComponent;
    @ViewChild('exportModalTpl', { read: TemplateRef, static: false }) exportModalTpl: any;

    usersCollection = [];
    showUsers: boolean = false;
    onlyMyTests: boolean = false;
    userId: number;
    saveUserIds: boolean = true;
    filters: Filter[] = [
        { filterKey: 'date', title: 'Date', show: true },
        { filterKey: 'destinations', title: 'Destinations', show: true },
        { filterKey: 'suppliers', title: 'Suppliers', show: false },
        { filterKey: 'users', title: 'Users', show: false },
        { filterKey: 'statuses', title: 'Status', show: true },
        { filterKey: 'searchById', title: 'ID', show: false },
        { filterKey: 'searchByPhoneNumbers', title: 'Phone', show: false },
        { filterKey: 'searchByText', title: 'Text', show: false },
        { filterKey: 'searchByComment', title: 'Comment', show: false },
        { filterKey: 'commentDate', title: 'Comment Date', show: false },
        { filterKey: 'searchByTestIdText', title: 'Test ID Text', show: false },
        { filterKey: 'searchBySenderId', title: 'Sender ID', show: false },
        { filterKey: 'tlv', title: 'TLV tag and value', show: false },
        { filterKey: 'testGroupIds', title: 'Test group ID', show: false },
        { filterKey: 'taskIds', title: 'Scheduled task ID', show: false },
    ];

    model = {
        ids: '',
        telqIds: '',
        dlr_statuses: [],
        receipt_statuses: [],
        user_ids: [],
        connections: [],
        sender: '',
        networks: [],
        from: null,
        to: null,
        commentText: null,
        text: null,
        commentDateFrom: null,
        commentDateTo: null,
        phoneNumbers: '',
        testGroupIds: '',
        taskIds: null,
        showOnlyScheduledTests: false,
        tlv: {
            tagHex: null,
            valueHex: null
        }
    };
    dlrStatuses = [];
    receiptStatuses = [];

    form: FormGroup;

    user: AuthUser;

    loading = false;

    tlvValid = true;
    exportSize: string | number = 'Custom';
    customExportSize = 1000;
    exportSizes = ['Custom', 'All'];
    exportData: any = {};
    exportSpinner = false;
    isBeingDragged: boolean = false;

    constructor(
        public userService: UsersService,
        public modal: ModalService,
        public formBuilder: FormBuilder,
        public validationService: ValidationService,
        public localStorage: LocalStorageService,
        public exportService: ExportService,
        public notificationService: NotificationService,
        private liveNumberTestingService: LiveNumberTestingService
    ) {
        MESSAGE_STATES.forEach(_ => this.dlrStatuses.push(_));
        LIVE_NUMBER_TEST_STATUSES.forEach(_ => this.receiptStatuses.push(_));

        this.validationService = validationService;

        this.form = formBuilder.group({
            ids: ['', Vld.compose([Vld.digitsAndSpaces(true), Vld.maxLength(255)])],
            telqIds: ['', Vld.compose([Vld.maxLength(255)])],
            testGroupIds: ['', Vld.compose([Vld.digitsAndSpaces(true), Vld.maxLength(255)])],
            taskIds: ['', Vld.compose([Vld.digitsAndSpaces(true), Vld.maxLength(255)])],
            phoneNumbers: ['', Vld.compose([Vld.maxLength(255)])],
            tlvTag: ['', Vld.compose([UdhTlvInputComponent.createTagValidator(2, false), Vld.hex(true)])],
            tlvValue: ['', Vld.compose([UdhTlvInputComponent.createValueValidator(false), Vld.hex(true)])],
        });
        this.form.statusChanges.subscribe(_ => {
            ValidationService.getInvalidControls(this.form)
        });
    }

    ngOnInit() {
        this.userService.getAuthUser().then(user => {
            this.user = user;
            const isAdmin = user.role === Role.ADMIN;
            const isMain = user.role === Role.MAIN;
            const isDeputy = user.role === Role.DEPUTY;
            this.showUsers = (isAdmin || isMain || isDeputy || user.showAllResults);
            const existingFilters = this.localStorage.get(`lnt_${this.user.id}_filter_state`) || [];
            if (existingFilters.length === this.filters.length) {
                this.filters = existingFilters;
            } else {
                existingFilters.forEach(ef => {
                    this.filters.filter(_ => _.filterKey === ef.filterKey).forEach(f => {
                        f.show = ef.show;
                    });
                });
            }
            this.filters = this.filters.filter(f => {
                if (!this.showUsers && f.filterKey === 'users') {
                    return false;
                }
                return true;
            });
            this.update();
        });
    }

    ngAfterViewInit() {
        this.countries?.reset();
        this.connections?.reset();
        if (this.childDatePeriods.length > 0) {
            const commentDateComponent = this.childDatePeriods.filter(child => child.type === 'commentDate')[0];
            if (commentDateComponent) commentDateComponent.reset();
        }
    }

    onChangeTlv() {
        const tag = this.model.tlv.tagHex;
        const value = this.model.tlv.valueHex;
        this.tlvValid = (!tag && !value) || (tag && value);
    }

    onChangeDate(dates) {
        if (dates instanceof Event || typeof dates === 'string') {
            return;
        }
        if (dates?.startStr && dates?.endStr) {
            this.model.from = dates.startStr + '.000Z';
            this.model.to = dates.endStr + '.999Z';
            this.onSubmitClick();
        } else {
            this.model.from = null;
            this.model.to = null;
        }
    }

    shouldAddFilterBeShown(): boolean {
        const hiddenFilters = this.filters.filter(_ => _.show === false);
        return hiddenFilters.length > 0;
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.filters, event.previousIndex, event.currentIndex);
        this.localStorage.set(`lnt_${this.user.id}_filter_state`, this.filters);
    }

    deleteItem(event: CdkDragDrop<string[]>) {
        const indexToHide = event.previousIndex;
        this.filters[indexToHide].show = false;
        this.onClear(this.filters[indexToHide].filterKey);
        this.localStorage.set(`lnt_${this.user.id}_filter_state`, this.filters);
    }

    update() {
        this.loading = true;
        const savedUserSearchDetails = this.localStorage.get(`lnt_user_search_${this.user.id}_search`);
        if (savedUserSearchDetails) {
            this.saveUserIds = true;
            this.populateSavedDetails(savedUserSearchDetails);
        } else {
            this.resetAll();
        }
        this.loading = false;
    }

    populateSavedDetails(savedModel): void {
        this.model.telqIds = savedModel.telqIds.join(', ');
        if (savedModel.ids && savedModel.ids.length) {
            this.model.ids = savedModel.ids.join(' ');
        }
        if (savedModel.testGroupIds && savedModel.testGroupIds.length) {
            this.model.testGroupIds = savedModel.testGroupIds.join(' ');
        }
        if (savedModel.taskIds && savedModel.taskIds.length) {
            this.model.taskIds = savedModel.taskIds.join(' ');
        }
        this.model.phoneNumbers = savedModel.destinations.filter(_ => _.manualNumber).map(_ => _.phone).join('\n');
        this.model.sender = savedModel.senders[0];
        this.model.from = savedModel.createdAtFrom;
        this.model.to = savedModel.createdAtTo;
        this.usersCollection = savedModel.usersCollection;
        // Check if Show only my tests is enabled... This means usersCollection has only one id of current logged in user ...
        if (savedModel.userIds[0] === this.user.id) {
            this.onlyMyTests = true;
        }
        this.dlrStatuses.map(dlrstatus => {
            if (savedModel.messageStates.includes(dlrstatus.id)) {
                dlrstatus.selected = true;
            }
        });
        this.receiptStatuses.map(recieptstatus => {
            if (savedModel.testStatuses.includes(recieptstatus.id)) {
                recieptstatus.selected = true;
            }
        });

        this.model.commentText = savedModel.commentText;
        this.model.commentDateFrom = savedModel.commentDateFrom;
        this.model.commentDateTo = savedModel.commentDateTo;
        if (savedModel.text) {
            this.model.text = savedModel.text;
        }

        setTimeout(() => {
            if (this.datePeriod) {
                this.datePeriod.start = Date.parse(this.model.from) / 1000;
                this.datePeriod.end = Date.parse(this.model.to) / 1000;
                this.datePeriod.updateValue();
            }
            if (this.childDatePeriods.length > 0) {
                const commentDateComponent = this.childDatePeriods.filter(child => child.type === 'commentDate')[0];
                if (commentDateComponent) {
                    commentDateComponent.start = Date.parse(this.model.commentDateFrom) / 1000;
                    commentDateComponent.end = Date.parse(this.model.commentDateTo) / 1000;
                    commentDateComponent.updateValue();
                }
            }
        }, 200);

        if (savedModel.tlv) {
            this.model.tlv = savedModel.tlv;
        }
    }

    getPopulatedModel(): any {
        let model: SearchParams = {};
        model.sessionSupplierIds = this.connections ? this.connections.getSelectedModels().map(_ => {
            return { supplierId: _.supplierId, sessionId: _.sessionId };
        }) : [];
        model.destinations = this.countries ? this.countries.getSelectedModels().map(_ => {
            if (typeof _.custom !== 'undefined' && _.custom) {
                return { phone: _.phonenumber, manualNumber: true };
            }
            return { mcc: _.mcc, mnc: _.mnc, originalMnc: _.originalMnc };
        }) : [];
        if (this.model.phoneNumbers) {
            this.model.phoneNumbers.split('\n').map(_ => {
                const phone = _.trim();
                if (phone.length > 0) {
                    model.destinations.push({ phone: phone, manualNumber: true });
                }
            });
        }
        model.senders = [];
        if (this.model.sender) {
            model.senders.push(this.model.sender.trim());
        }
        model.messageStates = this.dlrStatuses.filter(_ => _.selected).map(_ => _.id);
        model.testStatuses = this.receiptStatuses.filter(_ => _.selected).map(_ => _.id);

        model.ids = []
        if (this.model.ids) {
            model.ids = this.model.ids.split(' ').map(_ => _.trim()).filter(_ => _);
        }
        model.telqIds = []
        if (this.model.telqIds) {
            model.telqIds = this.model.telqIds.split(',').map(_ => _.trim()).filter(_ => _);
        }
        model.testGroupIds = []
        if (this.model.testGroupIds) {
            model.testGroupIds = this.model.testGroupIds.split(' ').map(_ => _.trim()).filter(_ => _);
        }
        model.taskIds = []
        if (this.model.taskIds) {
            model.taskIds = this.model.taskIds.split(' ').map(_ => _.trim()).filter(_ => _);
        }
        if (this.model.from) {
            model.createdAtFrom = this.model.from;
        }
        if (this.model.to) {
            model.createdAtTo = this.model.to;
        }
        model.userIds = this.onlyMyTests ? [this.user.id] : this.usersCollection.map(u => u.id);
        model.commentText = this.model.commentText;
        model.commentDateFrom = this.model.commentDateFrom;
        model.commentDateTo = this.model.commentDateTo;
        let text = null;
        if (typeof this.model.text === "string") {
            text = this.model.text.trim();
        }
        model.text = text ? text : null;
        if (this.model.tlv) {
            model.tlv = {
                tagHex: this.model.tlv.tagHex ? this.model.tlv.tagHex : null,
                valueHex: this.model.tlv.valueHex ? this.model.tlv.valueHex : null,
            }
            if (!model.tlv.tagHex && !model.tlv.valueHex) {
                delete model.tlv;
            }
        }
        model.showOnlyScheduledTests = this.model.showOnlyScheduledTests;
        return model;
    }

    checkFilterCondition(filterKey: string): void {
        if (filterKey === 'searchByPhoneNumbers' && this.countries?.selectedCount > 0) {
            this.countries?.reset();
            this.notificationService.info('Destination and phone number filter cannot be used together. Destination filter has been cleared.', 'Destination filter updated')
        }
        const populatedModel: SearchParams = this.getPopulatedModel();
        const isNonElasticSearchFilterActive = this.isNonElasticSearchFilterActive();
        if (this.liveNumberTestingService.ELASTIC_FILTERS.includes(filterKey)) {
            if (isNonElasticSearchFilterActive) {
                this.clearNonElasticSearchFilters();
                this.notificationService.info('This filter combination cannot be used', 'Some filters are updated');
            }
        } else {
            const isElasticSearchFilterActive = this.liveNumberTestingService.shouldRunOnElasticSearch(populatedModel);
            if (isElasticSearchFilterActive) {
                this.clearElasticSearchFilters();
                this.notificationService.info('This filter combination cannot be used', 'Some filters are updated')
            }
        }
    }

    isNonElasticSearchFilterActive(): boolean {
        for (let i = 0; i < this.filters.length; i++) {
            const f = this.filters[i];
            if (!this.liveNumberTestingService.ELASTIC_FILTERS.includes(f.filterKey)) {
                const isFActive = this.isFilterActive(f.filterKey);
                if (isFActive) {
                    return true;
                }
            }
        }
        return false;
    }

    clearElasticSearchFilters(): void {
        this.model.commentDateFrom = null;
        this.model.commentDateTo = null;
        this.model.commentText = null;
        this.model.text = null;
        this.childDatePeriods?.map(child => child.reset());
    }

    clearNonElasticSearchFilters(): void {
        this.model.telqIds = '';
        this.model.ids = '';
        this.model.sender = '';
        this.model.from = '';
        this.model.to = '';
        this.model.tlv.tagHex = null;
        this.model.tlv.valueHex = null;
        this.model.connections = [];
        this.model.testGroupIds = '';
        this.model.taskIds = '';
        if (this.datePeriod) {
            this.datePeriod.start = Date.parse(this.model.from) / 1000;
            this.datePeriod.end = Date.parse(this.model.to) / 1000;
            this.datePeriod.updateValue();
        }
        this.model.phoneNumbers = '';
        this.receiptStatuses.map(_ => _.selected = false);
        this.dlrStatuses.map(_ => _.selected = false);
        this.countries?.reset();
        this.connections?.reset();
        this.usersCollection = this.onlyMyTests ? this.usersCollection : [];
        this.users?.onClearAll();
    }

    onDestinationChange(): void {
        if (this.model.phoneNumbers?.length > 0) {
            this.model.phoneNumbers = '';
            this.notificationService.info('Destination and phone number filter cannot be used together. Phone number filter has been cleared.', 'Phone filter updated');
        }
        this.onSubmitClick();
    }

    onRouteChange(): void {
        this.onSubmitClick();
    }

    resetAll(): void {
        this.model.telqIds = '';
        this.model.ids = '';
        this.model.sender = '';
        this.model.from = '';
        this.model.to = '';
        this.model.commentDateFrom = null;
        this.model.commentDateTo = null;
        this.model.commentText = null;
        this.model.text = null;
        this.model.tlv.tagHex = null;
        this.model.tlv.valueHex = null;
        this.model.connections = [];
        this.model.testGroupIds = '';
        this.model.showOnlyScheduledTests = false;
        this.model.taskIds = '';
        this.childDatePeriods?.map(child => child.reset());
        if (this.datePeriod) {
            this.datePeriod.start = Date.parse(this.model.from) / 1000;
            this.datePeriod.end = Date.parse(this.model.to) / 1000;
            this.datePeriod.updateValue();
        }
        this.model.phoneNumbers = '';
        this.receiptStatuses.map(_ => _.selected = false);
        this.dlrStatuses.map(_ => _.selected = false);
        this.countries?.reset();
        this.connections?.reset();
        this.usersCollection = this.onlyMyTests ? this.usersCollection : [];
        this.users?.onClearAll();
        // this.saveUserIds = false;
    }

    onSubmitClick(filterKey: string = null) {
        this.checkFilterCondition(filterKey);
        const searchDetails: SearchParams = this.getPopulatedModel();
        if (this.saveUserIds) {
            searchDetails.usersCollection = this.usersCollection;
            this.localStorage.set(`lnt_user_search_${this.user.id}_search`, searchDetails);
        } else {
            this.localStorage.remove(`lnt_user_search_${this.user.id}_search`);
        }
        this.onSubmit.emit(searchDetails);
        this.handleFilterDropdown(filterKey);
    }

    handleFilterDropdown(filterKey: string = null) {
        const closeDropDownForTheseFilters = ['date', 'searchById', 'searchByPhoneNumbers', 'searchByText', 'searchByComment', 'commentDate', 'searchByTestIdText', 'searchBySenderId', 'tlv'];
        if (filterKey && closeDropDownForTheseFilters.includes(filterKey)) {
            this.filterTopBar.nativeElement.click();
        }
    }

    onClickHide() {
        const searchDetails: SearchParams = this.getPopulatedModel();
        searchDetails.usersCollection = this.usersCollection;
        if (this.saveUserIds) {
            this.localStorage.set(`lnt_user_search_${this.user.id}_search`, searchDetails);
        } else {
            this.localStorage.remove(`lnt_user_search_${this.user.id}_search`);
        }
        this.onHide.emit(searchDetails);
    }

    onSelectUser(user) {
        this.usersCollection.push(user);
        this.onlyMyTests = false;
        this.saveUserSearch();
        this.onSubmitClick();
    }

    onUnSelectUser(user, event: Event = null) {
        if (event) {
            event.stopPropagation();
        }
        this.usersCollection = this.usersCollection.filter(u => user.id !== u.id);
        this.saveUserSearch();
        this.onSubmitClick();
    }

    showOnlyScheduledTestsOnChange(): void {
        this.model.showOnlyScheduledTests = !this.model.showOnlyScheduledTests;
        this.onSubmitClick();
    }

    onChangeOnlyMyTests() {
        this.onlyMyTests = !this.onlyMyTests;
        if (this.onlyMyTests) {
            this.usersCollection = [];
            this.users?.onClearAll();
            this.onSubmitClick();
        } else {
            this.onUnSelectUser(this.user);
        }
        this.saveUserSearch();
    }

    onChangeSaveUserIds() {
        this.saveUserIds = !this.saveUserIds;
        if (this.saveUserIds) {
            const searchDetails = this.getPopulatedModel();
            searchDetails.usersCollection = this.usersCollection;
            this.localStorage.set(`lnt_user_search_${this.user.id}_search`, searchDetails);
        } else {
            this.localStorage.remove(`lnt_user_search_${this.user.id}_search`);
        }
        this.saveUserSearch();
    }

    saveUserSearch() {
        const ids = this.usersCollection.map(u => u.id);
        if (ids.length && this.saveUserIds) {
            this.localStorage.set('lnt_user_search_' + this.user.id, ids);
        } else {
            this.localStorage.remove('lnt_user_search_' + this.user.id);
        }
    }

    static getSaveUserIds(localStorage: LocalStorageService, user) {
        return localStorage.get('lnt_user_search_' + user.id, []);
    }

    onChangeCommentDate(dates): void {
        if (dates instanceof Event || typeof dates === 'string') {
            return;
        }
        if (dates?.startStr && dates?.endStr) {
            this.model.commentDateFrom = dates.startStr + '.000Z';
            this.model.commentDateTo = dates.endStr + '.999Z';
            this.onSubmitClick('commentDate');
        } else {
            this.model.commentDateFrom = null;
            this.model.commentDateTo = null;
        }
    }

    onClearAllStatus(): void {
        this.dlrStatuses.forEach(ds => ds.selected = false);
        this.receiptStatuses.forEach(rs => rs.selected = false);
        this.onSubmitClick();
    }

    statusChange(event: any, status: any): void {
        event.stopPropagation();
        status.selected = !status.selected;
        this.onSubmitClick();
    }

    onAdditionalFilterClick(filter): void {
        filter.show = !filter.show;
        this.localStorage.set(`lnt_${this.user.id}_filter_state`, this.filters);
    }

    onFilterChange(filter): void {
        this.onClickHide();
    }

    onClearAllFilter(): void {
        this.localStorage.set(`lnt_${this.user.id}_filter_state`, this.filters);
        this.resetAll();
        this.onClickHide();
    }

    onClear(filterKey: string) {
        switch (filterKey) {
            case 'searchById':
                this.model.ids = '';
                break;
            case 'searchByPhoneNumbers':
                this.model.phoneNumbers = '';
                break;
            case 'searchByText':
                this.model.text = '';
                break;
            case 'searchByComment':
                this.model.commentText = '';
                break;
            case 'searchByTestIdText':
                this.model.telqIds = '';
                break;
            case 'searchBySenderId':
                this.model.sender = '';
                break;
            case 'date':
                if (this.datePeriod) {
                    this.datePeriod.model = '';
                    this.datePeriod.control.patchValue('');
                    this.datePeriod.reset();
                }
                this.onChangeDate(null);
                this.handleFilterDropdown('date');
                break;
            case 'users':
                this.usersCollection = [];
                break;
            case 'statuses':
                this.dlrStatuses.forEach(ds => ds.selected = false);
                this.receiptStatuses.forEach(rs => rs.selected = false);
                break;
            case 'tlv':
                this.model.tlv.tagHex = '';
                this.model.tlv.valueHex = '';
                break;
            case 'commentDate':
                if (this.childDatePeriods.length > 0) {
                    const commentDateComponent = this.childDatePeriods.filter(child => child.type === 'commentDate')[0];
                    commentDateComponent.reset();
                    this.onChangeCommentDate(null);
                    this.handleFilterDropdown('commentDate');
                }
                break;
            case 'testGroupIds':
                this.model.testGroupIds = '';
                break;
            case 'taskIds':
                this.model.taskIds = '';
                this.model.showOnlyScheduledTests = false;
                break;
        }
        this.onSubmitClick(filterKey);
    }

    isFilterActive(filterKey: string): boolean {
        switch (filterKey) {
            case 'destinations':
                return this.countries?.selectedCount > 0;
            case 'suppliers':
                return this.connections?.getSelectedModels().length > 0;
            case 'searchById':
                return this.model.ids?.length > 0;
            case 'searchByPhoneNumbers':
                return this.model.phoneNumbers?.length > 0;
            case 'searchByText':
                return this.model.text?.length > 0;
            case 'searchByComment':
                return this.model.commentText?.length > 0;
            case 'searchByTestIdText':
                return this.model.telqIds?.length > 0;
            case 'searchBySenderId':
                return this.model.sender?.length > 0;
            case 'date':
                return this.model.from || this.model.to;
            case 'users':
                return this.usersCollection.length > 0;
            case 'statuses':
                return this.dlrStatuses.some(ds => ds.selected) || this.receiptStatuses.some(rs => rs.selected);
            case 'tlv':
                return this.model.tlv.tagHex || this.model.tlv.tagHex;
            case 'commentDate':
                return this.model.commentDateFrom || this.model.commentDateTo;
            case 'testGroupIds':
                return this.model.testGroupIds?.length > 0;
            case 'taskIds':
                return this.model.taskIds?.length > 0 || this.model.showOnlyScheduledTests;
            default:
                return false;
        }
    }

    getPeekForSelectedValues(filter): number | string | null {
        switch (filter.filterKey) {
            case 'destinations':
                const selectedCountriesCount = this.countries?.selectedCount;
                return selectedCountriesCount > 0 ? selectedCountriesCount : null;
            case 'suppliers':
                const selectedSuppliersCount = this.connections?.getSelectedModels().length;
                return selectedSuppliersCount > 0 ? selectedSuppliersCount : null;
            case 'users':
                return this.usersCollection.length;
            case 'statuses':
                const count = this.dlrStatuses.filter(ds => ds.selected).length + this.receiptStatuses.filter(rs => rs.selected).length;
                return count;
            case 'searchByPhoneNumbers':
                const phoneNumbers = this.model.phoneNumbers.split('\n').filter(_ => _.trim().length > 0);
                return phoneNumbers?.length;
            default:
                return null;
        }
    }

    onClickExport() {
        this.exportSize = 'Custom';
        this.exportSpinner = false;
        this.exportData = {};
        this.modal.alert().dialogClass('modal-dialog small-modal').component(this.exportModalTpl).open();
    }

    export(limit) {
        if (limit === 'Custom') limit = this.customExportSize;
        this.exportSpinner = true;
        let params = this.getPopulatedModel();
        const ids = LntSearchFormComponent.getSaveUserIds(this.localStorage, this.user);
        if (!params && ids.length) {
            params = { userIds: ids }
        }

        this.exportService.export('lnt', limit === 'All' ? null : limit, params ? params : {}).then(exportData => {
            this.exportData = exportData;
            this.exportSpinner = false;
        }).catch(error => {
            this.notificationService.error({
                title: 'Live number testing',
                message: 'An error occurred while export tests',
                serviceName: 'NTC',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
            this.exportSpinner = false;
        });
    }

    getIconForStatus(statusID: string): string {
        return CustomUtils.getIconForStatus(statusID);
    }

}