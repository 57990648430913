
import { Clipboard } from '@angular/cdk/clipboard';
import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { CellClickedEvent, ColDef, ColumnMovedEvent, ColumnResizedEvent, ColumnVisibleEvent, GetContextMenuItemsParams, GridReadyEvent, ICellRendererParams, MenuItemDef } from 'ag-grid-community';
import { debounceTime } from 'rxjs';
import { PhoneReportsWidgetComponent } from "../../../phone-reports/phone-reports-widget/phone-reports-widget.component";
import { AGTableBase } from '../../../shared/components/table/ag-table-base';
import { TestDetails, TestResult } from '../../../shared/models/test-result.model';
import { AuthUser, Role } from '../../../shared/models/user.model';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { AllRequestParams } from '../../../shared/services/manual-number-testing.service';
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { UsersService } from '../../../shared/services/users.service';
import { VoiceTestResult, VoiceTestResultCollection } from "../../../shared/models/voice-testing.model";
import { SearchParams, VoiceTestingService } from "../../../shared/services/voice-testing.service";
import { CustomUtils } from "../../../shared/services/custom-utils";
import { VtResultsTableActionsComponent } from "./vt-results-table-actions/vt-results-table-actions.component";
import { VtResultsTableStatusComponent } from './vt-results-table-status/vt-results-table-status.component';
declare var moment: any;


@Component({
    selector: 'app-vt-results-table',
    templateUrl: 'vt-results-table.component.html',
    styleUrls: ['vt-results-table.component.scss'],
})

export class VtResultsTableComponent extends AGTableBase implements OnInit, OnDestroy {

    @ViewChild(PhoneReportsWidgetComponent, { static: false }) phonenumberReportWidget: PhoneReportsWidgetComponent;
    @ViewChild('copyModalTpl', { read: TemplateRef, static: false }) copyModalTpl: any;
    @ViewChild('commentModalTpl', { read: TemplateRef, static: false }) commentModalTpl: any;

    @Output() actions = new EventEmitter<unknown>();
    @Output() onAfterDelete = new EventEmitter<unknown>();
    @Output() onRepeat = new EventEmitter<unknown>();

    isAdmin = false;
    user: AuthUser;
    loading: boolean = false;

    commentModel;
    commentModal: DialogRef;

    currentSection: string = '';
    intervalTimer: any;

    searchParams: SearchParams = {
        testGroupIds: [],
        testIds: [],
        userIds: [],
        networkDetails: [],
        callerIds: [],
        statuses: []
    };
    taskId: any;
    textModalHtml: string;

    testCaseDetails: TestDetails[] = [];

    // ng grid
    columnDefs: ColDef[] = [
        {
            headerName: 'ID', field: 'id',
            hide: true, flex: 0.5,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.id}</div>`;
            }
        },
        {
            headerName: 'Date', field: 'createdAtFormat',
            minWidth: 90, maxWidth: 90,
            flex: 0.6,
            headerTooltip: 'Date and time when the test was triggered, in the browser’s time zone',
            cellRenderer: (params: ICellRendererParams<VoiceTestResult>) => {
                const _ = params.data;
                return this.formatDate(_);
            },
        },
        {
            headerName: 'UTC Date', field: 'utcTimestamp', minWidth: 90, maxWidth: 90,
            flex: 0.6, headerTooltip: 'UTC date for test created date.', hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return CustomUtils.formatDateIntoUTC(_.createdAt)
            },
        },
        {
            headerName: 'Country(MCC)/Network(MNC)', field: 'destination',
            headerTooltip: 'The destination to which the test was sent',
            flex: 1.5,
            cellRenderer: (params: ICellRendererParams<VoiceTestResult>) => {
                return `<div class="two-lines">${params.value ? this.formatCountry(params.value) : ''}</div>`;
            }
        },
        {
            headerName: 'Status', field: 'testStatus',
            headerTooltip: 'Receipt status of the test, indicating whether the test was delivered to our test number',
            cellClass: 'd-flex align-items-center justify-content-center',
            minWidth: 90, maxWidth: 90, flex: 0.6,
            cellRenderer: VtResultsTableStatusComponent
        },
        {
            headerName: 'CLI', field: 'callerId',
            headerTooltip: 'Calling Line Identification',
            minWidth: 130, flex: 1,
            cellRenderer: (params: ICellRendererParams<VoiceTestResult>) => {
                const row = params.data;
                return '<div class="one-line">'  + (row.callerId ? row.callerId : 'N/A') + '</div>';
            }
        },
        {
            headerName: 'Phone', field: 'destination.phone',
            headerTooltip: 'Test number to which the test SMS is to be sent',
            flex: 1, minWidth: 130,
            cellRenderer: (params: ICellRendererParams<VoiceTestResult>) => {
                const row = params.data;
                if (row.destination && row.destination.phone) {
                    return row.destination.phone;
                }
                return 'N/A';
            }
        },
        {
            headerName: 'Delay', field: 'delay',
            headerTooltip: 'The time interval between the creation of a test and the delivery of a test to our server',
            minWidth: 90, maxWidth: 90, flex: 0.6,
            cellRenderer: (params: ICellRendererParams<VoiceTestResult>) => {
                const row = params.data;
                return `<div class="two-lines clickable">${this.formatDelay(row)}</div>`
            }
        },
        {
            headerName: 'User', field: 'user',
            flex: 0.5,
            headerTooltip: 'User that triggered the test',
            cellRenderer: (params: ICellRendererParams<VoiceTestResult>) => {
                const _ = params.data;
                return `<div class="one-line">${_.user ? (_.user.username ? _.user.username : _.user.email) : 'N/A'}<div>`;
            }
        },
        {
            headerName: 'Price', field: 'price', flex: 0.4,
            cellRenderer: (params: ICellRendererParams<VoiceTestResult>) => {
                const _ = params.data;
                return _.price > 0 ? _.price / 1000 : 0;
            }
        },
        {
            headerName: 'Actions', field: 'actions', maxWidth: 100, minWidth: 100,
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
            tooltipField: 'actions',
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: VtResultsTableActionsComponent,
        }
    ];

    rowData!: VoiceTestResult[];
    // ng grid

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        public service: VoiceTestingService,
        public userService: UsersService,
        public localStorage: LocalStorageService,
        private clipboard: Clipboard,
        public modal: ModalService
    ) {
        super();
        userService.getAuthUser().then(user => {
            this.isAdmin = user.role === Role.ADMIN;
            this.user = user;
            this.init();
        }).catch(e => { });
    }

    init() {
        let hideColumns = [];
        if (!this.isAdmin && !this.user.impersonated) {
            hideColumns.push('price');
        }

        if (this.user.role === 'subaccount' && !this.user.showAllResults) {
            hideColumns.push('user');
        }
        this.columnDefs = this.columnDefs.filter(c => hideColumns.indexOf(c.field) === -1);
        this.paginationPageSize = this.localStorage.get(`vt_results_table_component_size_${this.user.id}`) || 20;
        const savedUserSearchDetails = this.localStorage.get(`vt_user_search_${this.user.id}_search`);
        if (savedUserSearchDetails) {
            this.searchParams = savedUserSearchDetails;
        }
        this.intervalTimer = setInterval(() => {
            if (this.loading) {
                return;
            }
            this.update(false);
        }, 1000 * 20);
    }

    ngOnInit() {
        this.actionSubscription = this.service.action$.subscribe(
            action => this.onAction(action)
        );
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            this.localStorage.set(`vt_table_state_${this.user.id}`, this.gridApi.getColumnState());
        });
    }

    formatDate(_: VoiceTestResult) {
        return `<div class="two-lines"><span>${_.createdAtFormat[1]} <br>${_.createdAtFormat[0]}</span></div>`
    }

    formatCountry(c, icon = true) {
        return this.service.formatDestination(c, icon);
    }

    formatDelay(row: VoiceTestResult) {
        let delay = row.delay;
        let toSec = (del) => {
            let hours = Math.floor(del / 3600);
            let minutes = Math.floor((del - (hours * 3600)) / 60);
            let seconds = del - (hours * 3600) - (minutes * 60);

            let parts = [];
            if (hours) {
                parts.push(hours + 'h');
            }
            if (minutes) {
                parts.push(minutes + 'm');
            }
            if (seconds || !parts.length) {
                parts.push(seconds + 's');
            }

            let text = parts.join(' ');
            return del >= 300 ? `<span class="text-accent-a2">${text}</span>` : text;
        };

        if (typeof delay === 'number') {
            return toSec(delay);
        }
        return 'N/A';
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
        this.localStorage.set(`vt_results_table_component_size_${this.user.id}`, size);
        this.update();
    }

    update(loading = true) {
        if (!this.user.enabled) { return; }
        this.loading = loading;
        let params = new AllRequestParams();
        params.size = this.paginationPageSize;
        params.page = this.currentPageNumber - 1;
        const success = (data: VoiceTestResultCollection) => {
            data.content.map(_ => {
                const time = Date.parse(_.createdAt);
                _.createdAtFormat = moment(time).format('DD/MM/YY HH:mm:ss').split(" ");
                return _;
            });
            this.rowData = data.content;
            this.totalRowsCount = data.totalElements;
            this.loading = false;
        };
        const error = (error) => {
            this.loading = false;
            this.rowData = [];
            this.totalRowsCount = 0;
            this.notificationService.error({
                title: 'Voice testing',
                message: 'An error occurred while loading the results',
                serviceName: 'VTC',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
        };

        this.service.results(params, this.searchParams).subscribe(success, error)
    }

    onAction(event) {
        this.actions.emit(event);

        if (event.name === 'copy') {
            this.testCaseDetails = [];
            this.modal.alert().component(this.copyModalTpl).open().onDestroy.subscribe(() => { });
            this.service.getResultDetailsText(event.row).subscribe(text => {
                this.testCaseDetails = this.getFormattedData(text);
                this.currentSection = this.testCaseDetails[0].id;
            }, e => {
                console.error(e);
            });
        }
    }


    onCellClick(event: CellClickedEvent) {

    }

    getFormattedData(details: TestDetails[]): TestDetails[] {
        details.forEach(section => {
            section.data = section.data.map(detail => {
                const [first, ...rest] = detail.split(':');
                return [first, rest.join(':')];
            });
        });
        return details;
    }

    onSectionChange(sectionId: string) {
        this.currentSection = sectionId ? sectionId : this.testCaseDetails[0].id;
    }

    scrollTo(section) {
        document.querySelector('#' + section)
            .scrollIntoView();
    }

    // ng grid
    getContextMenuItems = (
        params: GetContextMenuItemsParams
    ): (MenuItemDef)[] => {
        const currentRowClicked = params.node.data;
        const result: (MenuItemDef)[] = [
            {
                name: 'Details ',
                icon: '<span class="icon-tests-settings fs-4"></span>',
                action: () => {
                    this.service.announceAction({ name: 'copy', row: currentRowClicked, column: 'action' })
                },
            },
            {
                name: 'Copy',
                icon: '<span class="icon-copy fs-4"></span>',
                action: () => {
                    const renderedValue = this.getRenderedCellValue(params);
                    this.clipboard.copy(renderedValue ? renderedValue : currentRowClicked.id);
                },
            }
        ];
        if (this.isAdmin) {
            return result;
        }
        return result.filter(item => item.name !== 'Show modem');
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.update();
        const columnState = this.localStorage.get(`vt_table_state_${this.user.id}`);
        this.gridApi.applyColumnState({ state: columnState });
    }
    // ng grid

    ngOnDestroy() {
        if (this.intervalTimer) {
            clearInterval(this.intervalTimer);
        }
        this.actionSubscription.unsubscribe();
        this.columnChange$.unsubscribe();
    }
}
