import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from "rxjs/operators";
import {
    AppscriptsNetworkInfo,
    AppscriptsNumber,
    AppscriptsNumberDetails,
    AppscriptsNumbersCollection,
    AppscriptsNumbersFilter,
    AppscriptsRegistrationInfo,
    AppscriptsUpdateNumber,
    AppscriptsUpdateUser,
    MtCampaign
} from '../models/appscripts-user.model';
import { MoNumberVerificationInformation } from "../models/mo-number-verification-information.model";
import { RestUtils } from './rest-utils';

@Injectable()
export class AppscriptsUserService {

    utils = new RestUtils();

    private actionSubject: Subject<AppscriptsNumberActionData> = new Subject<AppscriptsNumberActionData>();
    action$ = this.actionSubject.asObservable();

    headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(public http: HttpClient) {
    }

    announceAction(action: AppscriptsNumberActionData) {
        this.actionSubject.next(action);
    }

    getBalance(userId: number) {
        let url = this.utils.buildUrl('ROLE/appscr/data/users/' + userId + '/balance');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get(url, options);
    }

    addBalance(userId: number, balances) {
        let url = this.utils.buildUrl('ROLE/appscr/data/users/' + userId + '/balance');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, balances, options);
    }

    all(params: AppscriptsUserRequestParams): Observable<AppscriptsNumbersCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: (params.sort && params.sort.length) ? params.sort : [],
        };
        if (params.filter) {
            for (let i in params.filter) {
                queryParams[i] = params.filter[i];
            }
        }
        let url = this.utils.buildUrl('ROLE/appscr/data/numbers', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsNumbersCollection>(url, options);
    }

    one(id: number): Observable<AppscriptsNumber> {
        let url = this.utils.buildUrl('ROLE/appscr/data/numbers/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsNumber>(url, options);
    }

    updateNumber(id: number, data: AppscriptsUpdateNumber): Observable<any> {
        let url = this.utils.buildUrl('ROLE/appscr/data/numbers/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put(url, data, options)
    }

    updateUser(userId: number, data: AppscriptsUpdateUser): Observable<any> {
        let url = this.utils.buildUrl('ROLE/appscr/data/users/' + userId);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put(url, data, options)
    }

    updateMoEnabledForUserById(userId: number, enabled: boolean): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/users/${userId}/mo-enabled-for-user`, {
            enabled: enabled ? 'true' : 'false'
        });
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.put(url, null, options);
    }

    updateVoiceEnabledForUserById(userId: number, enabled: boolean): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/users/${userId}/voice-enabled-for-user`, {
            enabled: enabled ? 'true' : 'false'
        });
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.put(url, null, options);
    }

    deleteUser(userId: number): Observable<any> {
        let url = this.utils.buildUrl('ROLE/appscr/data/users/' + userId);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options);
    }

    numberDetails(id: number): Observable<AppscriptsNumberDetails> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/numbers/${id}/details`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsNumberDetails>(url, options).pipe(
            map(details => {
                details.lastPingAt = details.lastPingAt ? new Date(details.lastPingAt) : null;
                details.simStateUpdatedAt = details.simStateUpdatedAt ? new Date(details.simStateUpdatedAt) : null;
                details.serviceStateUpdatedAt = details.serviceStateUpdatedAt ? new Date(details.serviceStateUpdatedAt) : null;
                details.lastMtSmsReceivedAt = details.lastMtSmsReceivedAt ? new Date(details.lastMtSmsReceivedAt) : null;
                details.lastMoSmsReceivedAt = details.lastMoSmsReceivedAt ? new Date(details.lastMoSmsReceivedAt) : null;
                if (details.lastPaymentRequest) {
                    details.lastPaymentRequest.createdAt = new Date(details.lastPaymentRequest.createdAt);
                }
                if (details.lastMtCampaign) {
                   this.prepareMtCampaign(details.lastMtCampaign)
                }
                details.availableIndexHistory = details.availableIndexHistory.map(ai => {
                    ai.created_at = new Date(ai.created_at);
                    return ai;
                });
                details.whiteIndexHistory = details.whiteIndexHistory.map(wi => {
                    wi.created_at = new Date(wi.created_at);
                    return wi;
                });
                details.pingHistory = details.pingHistory.map(_ => new Date(_));

                return details;
            })
        );
    }

    prepareMtCampaign(campaign: MtCampaign) {
        campaign.createdAt = new Date(campaign.createdAt);
        if (campaign.issue) {
            const issue = campaign.issue.split(':')[0];
            switch (issue) {
                case 'LAST_SMS':
                    campaign.result = 'last-sms';
                    break;
                case 'SIM_STATE':
                    campaign.result = 'bad-state';
                    break;
                case 'SERVICE_SATE':
                    campaign.result = 'bad-state';
                    break;
                case 'APP_VERSION':
                    campaign.result = 'app-version';
                    break;
            }
        } else if (!campaign.isChosen || !campaign.isSentSms) {
            campaign.result = 'not-chosen';
        } else {
            campaign.result = 'ok';
        }
    }

    networkInfo(phone: string): Observable<AppscriptsNetworkInfo> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/numbers/${phone}/network-info`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsNetworkInfo>(url, options)
    }

    registrationInfo(phone: string): Observable<AppscriptsRegistrationInfo> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/numbers/${phone}/registration-info`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsRegistrationInfo>(url, options)
    }

    liveStatus(phone: string): Observable<string> {
        let url = this.utils.buildUrl(`ROLE/appscr/data/numbers/${phone}/live-status`);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<{status: string}>(url, options).pipe(
            map(_ => _.status)
        )
    }

    getVerificationCount(phoneNumber: string): Observable<MoNumberVerificationInformation> {
        let url = this.utils.buildUrl('ROLE/motc/verification/info/' + phoneNumber);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<MoNumberVerificationInformation>(url, options).pipe(
            map(v => {
                v.createdAt = v.createdAt ? new Date(v.createdAt) : null;
                v.updatedAt = v.updatedAt ? new Date(v.updatedAt) : null;
                return v;
            })
        );
    }

    resetVerificationCount(phoneNumber: string): Observable<any> {
        let url = this.utils.buildUrl('ROLE/motc/verification/reset/' + phoneNumber);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, {}, options);
    }

    uploadLogsToFirebase(phoneNumber: string): Observable<unknown> {
        let url = this.utils.buildUrl('ROLE/appscr/data/numbers/logs/' + phoneNumber);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get(url, options);
    }
}

export class AppscriptsUserRequestParams {
    size: number = 20;
    page: number = 1;
    filter: AppscriptsNumbersFilter;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

export interface AppscriptsNumberActionData {
    name: string;
    row: AppscriptsNumber;
    column: string;
}


