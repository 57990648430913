
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import { UsersService } from '../../shared/services/users.service';
import { BasicUser } from '../../shared/models/user.model';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-users-select',
    templateUrl: 'users-select.component.html',
    styleUrls: ['users-select.component.scss']
})

export class UsersSelectComponent implements OnInit {

    @Output() selectUser: EventEmitter<BasicUser> = new EventEmitter();
    @Output() unSelectUser: EventEmitter<BasicUser> = new EventEmitter();
    @Output() clear: EventEmitter<any> = new EventEmitter();
    @Input() roles = [];
    @Input() paymentType = '';
    @Input() multiple = true;
    @Input() limit = 0;
    @Input() source: 'live' | 'manual' | 'mo' | 'sessions' = 'live';

    tokenPhrase: string = '';

    sizes = [20, 50, 100];
    size = 10;
    page = 1;
    pageCountButtons = 5;
    rows = [];
    totalCountRows: number;
    selectCount = 0;
    loading = false;
    loadingSearch = false;
    batchSelected = false;

    selectedUserIds: Set<number> = new Set();
    savedUserSearchDetails;
    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(public userService: UsersService, public localStorage: LocalStorageService) { }

    ngOnInit() {
        this.checkSavedSearch();
        if (this.savedUserSearchDetails) {
            this.selectedUserIds = new Set(this.savedUserSearchDetails.userIds);
        }
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.onChangeInputSearch(searchValue);
        });
        this.update();
    }

    checkSavedSearch() {
        if (this.source === 'mo') {
            this.savedUserSearchDetails = this.localStorage.get(`mo_user_search_${this.userService.authUser.id}_search`);
        } else if (this.source === 'manual') {
            this.savedUserSearchDetails = this.localStorage.get(`mnt_user_search_${this.userService.authUser.id}_search`);
        } else if (this.source === 'sessions') {
            this.savedUserSearchDetails = this.localStorage.get(`session_supplier_${this.userService.authUser.id}_search_data`);
        } else {
            this.savedUserSearchDetails = this.localStorage.get(`lnt_user_search_${this.userService.authUser.id}_search`);
        }
    }

    update(search = false) {
        this.loading = !search;
        this.loadingSearch = search;
        this.userService.basicList(this.size, this.page, this.tokenPhrase, this.roles, this.paymentType).subscribe({
            next: res => {
                this.totalCountRows = res.totalElements;
                res.content.forEach(rc => {
                    if (this.selectedUserIds.has(rc.id)) {
                        rc['selected'] = true;
                    }
                });
                this.rows = res.content;
                this.loading = false;
                this.loadingSearch = false;
            },
            error: err => {
                this.loading = false;
                this.loadingSearch = false;
            }
        });
    }

    onChangePage(page) {
        this.page = page;
        this.update();
    }

    onChangeSize(size) {
        this.size = size;
        this.update();
    }

    onClickUser(user) {
        let selected = !user.selected;
        if (this.multiple && this.limit > 0 && selected && this.selectCount >= this.limit) {return;}
        user.selected = selected;
        if (user.selected) {this.selectCount++;} else {this.selectCount--;}
        this.emit(user);
    }

    onClickBatchSelect() {
        this.batchSelected = !this.batchSelected;
        this.rows.filter(_ => _.selected !== this.batchSelected).forEach(user => this.onClickUser(user));
    }

    private emit(user) {
        if (user.selected) {
            this.selectedUserIds.add(user.id);
            this.selectUser.emit(user);
        } else {
            this.selectedUserIds.delete(user.id);
            this.unSelectUser.emit(user);
        }
    }

    onChangeInputSearch(text: string) {
        this.tokenPhrase = text;
        this.update(true);
    }

    resetAll() {
        this.selectedUserIds.clear();
        this.rows.forEach(r => r.selected = false);
        this.searchControl.reset();
    }

    onClearAll() {
        this.resetAll();
        this.clear.emit('users');
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }
}