import { Pipe } from '@angular/core';

@Pipe({ name: 'sort' })

export class SortPipe {
    transform<T>(array: T[], args: string): T[] {
        if (!args[0]) {
            return array;
        }

        let direction = args[0][0];
        let column = args.replace('-', '');

        array.sort((a: any, b: any) => {
            let left = a[column];
            let right = b[column];

            if (typeof left === 'number' && typeof right === 'number') {
                return (direction === '-') ? right - left : left - right;
            } else if (!isNaN(left) && !isNaN(right)) {
                left = parseFloat(left);
                right = parseFloat(right);
                return (direction === '-') ? right - left : left - right;
            } else {
                left = Number(new Date(left));
                right = Number(new Date(right));
                return (direction === '-') ? right - left : left - right;
            }
        });

        return array;
    }
}