
import { Component, ElementRef, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { InputSearchDatePeriod } from "../../../shared/components/input/input.search.datePeriod";
import {
    MO_TEST_DLR_STATUSES,
    MO_TEST_SENT_STATUSES
} from "../../../shared/models/mo-testing.model";
import { AuthUser, Role } from '../../../shared/models/user.model';
import { CustomUtils } from '../../../shared/services/custom-utils';
import { ExportService } from '../../../shared/services/export.service';
import { LocalStorageService } from "../../../shared/services/localStorage.service";
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { UsersService } from '../../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';
import { UsersSelectComponent } from '../../../users/users-select/users-select.component';
import { DestinationsSelectComponent } from '../../destinations-select/destinations-select.component';
import { MntSearchFormComponent } from '../../mnt/mnt-search-form/mnt-search-form.component';
import { MoNumberInputComponent } from "../mo-number-input/mo-number-input.component";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-mo-search-form',
    templateUrl: 'mo-search-form.component.html',
    styleUrls: ['../../mnt/mnt-search-form/mnt-search-form.component.scss', 'mo-search-form.component.scss']
})

export class MoSearchFormComponent {

    @Output() onSubmit = new EventEmitter;

    @ViewChild(DestinationsSelectComponent, { static: false }) countries: DestinationsSelectComponent;
    @ViewChild(UsersSelectComponent, { static: false }) users: UsersSelectComponent;
    @ViewChild(InputSearchDatePeriod, { static: false }) datePeriod: InputSearchDatePeriod;
    @ViewChild(MoNumberInputComponent, { static: false }) numbers: MoNumberInputComponent;

    @ViewChild('filterTopBar', { static: false }) filterTopBar: ElementRef;
    @ViewChild('usersModalTpl', { read: TemplateRef, static: false }) usersModalTpl: any;
    @ViewChild('exportModalTpl', { read: TemplateRef, static: false }) exportModalTpl: any;

    usersCollection = [];
    showUsers = false;
    onlyMyTests = false;
    saveUserIds = true;
    userId: number;
    isBeingDragged: boolean = false;

    exportSize: string | number = 'Custom';
    customExportSize = 1000;
    exportSizes = ['Custom', 'All'];
    exportData = {
        url: undefined,
        size: undefined,
        sizeKb: undefined
    };
    exportSpinner = false;

    form: FormGroup;

    sentStatuses = [];
    dlrStatuses = [];

    filters = [
        { filterKey: 'ids', title: 'ID', show: false },
        { filterKey: 'origin', title: 'Origin', show: false },
        { filterKey: 'users', title: 'Users', show: false },
        { filterKey: 'statuses', title: 'Status', show: true },
        { filterKey: 'searchByPhoneNumbers', title: 'Phone', show: false },
        { filterKey: 'searchByTestIdText', title: 'Test ID Text', show: false },
        { filterKey: 'date', title: 'Date', show: false },
        { filterKey: 'submittedText', title: 'Submitted Text', show: false },
        { filterKey: 'destinationNumber', title: 'Destination', show: false }
    ];


    model = {
        ids: '',
        telqIds: '',
        text: '',
        networks: [],
        user_ids: [],
        from: null,
        to: null,
        phoneNumbers: '',
    };

    loading = false;
    user: AuthUser;
    usersModal: DialogRef;

    constructor(
        public userService: UsersService,
        public validationService: ValidationService,
        public localStorage: LocalStorageService,
        public modal: ModalService,
        public exportService: ExportService,
        public notificationService: NotificationService,
        formBuilder: FormBuilder
    ) {
        MO_TEST_SENT_STATUSES.forEach(_ => this.sentStatuses.push(Object.assign({}, _)));
        MO_TEST_DLR_STATUSES.forEach(_ => this.dlrStatuses.push(Object.assign({}, _)));
        this.form = formBuilder.group({
            telqIds: ['', Vld.compose([Vld.maxLength(255)])],
            ids: ['', Vld.compose([Vld.maxLength(255)])],
            submittedText: ['', Vld.compose([Vld.maxLength(255)])],
            phoneNumbers: ['', Vld.compose([Vld.maxLength(255)])],
        });
    }

    ngOnInit() {
        this.loading = true;
        this.userService.getAuthUser().then(user => {
            this.user = user;
            const isAdmin = user.role === Role.ADMIN;
            const isMain = user.role === Role.MAIN;
            const isDeputy = user.role === Role.DEPUTY;
            this.showUsers = (isAdmin || isMain || isDeputy || user.showAllResults);
            const existingFilters = this.localStorage.get(`mo_${this.user.id}_filter_state`) || [];
            if (existingFilters.length === this.filters.length) {
                this.filters = existingFilters;
            } else {
                existingFilters.forEach(ef => {
                    this.filters.filter(_ => _.filterKey === ef.filterKey).forEach(f => {
                        f.show = ef.show;
                    });
                });
            }
            this.filters = this.filters.filter(f => {
                if (!this.showUsers && f.filterKey === 'users') {
                    return false;
                }
                return true;
            });
            this.update();
        });
    }

    onChangeDate(dates) {
        if (dates instanceof Event || typeof dates === 'string') {
            return;
        }
        if (dates?.startStr && dates?.endStr) {
            this.model.from = dates.startStr + '.000Z';
            this.model.to = dates.endStr + '.999Z';
            this.onSubmitClick();
        } else {
            this.model.from = null;
            this.model.to = null;
        }
    }

    shouldAddFilterBeShown(): boolean {
        const hiddenFilters = this.filters.filter(_ => _.show === false);
        return hiddenFilters.length > 0;
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.filters, event.previousIndex, event.currentIndex);
        this.localStorage.set(`mo_${this.user.id}_filter_state`, this.filters);
    }

    deleteItem(event: CdkDragDrop<string[]>) {
        const indexToHide = event.previousIndex;
        this.filters[indexToHide].show = false;
        this.onClear(this.filters[indexToHide].filterKey);
        this.localStorage.set(`mo_${this.user.id}_filter_state`, this.filters);
    }

    update() {
        this.loading = true;
        const savedUserSearchDetails = this.localStorage.get(`mo_user_search_${this.user.id}_search`);
        if (savedUserSearchDetails) {
            this.saveUserIds = true;
            this.populateSavedDetails(savedUserSearchDetails);
        } else {
            this.model.telqIds = '';
            this.model.ids = '';
            this.model.from = '';
            this.model.to = '';
            this.model.text = '';
            if (this.datePeriod) {
                this.datePeriod.model = '';
                this.datePeriod.control.patchValue('');
            }
            this.sentStatuses.forEach(s => s.selected = false);
            this.dlrStatuses.forEach(s => s.selected = false);
            this.usersCollection = [];
            // this.saveUserIds = false;
        }
    }

    populateSavedDetails(savedModel): void {
        if (savedModel.telqIds && typeof savedModel.telqIds.length !== undefined) {
            this.model.telqIds = savedModel.telqIds.join(',');
        } else {
            this.model.telqIds = '';
        }

        if (savedModel.ids && typeof savedModel.ids.length !== undefined) {
            this.model.ids = savedModel.ids.join(' ');
        } else {
            this.model.ids = '';
        }
        this.model.text = savedModel.text;
        this.usersCollection = savedModel.usersCollection;
        // Check if Show only my tests is enabled... This means usersCollection has only one id of current logged in user ...
        if (savedModel.userIds[0] === this.user.id) {
            this.onlyMyTests = true;
        }

        this.model.phoneNumbers = savedModel.origins.filter(_ => _.manualNumber).map(_ => _.phone).join('\n');

        this.sentStatuses.forEach(s => {
            s.selected = savedModel.sentStatuses.includes(s.id);
        });
        this.dlrStatuses.forEach(s => {
            s.selected = savedModel.dlrStatuses.includes(s.id);
        });

        this.model.from = savedModel.createdAtFrom;
        this.model.to = savedModel.createdAtTo;
        setTimeout(() => {
            if (this.datePeriod) {
                this.datePeriod.start = Date.parse(this.model.from) / 1000;
                this.datePeriod.end = Date.parse(this.model.to) / 1000;
                this.datePeriod.updateValue();
            }
        }, 200);

        this.numbers?.setNumbers(savedModel.destinations);
    }

    getPopulatedModel(): any {
        let model = {
            destinations: [],
            origins: [],
            ids: [],
            telqIds: [],
            userIds: [],
            sentStatuses: this.sentStatuses.filter(s => s.selected).map(s => s.id),
            dlrStatuses: this.dlrStatuses.filter(s => s.selected).map(s => s.id),
            text: null,
            createdAtTo: null,
            createdAtFrom: null
        };
        model.origins = this.countries ? this.countries.getSelectedModels().map(_ => {
            if (typeof _.custom !== 'undefined' && _.custom) {
                return { phone: _.phonenumber, manualNumber: true };
            }
            return { mcc: _.mcc, mnc: _.mnc, originalMnc: _.originalMnc };
        }) : [];
        if (this.model.phoneNumbers) {
            this.model.phoneNumbers.split('\n').map(_ => {
                const phone = _.trim();
                if (phone.length > 0) {
                    model.origins.push({ phone: phone, manualNumber: true });
                }
            });
        }
        model.destinations = this.numbers?.getNumbers();

        if (this.model.ids) {
            model.ids = this.model.ids.split(' ').map(_ => parseInt(_.trim())).filter(_ => _);
        }
        if (this.model.telqIds) {
            model.telqIds = this.model.telqIds.split(',').map(_ => _.trim()).filter(_ => _);
        }
        if (this.model.from) {
            model.createdAtFrom = this.model.from;
        }
        if (this.model.to) {
            model.createdAtTo = this.model.to;
        }
        model.userIds = this.onlyMyTests ? [this.user.id] : this.usersCollection.map(u => u.id);
        const text = this.model.text ? this.model.text.trim() : this.model.text;
        if (text) {
            model.text = text;
        }

        return model;
    }

    onSubmitClick(filterKey: string = null) {
        const searchDetails = this.getPopulatedModel();
        searchDetails.usersCollection = this.usersCollection;
        if (this.saveUserIds) {
            this.localStorage.set(`mo_user_search_${this.user.id}_search`, searchDetails);
        } else {
            this.localStorage.remove(`mo_user_search_${this.user.id}_search`);
        }
        this.onSubmit.emit(searchDetails);
        this.handleFilterDropdown(filterKey);
    }

    handleFilterDropdown(filterKey: string = null) {
        const closeDropDownForTheseFilters = ['ids', 'date', 'searchByTestIdText', 'destinationNumber', 'searchByPhoneNumbers', 'submittedText'];
        if (filterKey && closeDropDownForTheseFilters.includes(filterKey)) {
            this.filterTopBar.nativeElement.click();
        }
    }

    onSelectUser(user) {
        this.usersCollection.push(user);
        this.onlyMyTests = false;
        this.saveUserSearch();
        this.onSubmitClick();
    }

    onUnSelectUser(user, event: Event = null) {
        if (event) {
            event.stopPropagation();
        }
        this.usersCollection = this.usersCollection.filter(u => user.id !== u.id);
        this.saveUserSearch();
        this.onSubmitClick();
    }

    onChangeOnlyMyTests() {
        this.onlyMyTests = !this.onlyMyTests;
        if (this.onlyMyTests) {
            this.usersCollection = [];
            this.users?.onClearAll();
            this.onSubmitClick();
        } else {
            this.onUnSelectUser(this.user);
        }

        this.saveUserSearch();
    }

    onChangeSaveUserIds() {
        this.saveUserIds = !this.saveUserIds;
        if (this.saveUserIds) {
            const searchDetails = this.getPopulatedModel();
            searchDetails.usersCollection = this.usersCollection;
            this.localStorage.set(`mo_user_search_${this.user.id}_search`, searchDetails);
        } else {
            this.localStorage.remove(`mo_user_search_${this.user.id}_search`);
        }
        this.saveUserSearch();
    }

    saveUserSearch() {
        const ids = this.usersCollection.map(u => u.id);
        if (ids.length && this.saveUserIds) {
            this.localStorage.set('mo_user_search_' + this.user.id, ids);
        } else {
            this.localStorage.remove('mo_user_search_' + this.user.id);
        }
    }

    static getSaveUserIds(localStorage: LocalStorageService, user) {
        return localStorage.get('mo_user_search_' + user.id, []);
    }

    onClickExport() {
        this.exportSize = 'Custom';
        this.exportSpinner = false;
        this.exportData = {
            url: undefined,
            size: undefined,
            sizeKb: undefined
        };
        this.modal.alert().dialogClass('modal-dialog small-modal').component(this.exportModalTpl).open();
    }

    export(limit) {
        if (limit === 'Custom') limit = this.customExportSize;
        this.exportSpinner = true;
        let params = this.getPopulatedModel();
        const ids = MntSearchFormComponent.getSaveUserIds(this.localStorage, this.user);
        if (!params && ids.length) {
            params = { userIds: ids }
        }
        this.exportService.export('mo', limit === 'All' ? null : limit, params ? params : {}).then(exportData => {
            this.exportData = exportData;
            this.exportSpinner = false;
        }).catch(error => {
            this.notificationService.error({
                title: 'MO testing',
                message: 'An error occurred while export tests',
                serviceName: 'MO Testing',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
            this.exportSpinner = false;
        });
    }

    onAdditionalFilterClick(filter): void {
        filter.show = !filter.show;
        this.localStorage.set(`mo_${this.user.id}_filter_state`, this.filters);
    }

    onFilterChange(filter): void {
        this.onSubmitClick();
    }

    checkFilterCondition(filter): void {
        if (filter.filterKey === 'searchByPhoneNumbers' && this.countries?.selectedCount > 0) {
            this.countries?.reset();
            this.notificationService.info('Origin and phone number filter cannot be used together. Origin filter has been cleared.', 'Origin filter updated')
        }
    }


    onOriginChange(): void {
        if (this.model.phoneNumbers?.length > 0) {
            this.model.phoneNumbers = '';
            this.notificationService.info('Origin and phone number filter cannot be used together. Phone number filter has been cleared.', 'Phone filter updated');
        }
        this.onSubmitClick();
    }

    onChangeDestination(): void {
        this.numbers.onBlur();
        this.onSubmitClick('destinationNumber');
    }

    onDestinationClear(event: PointerEvent, filterKey): void {
        if (event.pointerType === 'mouse') this.onClear(filterKey);
    }

    statusChange(event: any, status: any): void {
        event.stopPropagation();
        status.selected = !status.selected;
        this.onSubmitClick();
    }

    resetAll(): void {
        this.model.telqIds = '';
        this.model.ids = '';
        this.model.from = '';
        this.model.to = '';
        this.model.text = '';
        this.model.phoneNumbers = '';
        if (this.datePeriod) {
            this.datePeriod.model = '';
            this.datePeriod.control.patchValue('');
        }
        this.sentStatuses.map(_ => _.selected = false);
        this.dlrStatuses.map(_ => _.selected = false);
        this.countries?.reset();
        this.usersCollection = this.onlyMyTests ? this.usersCollection : [];
        this.users?.onClearAll();
        // this.saveUserIds = false;
        this.numbers?.reset();
    }

    onClearAllFilter(): void {
        this.localStorage.set(`mo_${this.user.id}_filter_state`, this.filters);
        this.resetAll();
        this.onSubmitClick();
    }

    onClear(filterKey: string) {
        switch (filterKey) {
            case 'origin':
                this.countries.reset();
                break;
            case 'searchById':
                this.model.ids = '';
                break;
            case 'searchByPhoneNumbers':
                this.model.phoneNumbers = '';
                break;
            case 'searchByTestIdText':
                this.model.telqIds = '';
                break;
            case 'date':
                this.onChangeDate(null);
                if (this.datePeriod) {
                    this.datePeriod.model = '';
                    this.datePeriod.control.patchValue('');
                    this.datePeriod.reset();
                }
                this.handleFilterDropdown('date');
                break;
            case 'submittedText':
                this.model.text = '';
                break;
            case 'ids':
                this.model.ids = '';
                break;
            case 'users':
                this.usersCollection = [];
                break;
            case 'statuses':
                this.sentStatuses.forEach(rs => rs.selected = false);
                this.dlrStatuses.forEach(rs => rs.selected = false);
                break;
            case 'destinationNumber':
                this.numbers.reset();
                break;
        }
        this.onSubmitClick();
    }

    isFilterActive(filter): boolean {
        switch (filter.filterKey) {
            case 'origin':
                return this.countries?.selectedCount > 0;
            case 'searchById':
                return this.model.ids?.length > 0;
            case 'searchByPhoneNumbers':
                return this.model.phoneNumbers?.length > 0;
            case 'searchByTestIdText':
                return this.model.telqIds?.length > 0;
            case 'date':
                return this.model.from || this.model.to;
            case 'users':
                return this.usersCollection.length > 0;
            case 'statuses':
                return this.sentStatuses.some(rs => rs.selected) || this.dlrStatuses.some(rs => rs.selected);
            case 'destinationNumber':
                return this.numbers?.getNumbers()?.length > 0;
            case 'submittedText':
                return this.model.text?.length > 0;
            case 'ids':
                return this.model.ids?.length > 0;
            default:
                return false;
        }
    }

    getPeekForSelectedValues(filter): number | string | null {
        switch (filter.filterKey) {
            case 'origin':
                const selectedCountriesCount = this.countries?.selectedCount;
                return selectedCountriesCount > 0 ? selectedCountriesCount : null;
            case 'users':
                return this.usersCollection.length;
            case 'statuses':
                const count = this.dlrStatuses.filter(ds => ds.selected).length + this.sentStatuses.filter(rs => rs.selected).length;
                return count;
            case 'searchByPhoneNumbers':
                const phoneNumbers = this.model.phoneNumbers.split('\n').filter(_ => _.trim().length > 0);
                return phoneNumbers?.length;
            default:
                return null;
        }
    }

    getIconForStatus(statusID: string): string {
        return CustomUtils.getIconForStatus(statusID);
    }

}
