
import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { VoiceTestGroup } from "../../../shared/models/voice-testing.model";
import { ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { PricingService } from "../../../shared/services/pricing.service";
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';
import { VoiceTestingService } from "../../../shared/services/voice-testing.service";
import { DestinationsSelectComponent } from '../../destinations-select/destinations-select.component';
import { UsersService } from "../../../shared/services/users.service";

@Component({
    selector: 'app-vt-form',
    templateUrl: 'vt-form.component.html',
    styleUrls: ['vt-form.component.scss'],
    inputs: ['model'],
    outputs: ['onAfterSave'],
})

export class VtFormComponent implements AfterViewInit {

    @ViewChild(DestinationsSelectComponent, { static: false }) countries: DestinationsSelectComponent;
    @ViewChild('confirmModalTpl', { read: TemplateRef, static: false }) confirmModalTpl: any;

    @Input() showButtons = true;
    @Input() autoRun = true;
    @Input() pauseUpdate = false;
    @Input() hiddenSearchButton = false;

    @Output() onChangeCountResults = new EventEmitter;
    @Output() onClickSearchButton = new EventEmitter;

    loading = true;
    backButtonShow = true;
    onAfterSave = new EventEmitter();
    countResults = 0;
    isAdmin = false;

    onAfterSaveEmmit = true;

    form: FormGroup;
    isFormValid: boolean = false;

    testsPrice: number = 0;
    ttl: number = 180;

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        formBuilder: FormBuilder,
        public validationService: ValidationService,
        public service: VoiceTestingService,
        public modal: ModalService,
        public pricingService: PricingService,
        private userService: UsersService
    ) {
        this.form = formBuilder.group({
            ttl: ['', Vld.compose([Vld.required, Vld.min(60), Vld.max(600)])],
            count_results: ['', Vld.compose([Vld.min(1)])],
        });
        userService.getAuthUser().then(u => {
            this.isAdmin = u.role === 'admin';
        });
    }

    get ttlInput() {
        return this.form.get('ttl');
    }

    ngAfterViewInit() {
        this.form.valueChanges.subscribe(_ => {
            this.isFormValid = this.form.valid;
        });
        this.countries.update().subscribe(_ => {
            this.loading = false;
        });
    }

    onSubmit() {
        this.loading = true;
        let dialogRef = this.modal.alert().component(this.confirmModalTpl).open();
        this.pricingService.getTestPrices(this.countResults, 'VOICE').subscribe({
            next: (result: number) => {
                this.testsPrice = result;
                this.loading = false;
                dialogRef.result.then(result => {
                    if (result) {
                        dialogRef.result.then(result => {
                            if (result) {
                                this.save();
                            }
                        });
                    }
                });
            },
            complete: () => {
                this.loading = false;
            }
        });
    }

    private save() {
        this.loading = true;
        return this.service.run(this.createModel()).subscribe(_ => {
            this.loading = false;
            this.notificationService.success('Test case created', 'Voice testing');
            if (this.onAfterSaveEmmit) {
                this.onAfterSave.emit();
                this.countries.reset();
            }
            this.calculateCountResults();
        }, error => {
            this.loading = false;
            this.notificationService.error({
                title: 'Voice testing',
                message: 'An error occurred while running the tests',
                serviceName: 'MTC',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
        });
    }

    calculateCountResults() {
        this.countResults = this.countries.getSelectedModels().length;
        this.form.controls.count_results.patchValue(this.countResults);
        this.onChangeCountResults.emit(this.countResults);
    }

    clickSearchButton() {
        this.onClickSearchButton.emit();
    }

    reset() {
        this.countries.reset();
        this.calculateCountResults();
    }

    private createModel(): VoiceTestGroup {
        let testGroup: VoiceTestGroup = {
            testDtos: [],
            ttl: this.ttl
        };
        testGroup.testDtos = this.countries.getSelectedModels().map(_ => {
            if (_.custom) {
                return {
                    finished: false,
                    destinationDto: {
                        countryName: _.countryName ? _.countryName : null,
                        mcc: null,
                        mnc: null,
                        originalMnc: null,
                        originalProviderName: null,
                        providerName: null,
                        phone: _.phonenumber,
                        manualNumber: true
                    }
                };
            }
            return {
                finished: false,
                destinationDto: {
                    countryName: _.countryName,
                    mcc: _.mcc,
                    mnc: _.mnc,
                    originalMnc: _.originalMnc,
                    originalProviderName: _.originalProviderName,
                    providerName: _.providerName,
                    manualNumber: false
                }
            };
        });

        return testGroup;
    }

}
