import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColDef, GridApi, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { catchError, forkJoin, of } from 'rxjs';
import { AgTooltipComponent } from '../../../../shared/components/ag-tooltip/ag-tooltip.component';
import { AnalyticsTariff, Bundle, SpecialPricingGroup, Tier } from '../../../../shared/models/pricing.model';
import { PaymentType } from '../../../../shared/models/user.model';
import { PricingService } from '../../../../shared/services/pricing.service';

@Component({
    selector: 'app-special-pricing-sub-table',
    templateUrl: './special-pricing-sub-table.component.html',
    styleUrls: ['./special-pricing-sub-table.component.scss']
})
export class SpecialPricingSubTableComponent implements ICellRendererAngularComp {

    rowId!: string;
    masterGridApi!: GridApi;
    currentMasterRowData!: SpecialPricingGroup;

    tierRowData!: Tier[];
    bundleRowData!: Bundle[];
    analyticsTariffRowData!: AnalyticsTariff[];

    defaultColDef: ColDef = {
        flex: 1,
        resizable: true, sortable: false,
        minWidth: 20, maxWidth: 400,
        autoHeaderHeight: true, wrapHeaderText: true, suppressMenu: true,
        wrapText: true,
        showDisabledCheckboxes: true,
        cellClass: 'd-flex justify-content-start align-items-center',
        tooltipComponent: AgTooltipComponent,
    };
    tiersColumnDefs: ColDef[] = [
        {
            headerName: 'Product Type', field: 'serviceType',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.serviceType === 'VOICE' ? 'CLI' : _.serviceType}</div>`;
            }
        },
        {
            headerName: 'Rate per product/test (EUR)', field: 'ratePerUnit',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.ratePerUnit}</div>`;
            }
        },
        {
            headerName: 'Quota', field: 'quota',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.quota}</div>`;
            }
        },
        {
            headerName: 'Commitment', field: 'unit',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.commitment ? _.commitment : ''}</div>`;
            },
        },
        {
            headerName: 'Valid from', field: 'visibleFrom',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.formatDate(_.visibleFrom)}</div>`;
            }
        },
        {
            headerName: 'Visible until', field: 'visibleUntil',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.formatDate(_.visibleUntil)}</div>`;
            }
        }
    ];

    bundlesColumnDefs: ColDef[] = [
        {
            headerName: 'Product Type', field: 'serviceType',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.serviceType === 'VOICE' ? 'CLI' : _.serviceType}</div>`;
            }
        },
        {
            headerName: 'Rate per product/test (EUR)', field: 'ratePerUnit',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.ratePerUnit}</div>`;
            }
        },
        {
            headerName: 'Top up value (EUR)', field: 'commitment',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.commitment}</div>`;
            }
        },
        {
            headerName: 'Pricing visible from', field: 'visibleFrom',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.formatDate(_.visibleFrom)}</div>`;
            },
        },
        {
            headerName: 'Visible until', field: 'visibleFrom',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.formatDate(_.visibleFrom)}</div>`;
            }
        },
        {
            headerName: 'Purchased credit/access available until', field: 'validForSeconds',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${PricingService.formatValidForSeconds(_.validForSeconds ? _.validForSeconds : null)}</div>`;
            }
        }
    ];

    analyticsTariffColumnDefs: ColDef[] = [
        {
            headerName: 'Product Type', field: 'productType',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">Analytics</div>`;
            }
        },
        {
            headerName: 'Rate per product (EUR)', field: 'rate',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.rate}</div>`;
            }
        },
        {
            headerName: 'Valid from', field: 'visibleFrom',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.formatDate(_.visibleFrom)}</div>`;
            }
        },
        {
            headerName: 'Visible until', field: 'visibleUntil',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.formatDate(_.visibleUntil)}</div>`;
            }
        }
    ];

    constructor(
        private pricingService: PricingService,
        private datePipe: DatePipe,
    ) {
    }

    agInit(params: ICellRendererParams): void {
        this.rowId = params.node.id;
        this.masterGridApi = params.api;
        this.currentMasterRowData = params.data;

        if (this.currentMasterRowData.paymentType === PaymentType.POSTPAID) {
            this.loadPostpaidData();
        } else {
            this.loadPrepaidData();
        }
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    onTierGridReady(params: GridReadyEvent) {
        const gridInfo = {
            id: this.rowId,
            api: params.api,
        };
        this.masterGridApi.addDetailGridInfo(this.rowId, gridInfo);
    }

    onBundlesGridReady(params: GridReadyEvent) {
        const gridInfo = {
            id: this.rowId,
            api: params.api,
        };
        this.masterGridApi.addDetailGridInfo(this.rowId, gridInfo);
    }

    onAnalyticsTariffGridReady(params: GridReadyEvent) {
        const gridInfo = {
            id: this.rowId,
            api: params.api,
        };
        this.masterGridApi.addDetailGridInfo(this.rowId, gridInfo);
    }

    loadPostpaidData() {
        forkJoin({
            tiers: this.getTiers(),
            analyticsTariff: this.getAnalyticsTariff()
        }).subscribe({
            next: ({ tiers, analyticsTariff }) => {
                this.tierRowData = tiers;
                this.analyticsTariffRowData = analyticsTariff;
            },
            error: (error) => {
                console.error('Error loading postpaid data:', error);
            }
        });
    }

    loadPrepaidData() {
        forkJoin({
            bundles: this.getBundles(),
            analyticsTariff: this.getAnalyticsTariff()
        }).subscribe({
            next: ({ bundles, analyticsTariff }) => {
                this.bundleRowData = bundles;
                this.analyticsTariffRowData = analyticsTariff;
            },
            error: (error) => {
                console.error('Error loading prepaid data:', error);
            }
        });
    }

    getTiers() {
        return this.pricingService.tiers(this.currentMasterRowData.id).pipe(
            catchError(() => of([] as Tier[]))
        );
    }

    getBundles() {
        return this.pricingService.bundles(this.currentMasterRowData.id).pipe(
            catchError(() => of([]))
        );
    }

    getAnalyticsTariff() {
        return this.pricingService.analyticsTariffs(this.currentMasterRowData.id, 'special').pipe(
            catchError(() => of([]))
        );
    }

    formatDate(timestamp) {
        if (timestamp === null) {
            return '';
        }
        return this.datePipe.transform(new Date(timestamp), 'MMM d, y');
    }

    ngOnDestroy(): void {
        console.log('removing detail grid info with id: ', this.rowId);
        this.masterGridApi.removeDetailGridInfo(this.rowId);
    }

}
