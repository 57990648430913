import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, forkJoin, map, Observable, throwError } from 'rxjs';
import { InputSearchDatePeriod } from "../../shared/components/input/input.search.datePeriod";
import { Invoice } from "../../shared/models/invoice.model";
import { BatchTasks } from '../../shared/services/batch-tasks';
import { InvoiceActionData, InvoiceSearchEvent, InvoiceService } from '../../shared/services/invoice.service';
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { UsersService } from '../../shared/services/users.service';
import { Validators as V, ValidationService } from "../../shared/services/validation.service";
import { InvoicesFormComponent } from "../invoices-form/invoices-form.component";
import { InvoicesTableComponent } from '../invoices-table/invoices-table.component';

@Component({
    selector: 'app-invoices-index',
    templateUrl: './invoices-index.component.html',
    styleUrls: ['invoices-index.component.scss'],
})

export class InvoicesIndexComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(InvoicesTableComponent, { static: false }) table: InvoicesTableComponent;
    @ViewChild(InvoicesFormComponent, { static: false }) createForm: InvoicesFormComponent;
    @ViewChild(InputSearchDatePeriod) datePeriod: InputSearchDatePeriod;
    @ViewChild('paypalForm', { static: false }) paypalForm: ElementRef;
    @ViewChild('paypalModalTpl', { read: TemplateRef, static: false }) paypalModalTpl: any;
    @ViewChild('createModalTpl', { read: TemplateRef, static: false }) createModalTpl: any;
    @ViewChild('detailsModalTpl', { read: TemplateRef, static: false }) detailsModalTpl: any;

    createModal: DialogRef;
    isAdmin = false;
    selectedRows: Invoice[] = [];
    batchCompleted = 0;
    progressShow = false;
    isAutoGeneratedFilterChecked: boolean = false;
    invoice: Invoice;
    invoiceSecret: string;
    paypalModal: DialogRef;

    paypalLoading = false;
    paypalConfirmUrl: string;
    paypalCancelUrl: string;
    paypalReturnUrl: string;

    detailsLoading = false;
    detailsInterval;
    detailsModal: DialogRef;

    partialPaymentModel = {amount: 0}
    partialPaymentForm: FormGroup;
    partialPaymentShow = false;

    private muteFilterTrigger = false;

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        public userService: UsersService,
        public invoiceService: InvoiceService,
        public modal: ModalService,
        public validationService: ValidationService,
        public route: ActivatedRoute,
        private titleService: Title,
        private formBuilder: FormBuilder,
        private clipboard: Clipboard
    ) {
        titleService.setTitle('Invoices');
        userService.can('admin').then(_ => this.isAdmin = _).catch(() => { });
        this.partialPaymentForm = formBuilder.group({
            amount: ['', V.required]
        });
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.route.queryParams.subscribe(params => {
            if (params.details) {
                this.invoiceView(params.details);
            }
            if (params.payment) {
                this.invoicePayment(params.payment);
            }
        });
    }

    batchClear() {
        this.progressShow = false;
        this.selectedRows = [];
        this.table.resetBatch();
    }

    invoiceAction(event: InvoiceActionData) {
        if (event.name === 'view') {
            this.invoiceView(event.row.id)
        }

        if (event.name === 'paypalPayment') {
            this.invoicePayment(event.row.id);
        }
    }

    private invoicePayment(id: string) {
        this.invoiceSecret = null;
        this.invoice = null;
        this.paypalLoading = true;
        forkJoin([
            this.invoiceService.one(id),
            this.invoiceService.getSecret(id)
        ]).pipe(
            catchError(e => {
                if (this.paypalModal) {
                    this.paypalModal.close();
                }
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred while loading the invoice',
                    serviceName: 'API GATEWAY',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
                this.paypalLoading = false;
                return throwError(() => e);
            })
        ).subscribe(data => {
            const [invoice, secret] = data;
            this.invoice = invoice;
            this.paypalConfirmUrl = this.invoiceService.getNotifyUrl(secret);
            this.paypalCancelUrl = window.location.href + '?return=cancel';
            this.paypalReturnUrl = this.invoiceService.getReturnUrl(secret);
            this.invoiceSecret = secret;
            this.paypalLoading = false;
        });
        this.paypalModal = this.modal.alert().component(this.paypalModalTpl).open();
    }

    private invoiceView(id: string) {
        this.invoice = null;
        this.detailsLoading = true;
        this.invoiceService.one(id).subscribe({
            next: invoice => this.invoice = invoice,
            complete: () => this.detailsLoading = false,
        });
        this.detailsModal = this.modal.alert().component(this.detailsModalTpl).open();
        this.detailsModal.onDestroy.subscribe(() => {
            clearInterval(this.detailsInterval);
            this.invoice = null;
        });
        this.detailsInterval = setInterval(() => {
            if (this.detailsLoading) {return;}
            this.invoiceService.one(this.invoice.id).subscribe({
                next: invoice => this.invoice = invoice,
            });
        }, 1000 * 10);
    }

    invoiceCreate() {
        this.createModal = this.modal.alert().component(this.createModalTpl).open();
    }

    batchGenerateZohoReport() {
        const tasks = this.selectedRows.map(row => {
            return this.invoiceService.createZohoInvoice(row.id).pipe(map(() => {
                this.notificationService.success(`Generated zoho invoice ${row.userEmail}`, 'Invoices');
                this.completeTask();
            }), catchError((error, caught) => {
                this.notificationService.error({
                    title: 'Invoices',
                    message: `An error occurred while creating Zoho invoice ${row.userEmail}`,
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    batchSendZohoInvoices() {
        const tasks = this.selectedRows.map(row => {
            return this.invoiceService.sendZohoInvoice(row.id).pipe(map(() => {
                this.notificationService.success(`Zoho Invoice send for ${row.userEmail}`, 'Invoices');
                this.completeTask();
            }), catchError((error, caught) => {
                this.notificationService.error({
                    title: 'Invoices',
                    message: `An error occurred while sending Zoho invoice for ${row.userEmail}`,
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    batchCopySelectedRows() {
        const selectedValues = this.selectedRows.map((_: Invoice) => {
            return [_.status, _.createdAt, _.paidAt, _.totalCommitment, _.rateApplied, _.pricingGroupTitle, _.createdByEmail, _.userEmail, _.ownerEmail, _.emailSent].join('\t');
        });
        this.clipboard.copy(selectedValues.join('\n'));
    }

    autoGenerateFilterChange(): void {
        if (this.muteFilterTrigger) {return;}
        this.table.requestParams['autoGenerated'] = this.isAutoGeneratedFilterChecked;
        this.table.requestParams.page = 1;
        this.table.update();
    }

    startTasks(tasks: Observable<any>[]) {
        this.progressShow = true;
        this.batchCompleted = 0;
        this.table.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.table.loading = false;
            this.progressShow = false;
            this.selectedRows = [];
            this.table.update();
        }
    }

    onAfterSave() {
        this.table.update();
        this.createModal.close();
    }

    onClickVoid(): void {
        this.detailsLoading = true;
        this.invoiceService.markAsVoid(this.invoice.id).subscribe({
            next: _ => {
                this.notificationService.success(
                    'Invoice marked as void.',
                    'Invoices'
                );
                if (this.detailsModal) {
                    this.detailsModal.close();
                }
                this.table.update();
            },
            error: error => {
                this.detailsLoading = false;
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred while updating the invoice',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            }
        });
    }

    onClickMarkAsPaid() {
        this.detailsLoading = true;
        this.invoiceService.markAsPaid(this.invoice.id).subscribe({
            next: _ => {
                this.notificationService.success(
                    'Invoice updated',
                    'Invoices'
                );
                if (this.detailsModal) {
                    this.detailsModal.close();
                }
                this.table.update();
            },
            error: error => {
                this.detailsLoading = false;
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred while updating the invoice',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            }
        });
    }

    isPartialPaymentShow() {
        return this.isAdmin && this.invoice && this.invoice.status === 'WAITING' && !this.invoice.void;
    }

    partialPaymentOpen() {
        this.partialPaymentModel.amount = 0;
        this.partialPaymentForm.controls.amount.setValidators(this.createValidatorAmount());
        this.partialPaymentForm.reset();
        this.partialPaymentShow = true;
    }

    onSubmitPartialPayment() {
        this.detailsLoading = true;
        this.invoiceService.partialPayment(this.invoice.id, this.partialPaymentModel.amount).subscribe({
            next: () => {
                this.notificationService.success('Partial payment is complete.', 'Invoices');
                this.partialPaymentShow = false;
                if (this.detailsModal) {
                    this.detailsModal.close();
                }
                this.table.update();
            },
            error: error => {
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred while partial payment',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            },
        })
    }

    private createValidatorAmount() {
        return V.compose([
            V.required,
            V.digitsAndDot(true),
            V.min(0.001, false),
            V.price(3, true),
            V.max(this.invoice.commitment, true)
        ]);
    }

    onSubmitSearchForm(event: InvoiceSearchEvent) {
        if (this.table.loading) {
            return;
        }
        this.table.currentPageNumber = 1;
        this.table.requestParams.searchData = event;
        this.table.update();
    }

    ngOnDestroy() {
        clearInterval(this.detailsInterval);
    }
}
