import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {
    AppscriptsMoSmsCollection,
    AppscriptsSmsCollection,
    AppscriptsSmsFilter, AppscriptsVoceCallCollection, AppscriptsVoiceCallsFilter
} from '../models/appscripts-sms.model';
import { RestUtils } from './rest-utils';
import { map } from "rxjs/operators";
declare var moment: any;

@Injectable()
export class AppscriptsSmsService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    static VOICE_CALL_STATUSES = [
        {
            id: 'VERIFICATION_STATUS_NOT_VERIFIED',
            label: 'Not verified'
        },
        {
            id: 'VERIFICATION_STATUS_PASSED',
            label: 'Passed'
        },
        {
            id: 'VERIFICATION_STATUS_FAILED',
            label: 'Failed'
        }
    ]

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    allMt(params: AppscriptsSmsRequestParams): Observable<AppscriptsSmsCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: params.sort && params.sort.length ? params.sort : [],
        };
        if (params.filter) {
            for (let i in params.filter) {
                queryParams[i] = params.filter[i];
            }
        }
        let url = this.utils.buildUrl('ROLE/appscr/data/mt-sms', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsSmsCollection>(url, options);
    }

    allMo(params: AppscriptsSmsRequestParams): Observable<AppscriptsMoSmsCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: params.sort && params.sort.length ? params.sort : [],
        };
        if (params.filter) {
            for (let i in params.filter) {
                queryParams[i] = params.filter[i];
            }
        }
        let url = this.utils.buildUrl('ROLE/appscr/data/mo-sms', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsMoSmsCollection>(url, options);
    }

    allVoiceCall(params: AppscriptsVoiceCallsRequestParams): Observable<AppscriptsVoceCallCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: params.sort && params.sort.length ? params.sort : [],
        };
        if (params.filter) {
            for (let i in params.filter) {
                queryParams[i] = params.filter[i];
            }
        }
        let url = this.utils.buildUrl('ROLE/appscr/data/voice-calls', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsVoceCallCollection>(url, options).pipe(
            map(r => {
                r.content = r.content.map(v => {
                    v.createdAt = v.createdAt ? moment(v.createdAt).format('DD/MM/YY HH:mm:ss').split(" ") : v.createdAt;
                    v.callCreatedAt = v.callCreatedAt ? moment(v.callCreatedAt).format('DD/MM/YY HH:mm:ss').split(" ") : v.callCreatedAt;
                    v.resultReceivedAt = v.resultReceivedAt ? moment(v.resultReceivedAt).format('DD/MM/YY HH:mm:ss').split(" ") : v.resultReceivedAt;
                    return v;
                })
                return r;
            })
        );
    }
}

export class AppscriptsSmsRequestParams {
    size: number = 20;
    page: number = 1;
    filter: AppscriptsSmsFilter;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

export class AppscriptsVoiceCallsRequestParams {
    size: number = 20;
    page: number = 1;
    filter: AppscriptsVoiceCallsFilter;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

