import {
    CellClickedEvent,
    ColDef,
    ColumnApi,
    ColumnMovedEvent,
    ColumnResizedEvent,
    ColumnVisibleEvent,
    GetContextMenuItemsParams,
    GetMainMenuItemsParams,
    GridApi,
    ICellRendererParams,
    RowClassRules,
    SortChangedEvent
} from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import { AgTooltipComponent } from '../ag-tooltip/ag-tooltip.component';

export abstract class AGTableBase {

    abstract update(): void;

    mutationObserver: MutationObserver;
    actionSubscription: Subscription;
    paginationPageSize: number = 20;
    sizes: number[] = [20, 50, 100];
    domLayout: string = "autoHeight";
    gridApi: GridApi;
    totalRowsCount: number = 0;
    currentPageNumber: number = 1;
    sortState: string[] = [];

    columnChange$ = new Subject<ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent | SortChangedEvent>();
    customIcons: { [key: string]: Function | string; } = {
        menu: '<span class="icon-settings fs-3 text-neutral-n7 cp"></span>',
    }

    defaultColDef: ColDef = {
        flex: 1,
        resizable: true, sortable: false,
        minWidth: 90, maxWidth: 400,
        autoHeaderHeight: true, wrapHeaderText: true, suppressMenu: true,
        wrapText: true,
        showDisabledCheckboxes: true,
        cellClass: 'd-flex justify-content-start align-items-center',
        tooltipComponent: AgTooltipComponent,
    };

    rowClassRules: RowClassRules = {
        'text-neutral-n7': (params: ICellRendererParams) => {
            return params.data.isBackup;
        }
    };

    onPageChange(event) {
        if (event === this.currentPageNumber) {
            return;
        }
        this.currentPageNumber = event;
        this.update();
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
        this.update();
    }

    onSortChange(event: SortChangedEvent) {
        const columnState = this.gridApi.getColumnState();
        this.sortState = columnState.map(c => c.sort ? `${c.colId},${c.sort}` : null);
        this.update();
    }

    onColumnMoved(event: ColumnMovedEvent) {
        this.columnChange$.next(event);
    }

    onColumnResized(event: ColumnResizedEvent) {
        this.columnChange$.next(event);
    }

    onColumnVisible(event: ColumnVisibleEvent) {
        this.columnChange$.next(event);
    }

    getRenderedCellValue(params: GetContextMenuItemsParams): string {
        // Find the rendered cell
        const cellRendererInstances = params.api.getCellRendererInstances({
            rowNodes: [params.node],
            columns: [params.column]
        });
        if (cellRendererInstances && cellRendererInstances.length > 0) {
            const cellRendererInstance = cellRendererInstances[0];
            const gui = (cellRendererInstance as any).getGui ? (cellRendererInstance as any).getGui() : null;
            if (gui) {
                return gui.innerText;
            }
        }
        return params.value;
    }

    getMainMenuItems(params: GetMainMenuItemsParams) {
        return params.defaultItems.slice(1); // Hide Autosize this column ...
    }

    onActionClick(event: CellClickedEvent) {
        const targetElement = event.event.target as Element;
        if (targetElement && targetElement.classList.contains('icon-more')) {
            const event = new MouseEvent('contextmenu', {
                bubbles: true,
                cancelable: false,
                view: window,
                button: 2, // Right click ...
                buttons: 2,
                clientX: targetElement.getBoundingClientRect().left,
                clientY: targetElement.getBoundingClientRect().top
            });
            targetElement.dispatchEvent(event);
        }
    }

    addHorizontalScrollOnTop() {
        const headerSelector = '.ag-header';
        const scrollSelector = '.ag-body-horizontal-scroll';
        const scrollViewportSelector = '.ag-body-horizontal-scroll-viewport';
        const scrollContainerSelector = '.ag-body-horizontal-scroll-container';

        // get scrollbar elements
        const scrollElement = document.querySelector(scrollSelector);
        const scrollViewportElement = document.querySelector(scrollViewportSelector);
        const scrollContainerElement = document.querySelector(scrollContainerSelector);

        // create scrollbar clones
        const cloneElement = scrollElement.cloneNode(true) as Element;
        const cloneViewportElement = cloneElement.querySelector(scrollViewportSelector);
        const cloneContainerElement = cloneElement.querySelector(scrollContainerSelector);

        // insert scrollbar clone
        const headerElement = document.querySelector(headerSelector);
        headerElement.insertAdjacentElement('afterend', cloneElement);

        // add event listeners to keep scroll position synchronized
        scrollViewportElement.addEventListener('scroll', () => cloneViewportElement.scrollTo({ left: scrollViewportElement.scrollLeft }));
        cloneViewportElement.addEventListener('scroll', () => scrollViewportElement.scrollTo({ left: cloneViewportElement.scrollLeft }));

        // create a mutation observer to keep scroll size synchronized
        this.mutationObserver = new MutationObserver(mutationList => {
            for (const mutation of mutationList) {
                switch (mutation.target) {
                    case scrollElement:
                        cloneElement.setAttribute('style', scrollElement.getAttribute('style'));
                        break;
                    case scrollViewportElement:
                        cloneViewportElement.setAttribute('style', scrollViewportElement.getAttribute('style'));
                        break;
                    case scrollContainerElement:
                        cloneContainerElement.setAttribute('style', scrollContainerElement.getAttribute('style'));
                        break;
                }
            }
        });

        // start observing the scroll elements for `style` attribute changes
        this.mutationObserver.observe(scrollElement, { attributeFilter: ['style'], subtree: true });
    }

}
