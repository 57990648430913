import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from '../../../environments/environment';
import { Network, NetworkCountry, NetworkRegion } from '../models/network.model';
import { RestUtils } from './rest-utils';

@Injectable()
export class NetInfoService {

    utils = new RestUtils();

    headers = new HttpHeaders({
        'Content-Type': ['application/json']
    });

    constructor(public http: HttpClient) {
    }

    networks(params?: AllRequestParamsNetwork): Observable<Network[]> {
        let queryParams: any = {};
        queryParams.regionName = params?.regionName;
        queryParams.networkName = params?.networkName;
        queryParams.sort = params?.sort;
        let url = this.utils.buildUrl('ROLE/net-intel/networks', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<Network[]>(url, options);
    }

    network(id: number): Observable<Network> {
        let url = this.utils.buildUrl(`ROLE/net-intel/networks/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<Network>(url, options);
    }

    createNetwork(): Network {
        return {
            id: null,
            name: '',
            mcc: '',
            mnc: '',
            country: null,
            prefixes: [],
        }
    }

    saveNetwork(network: Network) {
        let url = this.utils.buildUrl(network.id ? `ROLE/net-intel/networks/${network.id}` : `ROLE/net-intel/networks`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        if (network.id) {
            return this.http.put<Network>(url, network, options);
        }
        return this.http.post<Network>(url, network, options);
    }

    deleteNetwork(id: number) {
        let url = this.utils.buildUrl(`ROLE/net-intel/networks/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options);
    }

    countries(params?: AllRequestParamsRegion) {
        let queryParams: any = {};
        if (params) {
            if (params.regionName) {
                queryParams.regionName = params.regionName;
            }
            if (params.prefixes) {
                queryParams.prefixes = params.prefixes;
            }
            if (params.sort && params.sort.length) {
                queryParams.sort = params.sort;
            }
        }
        let url = this.utils.buildUrl('ROLE/net-intel/countries', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<NetworkCountry[]>(url, options);
    }

    country(id: number): Observable<NetworkCountry> {
        let url = this.utils.buildUrl(`ROLE/net-intel/countries/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<NetworkCountry>(url, options);
    }

    saveCountry(country: NetworkCountry) {
        let url = this.utils.buildUrl(country.id ? `ROLE/net-intel/countries/${country.id}` : `ROLE/net-intel/countries`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);
        if (country.id) {
            return this.http.put<NetworkCountry>(url, country, options);
        }
        return this.http.post<NetworkCountry>(url, country, options);
    }

    deleteCountry(id: number) {
        let url = this.utils.buildUrl(`ROLE/net-intel/countries/${id}`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete<NetworkCountry>(url, options);
    }

    countryForNumber(number: string): Observable<NetworkCountry> {
        let url = this.utils.buildUrl(`ROLE/net-intel/countries/search/by-phone-number`, { phoneNumber: number });
        let options = this.utils.getHttpHeaderOptions(this.headers);
        return this.http.get<NetworkCountry>(url, options);
    }

    createCountry(): NetworkCountry {
        return {
            id: null,
            name: '',
            isoAlpha2: '',
            prefixes: []
        }
    }

    getAllRegions(): Observable<NetworkRegion[]> {
        return this.http.get<NetworkRegion[]>(environment.countriesApiUrl, {});
    }
}

export class AllRequestParamsNetwork {

    search: string;

    sort: string[] = [];

    regionName = '';

    networkName = '';

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

export class AllRequestParamsRegion {

    search: string;

    sort: string[] = [];

    regionName = '';

    prefixes = '';

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}
