import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Filter } from '../../../shared/filter/filter';
import { AppscriptsVoiceCallsFilter } from '../../../shared/models/appscripts-sms.model';
import { AppscriptsSmsService } from '../../../shared/services/appscripts-sms.service';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { UsersService } from '../../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';
import { InputSearchDatePeriod } from '../../../shared/components/input/input.search.datePeriod';

@Component({
    selector: 'app-appscripts-cli-filter',
    templateUrl: './appscripts-cli-filter.component.html',
    styleUrls: ['./appscripts-cli-filter.component.scss']
})
export class AppscriptsCliFilterComponent {

    @Output() onSubmit = new EventEmitter<AppscriptsVoiceCallsFilter>();
    @ViewChildren(InputSearchDatePeriod) childDatePeriods: QueryList<InputSearchDatePeriod>;

    isBeingDragged: boolean = false;
    filters: Filter[] = [
        // { filterKey: 'ids', title: 'ID', show: true },
        { filterKey: 'phoneNumbers', title: 'Phone numbers', show: true },
        { filterKey: 'callerPhoneNumber', title: 'CLI', show: true },
        { filterKey: 'createdAt', title: 'Date created', show: true },
        { filterKey: 'resultReceivedAt', title: 'Date delivered', show: true },
        // { filterKey: 'callerNumberVerificationStatuses', title: 'Statuses', show: true },
    ];
    form: FormGroup;
    voiceStatuses: { id: string, label: string }[] = AppscriptsSmsService.VOICE_CALL_STATUSES;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UsersService,
        private localStorage: LocalStorageService,
        public validationService: ValidationService,
    ) {

    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            ids: [null, Vld.compose([Vld.digitsAndSpaces(true), Vld.maxLength(255)])],
            phoneNumbers: [null, Vld.compose([Vld.digitsAndSpaces(), Vld.maxLength(255)])],
            callerPhoneNumber: [null, Vld.compose([Vld.digitsAndSpaces(), Vld.maxLength(255)])],
            createdAtTo: [null],
            createdAtFrom: [null],
            resultReceivedAtTo: [null],
            resultReceivedAtFrom: [null],
            callerNumberVerificationStatuses: [null],
        });
        const existingFilters = this.localStorage.get(`appscripts_cli_${this.userService.authUser.id}_filter_state`) || [];
        if (existingFilters.length === this.filters.length) {
            this.filters = existingFilters;
        } else {
            existingFilters.forEach(ef => {
                this.filters.filter(_ => _.filterKey === ef.filterKey).forEach(f => {
                    f.show = ef.show;
                });
            });
        }
    }

    getPeekForSelectedValues(filter): number | string | null {
        switch (filter.filterKey) {
            default:
                return null;
        }
    }

    isFilterActive(filter: Filter): boolean {
        switch (filter.filterKey) {
            case 'ids':
                return this.form.get('ids')?.value !== null;
            case 'phoneNumbers':
                return this.form.get('phoneNumbers')?.value !== null;
            case 'callerPhoneNumber':
                return this.form.get('callerPhoneNumber').value?.length > 0;
            case 'createdAt':
                return this.form.get('createdAtTo').value?.length > 0;
            case 'resultReceivedAt':
                return this.form.get('resultReceivedAtTo').value?.length > 0;
            case 'callerNumberVerificationStatuses':
                return this.form.get('callerNumberVerificationStatuses').value?.length > 0;
            default:
                return false;
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.filters, event.previousIndex, event.currentIndex);
        this.localStorage.set(`appscripts_cli_${this.userService.authUser.id}_filter_state`, this.filters);
    }

    onClear(filterKey: string) {
        switch (filterKey) {
            case 'ids':
                this.form.get('ids').reset();
                break;
            case 'phoneNumbers':
                this.form.get('phoneNumbers').reset();
                break;
            case 'callerPhoneNumber':
                this.form.get('callerPhoneNumber').reset();
                break;
            case 'createdAt':
                this.form.get('createdAtTo').reset();
                this.form.get('createdAtFrom').reset();
                const createdAtDatePeriod = this.childDatePeriods.find(_ => _.type === 'createdAtDate');
                createdAtDatePeriod?.reset();
                break;
            case 'resultReceivedAt':
                this.form.get('resultReceivedAtTo').reset();
                this.form.get('resultReceivedAtFrom').reset();
                const resultReceivedAtDatePeriod = this.childDatePeriods.find(_ => _.type === 'resultReceivedAtDate');
                resultReceivedAtDatePeriod?.reset();
                break;
            case 'callerNumberVerificationStatuses':
                this.form.get('callerNumberVerificationStatuses').reset();
                break;
            default:
                break;
        }
        this.onSubmitClick();
    }

    onSubmitClick(filterKey: string = null) {
        let searchData = this.form.getRawValue();
        const ids = searchData.ids?.split(' ').filter(Boolean).map(Number);
        const phoneNumbers = searchData.phoneNumbers?.split(' ').filter(Boolean).map(Number);
        searchData = { ...searchData, ids: ids, phoneNumbers };
        this.onSubmit.emit(searchData);
    }

    resetAll(): void {
        this.form.reset();
        this.childDatePeriods.forEach(_ => _?.reset());
    }

    deleteItem(event: CdkDragDrop<string[]>) {
        const indexToHide = event.previousIndex;
        this.filters[indexToHide].show = false;
        this.onClear(this.filters[indexToHide].filterKey);
        this.localStorage.set(`appscripts_cli_${this.userService.authUser.id}_filter_state`, this.filters);
    }

    shouldAddFilterBeShown(): boolean {
        const hiddenFilters = this.filters.filter(_ => _.show === false);
        return hiddenFilters.length > 0;
    }

    onAdditionalFilterClick(filter): void {
        filter.show = !filter.show;
        this.localStorage.set(`appscripts_cli_${this.userService.authUser.id}_filter_state`, this.filters);
    }

    onClearAllFilter(): void {
        this.resetAll();
        this.onSubmitClick();
        this.localStorage.set(`appscripts_cli_${this.userService.authUser.id}_filter_state`, this.filters);
    }

    onChangeStatus(status: { id: string, label: string }): void {
        const statuses = this.form.get('callerNumberVerificationStatuses').value || [];
        if (statuses.includes(status.id)) {
            statuses.splice(statuses.indexOf(status.id), 1);
        } else {
            statuses.push(status.id);
        }
        this.form.get('callerNumberVerificationStatuses').setValue(statuses);
        this.onSubmitClick();
    }

    onChangeCreatedAt(dates): void {
        this.form.get('createdAtTo').setValue(dates.endIso);
        this.form.get('createdAtFrom').setValue(dates.startIso);
        this.onSubmitClick();
    }

    onChangeResultReceivedAt(dates): void {
        this.form.get('resultReceivedAtTo').setValue(dates.endIso);
        this.form.get('resultReceivedAtFrom').setValue(dates.startIso);
        this.onSubmitClick();
    }

    isStatusSelected(status: { id: string, label: string }): boolean {
        return this.form.get('callerNumberVerificationStatuses').value?.includes(status.id);
    }

}
