import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { RestUtils } from './rest-utils';
import { AppscriptsSetting, AppscriptsSettingUpdate } from "../models/appscripts-setting.model";
declare var moment: any;

@Injectable()
export class AppscriptsSettingService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    all(): Observable<AppscriptsSetting[]> {
        let url = this.utils.buildUrl('ROLE/appscr/data/settings');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<AppscriptsSetting[]>(url, options);
    }

    save(settings: AppscriptsSettingUpdate[]): Observable<void> {
        let url = this.utils.buildUrl('ROLE/appscr/data/settings');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put<void>(url, settings, options);
    }
}

