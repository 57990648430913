import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SchedulerReportsTableComponent } from '../scheduler-reports-table/scheduler-reports-table.component';
import { SchedulerReportSearchEvent } from '../../shared/services/scheduler.service';

@Component({
    selector: 'app-scheduler-reports-index',
    templateUrl: './scheduler-reports-index.component.html',
    styleUrls: [],
})
export class SchedulerReportsIndexComponent {

    serviceDisabled = null;
    @ViewChild(SchedulerReportsTableComponent, { static: false }) schedulerReportsTableComponent: SchedulerReportsTableComponent;

    constructor(titleService: Title) {
        titleService.setTitle('Reports');
    }

    onSubmitSearchForm(event: SchedulerReportSearchEvent): void {
        if (this.schedulerReportsTableComponent.loading) {
            return;
        }
        this.schedulerReportsTableComponent.currentPageNumber = 1;
        this.schedulerReportsTableComponent.requestParams.searchData = event;
        this.schedulerReportsTableComponent.update();
    }
}
