import { Temporal } from "temporal-polyfill";

export type AppscriptsSettingType = 'STRING'|'INT'|'LONG'|'DOUBLE'|'BOOLEAN'|'DATETIME'|'DATE'|'DURATION'|'CRON'

export interface AppscriptsSettingUpdate {
    id: string;
    value: string;
}

export interface AppscriptsSetting extends  AppscriptsSettingUpdate {
    valueType: AppscriptsSettingType;
}

export interface AppscriptsSettingParsed extends AppscriptsSetting {
    valueParsed: any;
    newValue: string;
    hasChanges: boolean;
}

export function parseValue(value: string, valueType: AppscriptsSettingType): any {
    switch (valueType) {
        case "BOOLEAN":
            return value === 'true';
        case "INT":
            return parseInt(value);
        case "LONG":
            return parseInt(value);
        case "DOUBLE":
            return parseFloat(value);
        case "DURATION":
            return Temporal.Duration.from(value);
        case "DATE":
            return new Date(value);
        case "DATETIME":
            return new Date(value);
        default:
            return value;
    }
}

export function parsedValueToString(value: any, valueType: AppscriptsSettingType): string {
    switch (valueType) {
        case "BOOLEAN":
            return value ? 'true' : 'false';
        case "DURATION":
            const val = value as Temporal.Duration
            return val.toString();
        default:
            return String(value);
    }
}
