import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { InputSearchDatePeriod } from '../../shared/components/input/input.search.datePeriod';
import { Filter } from '../../shared/filter/filter';
import { BasicUser } from '../../shared/models/user.model';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { SchedulerSearchEvent, SchedulerService, SchedulerStatus } from '../../shared/services/scheduler.service';
import { UsersService } from '../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { DestinationsSelectComponent } from '../../test/destinations-select/destinations-select.component';
import { RoutesSelectComponent } from '../../test/routes-select/routes-select.component';
import { UsersSelectComponent } from '../../users/users-select/users-select.component';

@Component({
    selector: 'app-scheduler-search',
    templateUrl: './scheduler-search.component.html',
    styleUrls: ['./scheduler-search.component.scss']
})
export class SchedulerSearchComponent implements OnInit {

    @Output() onSubmit = new EventEmitter<SchedulerSearchEvent>();
    @ViewChild(UsersSelectComponent, { static: false }) userComponent: UsersSelectComponent;
    @ViewChild(DestinationsSelectComponent, { static: false }) destinationComponent: DestinationsSelectComponent;
    @ViewChild(RoutesSelectComponent, { static: false }) supplierComponent: RoutesSelectComponent;
    @ViewChildren(InputSearchDatePeriod) childDatePeriods: QueryList<InputSearchDatePeriod>;

    isBeingDragged: boolean = false;
    filters: Filter[] = [
        { filterKey: 'ids', title: 'ID', show: false },
        { filterKey: 'statuses', title: 'Status', show: true },
        { filterKey: 'names', title: 'Name', show: true },
        { filterKey: 'startDate', title: 'Start date', show: true },
        { filterKey: 'finishDate', title: 'Finish date', show: true },
        { filterKey: 'schedulerUserIds', title: 'User', show: false },
        // { filterKey: 'countryNetworks', title: 'Country/Network', show: true },
        // { filterKey: 'suppliers', title: 'Supplier', show: true },
    ];
    form: FormGroup;
    schedulerStatuses: SchedulerStatus[] = [];

    constructor(
        private localStorage: LocalStorageService,
        private usersService: UsersService,
        private formBuilder: FormBuilder,
        public validationService: ValidationService,
    ) {
        this.schedulerStatuses = SchedulerService.SCHEDULER_STATUSES.map(_ => {
            return { value: _, label: this.getLabelForSchedulerStatus(_), icon: this.getIconForStatus(_), isChecked: false };
        });
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            ids: [null, Vld.compose([Vld.digitsAndSpaces(true), Vld.maxLength(255)])],
            statuses: [null],
            names: [null],
            startDateFrom: [null],
            startDateTo: [null],
            finishDateFrom: [null],
            finishDateTo: [null],
            schedulerUserIds: [null],
            countryNetworks: [null],
            suppliers: [null],
        });

        const existingFilters = this.localStorage.get(`scheduler_${this.usersService.authUser.id}_filter_state`) || [];
        if (existingFilters.length === this.filters.length) {
            this.filters = existingFilters;
        } else {
            existingFilters.forEach(ef => {
                this.filters.filter(_ => _.filterKey === ef.filterKey).forEach(f => {
                    f.show = ef.show;
                });
            });
        }
        this.filters = this.filters.filter(_ => {
            if (this.usersService.authUser?.role === 'subaccount' && !this.usersService.authUser?.showAllResults) {
                return _.filterKey !== 'schedulerUserIds'; // hide user filter for subaccounts
            }
            return _;
        });
    }

    getLabelForSchedulerStatus(status: string): string {
        if (status === 'FINISHED') return 'Completed';
        return status[0] + status.slice(1).toLowerCase();
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.filters, event.previousIndex, event.currentIndex);
        this.localStorage.set(`scheduler_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    getPeekForSelectedValues(filter): number | string | null {
        switch (filter.filterKey) {
            case 'schedulerUserIds':
                return this.form.get('schedulerUserIds').value?.length;
            case 'countryNetworks':
                return this.destinationComponent?.getSelectedModels().length;
            case 'suppliers':
                return this.supplierComponent?.getSelectedModels().length;
            default:
                return null;
        }
    }

    isFilterActive(filter: Filter): boolean {
        switch (filter.filterKey) {
            case 'ids':
                return this.form.get('ids').value?.length > 0;
            case 'statuses':
                return this.form.get('statuses').value?.length > 0;
            case 'names':
                return this.form.get('names').value?.length > 0;
            case 'startDate':
                return this.form.get('startDateFrom').value;
            case 'finishDate':
                return this.form.get('finishDateFrom').value;
            case 'suppliers':
                return this.supplierComponent?.getSelectedModels().length > 0;
            case 'countryNetworks':
                return this.destinationComponent?.getSelectedModels().length > 0;
            case 'schedulerUserIds':
                return this.form.get('schedulerUserIds').value?.length > 0;
            default:
                return false;
        }
    }

    onClear(filterKey: string) {
        switch (filterKey) {
            case 'ids':
                this.form.get('ids').setValue(null);
                break;
            case 'statuses':
                this.form.get('statuses').setValue(null);
                break;
            case 'names':
                this.form.get('names').setValue(null);
                break;
            case 'startDate':
                this.form.get('startDateFrom').setValue(null);
                this.form.get('startDateTo').setValue(null);
                const startDatePeriod = this.childDatePeriods.find(_ => _.type === 'startDate');
                startDatePeriod.reset();
                break;
            case 'finishDate':
                this.form.get('finishDateFrom').setValue(null);
                this.form.get('finishDateTo').setValue(null);
                const finishDatePeriod = this.childDatePeriods.find(_ => _.type === 'finishDate');
                finishDatePeriod.reset();
                break;
            case 'suppliers':
                this.supplierComponent?.reset();
                this.form.get('suppliers').setValue(null);
                break;
            case 'countryNetworks':
                this.destinationComponent?.reset();
                this.form.get('countryNetworks').setValue(null);
                break;
            case 'schedulerUserIds':
                this.form.get('schedulerUserIds').setValue(null);
                this.userComponent?.resetAll();
                break;
            default:
                break;
        }
        this.onSubmitClick();
    }

    onSubmitClick(filterKey: string = null) {
        let searchData = this.form.getRawValue();
        searchData.ids = searchData.ids?.split(' ').filter(_ => _);
        searchData.statuses = searchData.statuses?.map(_ => _.value);
        searchData.names = searchData.names?.split(',').filter(_ => _);
        searchData.schedulerUserIds = searchData.schedulerUserIds?.map(_ => _.id);
        this.onSubmit.emit(searchData);
    }

    resetAll(): void {
        this.form.reset();
        this.schedulerStatuses.map(_ => _.isChecked = false);
        this.destinationComponent?.reset();
        this.supplierComponent?.reset();
        this.userComponent?.resetAll();
        this.childDatePeriods.forEach(_ => _.reset());
    }

    shouldAddFilterBeShown(): boolean {
        const hiddenFilters = this.filters.filter(_ => _.show === false);
        return hiddenFilters.length > 0;
    }

    deleteItem(event: CdkDragDrop<string[]>) {
        const indexToHide = event.previousIndex;
        this.filters[indexToHide].show = false;
        this.onClear(this.filters[indexToHide].filterKey);
        this.localStorage.set(`scheduler_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    onAdditionalFilterClick(filter): void {
        filter.show = !filter.show;
        this.localStorage.set(`scheduler_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    onClearAllFilter(): void {
        this.localStorage.set(`scheduler_${this.usersService.authUser.id}_filter_state`, this.filters);
        this.resetAll();
        this.onSubmitClick();
    }

    onChangeStartDate(dates): void {
        const { startIso, endIso } = dates;
        this.form.get('startDateFrom').setValue(startIso);
        this.form.get('startDateTo').setValue(endIso);
        this.onSubmitClick();
    }

    onChangeFinishDate(dates): void {
        const { startIso, endIso } = dates;
        this.form.get('finishDateFrom').setValue(startIso);
        this.form.get('finishDateTo').setValue(endIso);
        this.onSubmitClick();
    }

    onRouteChange(): void {
        const supplierControl: FormControl = this.form.get('suppliers') as FormControl;
        supplierControl.setValue(this.supplierComponent.getSelectedModels());
        this.onSubmitClick();
    }

    onDestinationChange(): void {
        const destinationControl: FormControl = this.form.get('countryNetworks') as FormControl;
        destinationControl.setValue(this.destinationComponent.getSelectedModels());
        this.onSubmitClick();
    }

    onChangeStatus(status: SchedulerStatus): void {
        status.isChecked = !status.isChecked;
        this.form.get('statuses').setValue(this.schedulerStatuses.filter(_ => _.isChecked));
        this.onSubmitClick();
    }

    getIconForStatus(status: string): string {
        switch (status) {
            case 'RUNNING':
                return 'icon-wait text-primary-p4';
            case 'SCHEDULED':
                return 'fas fa-history text-accent-a6';
            case 'STOPPED':
                return 'fas fa-stop text-error-r2';
            case 'FINISHED':
                return 'icon-check';
            default:
                return '';
        }
    }

    onSelectUser(user: BasicUser) {
        const userControl: FormControl = this.form.get('schedulerUserIds') as FormControl;
        const selectedUsers = userControl.value || [];
        if (!selectedUsers.includes(user)) {
            userControl.setValue([...selectedUsers, user]);
        }
        this.onSubmitClick();
    }

    onUnSelectUser(user: BasicUser) {
        const userControl: FormControl = this.form.get('schedulerUserIds') as FormControl;
        const selectedUsers = userControl.value || [];
        userControl.setValue(selectedUsers.filter(_ => _.id !== user.id));
        this.onSubmitClick();
    }

}
