
import {
    Component,
    Output,
    EventEmitter,
    ViewChild,
    Input, TemplateRef, OnInit
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { NotificationService } from '../../../shared/services/notification.service';
import {ValidationService, Validators as V,} from '../../../shared/services/validation.service';
import { AnalyticsTariff, Bundle, PricingGroup, Tier } from "../../../shared/models/pricing.model";
import {PricingService} from "../../../shared/services/pricing.service";
import {SchedulerService} from "../../../shared/services/scheduler.service";
import { CustomUtils } from '../../../shared/services/custom-utils';
import { UsersService } from '../../../shared/services/users.service';
import {DialogRef, ModalService} from "../../../shared/services/modal.service";
declare var moment: any;

@Component({
    selector: 'app-pricing-group-form',
    templateUrl: './pricing-group-form.component.html',
    styleUrls: ['./pricing-group-form.component.scss', '../../pricing.scss']
})

export class PricingGroupFormComponent implements OnInit {

    @Input() backButtonShow = true;
    @Input() model: PricingGroup;

    @Output() onAfterSave = new EventEmitter();

    @ViewChild('tierModalTpl', {read: TemplateRef, static: false}) tierModalTpl: any;
    tierModal: DialogRef;

    @ViewChild('bundleModalTpl', {read: TemplateRef, static: false}) bundleModalTpl: any;
    bundleModal: DialogRef;

    @ViewChild('analyticsModalTpl', {read: TemplateRef, static: false}) analyticsModalTpl: any;
    analyticsModal: DialogRef;

    services = PricingService.services;
    modelTier: Tier & {index?: number};
    modelBundle: Bundle & {index?: number};
    modelAnalyticsTariff: AnalyticsTariff;

    validForSecondsUnit = 'unlimited';
    validForSecondsValue = null;

    loading = false;

    form: FormGroup;
    formBundle: FormGroup;
    formTier: FormGroup;
    formAnalyticsTariff: FormGroup;

    constructor(
        public notificationService: NotificationService,
        public formBuilder: FormBuilder,
        public service: PricingService,
        public userService: UsersService,
        public validationService: ValidationService,
        public modal: ModalService
    ) {
        this.model = service.createGroup();
        this.form = formBuilder.group({
            title: ['', V.compose([V.required, V.minLength(1), V.maxLength(255)])],
            paymentType: ['', V.compose([V.required])],
            visibleAll: [''],
            enabled: ['']
        });
        const visibleRangeValidator = () => {
            const model = this.model.paymentType === 'PREPAID' ? this.modelBundle : this.modelTier;
            if (!model || !model.visibleFrom || !model.visibleUntil) {
                return null;
            }

            const visibleFrom = new Date(model.visibleFrom);
            const visibleUntil = new Date(model.visibleUntil);

            if (visibleFrom > visibleUntil) {
                return {visibleRange: false};
            }

            return null;

        };

        validationService.addFormatter('visibleRange', validationService.createTextFormatter('"Pricing visible from" must be less then "Visible until"'));

        const visibleFromMin = () => {
            const model = this.model.paymentType === 'PREPAID' ? this.modelBundle : this.modelTier;
            if (!model || !model.visibleFrom || model.id) {
                return null;
            }
            const visibleFrom = new Date(model.visibleFrom);
            const now = new Date();
            now.setHours(0,0,0,0);

            if (visibleFrom < now) {
                return {visibleFromMin: false};
            }

            return null;
        }
        validationService.addFormatter('visibleFromMin', validationService.createTextFormatter('"Pricing visible from" must be current date or date in the future.'))

        this.formBundle = formBuilder.group({
            serviceType: ['', V.compose([V.required])],
            ratePerUnit: ['', V.compose([V.required, V.digitsAndDot(true), V.min(0.001, false), V.price(3, true)])],
            commitment: ['', V.compose([V.required, V.digitsAndDot(true), V.min(0.001, false), V.price(3, true)])],
            visibleFrom: ['', V.compose([visibleRangeValidator, visibleFromMin])],
            visibleUntil: ['', V.compose([visibleRangeValidator])],
            validForSeconds: ['']
        });

        this.formTier = formBuilder.group({
            serviceType: ['', V.compose([V.required])],
            ratePerUnit: ['', V.compose([V.required, V.digitsAndDot(true), V.min(0.001, false), V.price(3, true)])],
            commitment: ['', V.compose([V.required, V.digitsAndDot(true), V.min(0.001, false), V.price(3, true)])],
            visibleFrom: ['', V.compose([visibleRangeValidator, visibleFromMin])],
            visibleUntil: ['', V.compose([visibleRangeValidator])],
        });

        const visibleRangeAnalyticsValidator = this.createVisibleRangeAnalyticsValidator();
        const visibleFromMinAnalyticsValidator = this.createVisibleFromMinAnalyticsValidator();
        this.formAnalyticsTariff = formBuilder.group({
            rate: ['', V.compose([V.required, V.digitsAndDot(true), V.min(0.001, false), V.price(3, true)])],
            visibleFrom: ['', V.compose([visibleRangeAnalyticsValidator, visibleFromMinAnalyticsValidator])],
            visibleUntil: ['', V.compose([visibleRangeAnalyticsValidator])],
        });

        this.validationService = validationService;
    }

    private createVisibleRangeAnalyticsValidator() {
        return () => {
            const model = this.modelAnalyticsTariff;
            if (!model || !model.visibleFrom || !model.visibleUntil) {
                return null;
            }
            const visibleFrom = new Date(model.visibleFrom);
            const visibleUntil = new Date(model.visibleUntil);
            if (visibleFrom > visibleUntil) {
                return {visibleRange: false};
            }
            return null;
        };
    }

    private createVisibleFromMinAnalyticsValidator() {
        return () => {
            const model = this.modelAnalyticsTariff;
            if (!model || !model.visibleFrom) {
                return null;
            }
            const visibleFrom = new Date(model.visibleFrom);
            const now = new Date();
            now.setHours(0,0,0,0);
            if (visibleFrom < now) {
                return {visibleFromMin: false};
            }
            return null;
        };
    }

    ngOnInit() {
    }

    addBundle() {
        this.formBundle.reset();
        this.modelBundle = this.service.createBundle();
        this.modelBundle.serviceType = this.services[0]; 
        const time = SchedulerService.secondsToHuman(this.modelBundle.validForSeconds);
        this.validForSecondsUnit = time.unit;
        this.validForSecondsValue = time.value;
        this.bundleModal = this.modal.alert().isBlocking(true).component(this.bundleModalTpl).open();
    }

    editBundle(bundle) {
        let model = {
            index: this.model.bundles.indexOf(bundle)
        };
        for (let i in bundle) {model[i] = bundle[i];}
        this.modelBundle = model;
        this.validForSecondsUnit = 'unlimited';
        this.validForSecondsValue = null;
        if (bundle.validForSeconds) {
            const time = SchedulerService.secondsToHuman(bundle.validForSeconds);
            this.validForSecondsUnit = time.unit;
            this.validForSecondsValue = time.value;
        }
        this.bundleModal = this.modal.alert().isBlocking(true).component(this.bundleModalTpl).open();
    }

    deleteBundle(bundle) {
        if (!this.model.id || !bundle.id) {
            this.model.bundles = this.model.bundles.filter(_ => _ !== bundle);
        } else {
            this.model.bundles = this.model.bundles.filter(_ => _.id !== bundle.id);
        }
    }

    onChangeVisibleFromBundle(time) {
        if (time === null) {
            this.modelBundle.visibleFrom = null;
            this.formBundle.controls.visibleFrom.patchValue('');
        }
        if (!parseInt(time)) {
            return;
        }
        let value = moment.unix(time).format("YYYY-MM-DD");
        this.modelBundle.visibleFrom = value;
        this.formBundle.controls.visibleFrom.patchValue(value);
    }

    onChangeVisibleUntilBundle(time) {
        if (time === null) {
            this.modelBundle.visibleUntil = null;
            this.formBundle.controls.visibleUntil.patchValue('');
        }
        if (!parseInt(time)) {
            return;
        }
        let value = moment.unix(time).format("YYYY-MM-DD");
        this.modelBundle.visibleUntil = value;
        this.formBundle.controls.visibleUntil.patchValue(value);
    }

    onChangeValidForSeconds(value) {
        if (!value.unit) {
            return;
        }
        this.modelBundle.validForSeconds = SchedulerService.repeatEveryToSeconds(value.value, value.unit);
        this.formBundle.controls.validForSeconds.patchValue(this.modelBundle.validForSeconds);
    }

    formatValidForSeconds(sec) {
        if (!sec) {
            return 'Unlimited'
        }
        return PricingService.formatValidForSeconds(sec);
    }

    onSubmitBundle() {
        let model = Object.assign({}, this.modelBundle);
        if (!('index' in model)) {
            this.model.bundles.push(model);
        } else {
            const index = model.index;
            delete model.index;
            this.model.bundles[index] = model;
        }
        this.bundleModal.close()
    }

    addTier() {
        this.modelTier = this.service.createTier();
        this.modelTier.serviceType = this.services[0];
        this.tierModal = this.modal.alert().isBlocking(true).component(this.tierModalTpl).open();
    }

    editTier(tier) {
        let model = Object.assign({}, tier);
        model.index = this.model.tiers.indexOf(tier);
        this.modelTier = model;
        this.tierModal = this.modal.alert().isBlocking(true).component(this.tierModalTpl).open();
    }

    onChangeVisibleFromTier(time) {
        if (time === null) {
            this.modelTier.visibleFrom = null;
            this.formTier.controls.visibleFrom.patchValue('');
        }
        if (!parseInt(time)) {
            return;
        }
        let value = moment.unix(time).format("YYYY-MM-DD");
        this.modelTier.visibleFrom = value;
        this.formTier.controls.visibleFrom.patchValue(value);
    }

    onChangeVisibleUntilTier(time) {
        if (time === null) {
            this.modelTier.visibleUntil = null;
            this.formTier.controls.visibleUntil.patchValue('');
        }
        if (!parseInt(time)) {
            return;
        }
        let value = moment.unix(time).format("YYYY-MM-DD");
        this.modelTier.visibleUntil = value;
        this.formTier.controls.visibleUntil.patchValue(value);
    }

    onChangeVisibleFromAnalytics(time) {
        if (time === null) {
            this.modelAnalyticsTariff.visibleFrom = null;
            this.formAnalyticsTariff.controls.visibleFrom.patchValue('');
        }
        if (!parseInt(time)) {
            return;
        }
        let value = moment.unix(time).format("YYYY-MM-DD");
        this.modelAnalyticsTariff.visibleFrom = value;
        this.formAnalyticsTariff.controls.visibleFrom.patchValue(value);
    }

    onChangeVisibleUntilAnalytics(time) {
        if (time === null) {
            this.modelAnalyticsTariff.visibleUntil = null;
            this.formAnalyticsTariff.controls.visibleUntil.patchValue('');
        }
        if (!parseInt(time)) {
            return;
        }
        let value = moment.unix(time).format("YYYY-MM-DD");
        this.modelAnalyticsTariff.visibleUntil = value;
        this.formAnalyticsTariff.controls.visibleUntil.patchValue(value);
    }

    deleteTier(tier) {
        if (!this.model.id || !tier.id) {
            this.model.tiers = this.model.tiers.filter(_ => _ !== tier);
        } else {
            this.model.tiers = this.model.tiers.filter(_ => _.id !== tier.id);
        }
    }

    onSubmitTier() {
        let model = Object.assign({}, this.modelTier);
        if (!('index' in model)) {
            this.model.tiers.push(model);
        } else {
            const index = model.index;
            delete model.index;
            this.model.tiers[index] = model;
        }
        this.tierModal.close()
    }

    addAnalytics() {
        this.modelAnalyticsTariff = this.service.createAnalyticsTariff();
        this.analyticsModal = this.modal.alert().isBlocking(true).component(this.analyticsModalTpl).open();
    }

    editAnalytics(tariff: AnalyticsTariff) {
        let model = Object.assign({}, tariff);
        model.index = this.model.analyticsTariffs.indexOf(tariff);
        this.modelAnalyticsTariff = model;
        this.analyticsModal = this.modal.alert().isBlocking(true).component(this.analyticsModalTpl).open();
    }

    deleteAnalytics(tariff: AnalyticsTariff) {
        if (!this.model.id || !tariff.id) {
            this.model.analyticsTariffs = this.model.analyticsTariffs.filter(_ => _ !== tariff);
        } else {
            this.model.analyticsTariffs = this.model.analyticsTariffs.filter(_ => _.id !== tariff.id);
        }
    }

    onSubmitAnalytics() {
        let model = Object.assign({}, this.modelAnalyticsTariff);
        if (!('index' in model)) {
            this.model.analyticsTariffs.push(model);
        } else {
            const index = model.index;
            delete model.index;
            this.model.analyticsTariffs[index] = model;
        }
        this.analyticsModal.onDestroy.subscribe(() => {
            this.modelAnalyticsTariff = null;
        });
        this.analyticsModal.close();
    }

    onSubmit() {
        this.loading = true;
        let model = Object.assign({}, this.model);
        model.tiers = [];
        model.bundles = [];
        if (model.paymentType === 'PREPAID') {
            model.bundles = this.model.bundles.map(b => {
                if (b.serviceType === 'CLI') {
                    b.serviceType = 'VOICE';
                }
                return b;
            });
        } else {
            model.tiers = this.model.tiers.map(t => {
                if (t.serviceType === 'CLI') {
                    t.serviceType = 'VOICE';
                }
                return t;
            });
            model.tiers = this.model.tiers;
        }
        model.analyticsTariffs.forEach(at => {
            for (let i in at) {if (!at[i]) { delete at[i];}}
        });
        this.model = CustomUtils.trimModelFields(model);
        this.service.saveGroup(model).subscribe({
            next: (res: boolean) => {
                this.loading = false;
                this.onAfterSave.emit();
                this.notificationService.success(
                    'Pricing group ' + (this.model.id ? 'updated' : 'created'),
                    'General pricing'
                );
            },
            error: error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'General pricing',
                    message: 'An error occurred while creating/updating the pricing group',
                    serviceName: 'GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status,
                    ts: error.timestamp ? error.timestamp : null
                });
            }
        });
    }


    getMinDate() {
        return new Date()
    }
}
