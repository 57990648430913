import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TextTemplateComponent } from "../../test/text-template/text-template.component";
import { Destination } from '../models/destination.model';
import {
    MoTestDetails,
    MoTestingRequest,
    MoTestingResult,
    MoTestingResultsCollection,
    MoTestingSearch, MoText
} from "../models/mo-testing.model";
import { RestUtils } from './rest-utils';

@Injectable()
export class MoTestingService {

    //PDU decoding JS library
    // @ts-ignore
    pdu = require('pdu');
    http: HttpClient;
    utils = new RestUtils();
    headers = new HttpHeaders();
    private actionSubject: Subject<MoActionData> = new Subject<MoActionData>();
    action$ = this.actionSubject.asObservable();

    STATUS_CODE_MAP = {
        "-1": { "description": "Success", "link": null },
        "1": { "description": "Generic failure cause", "link": "https://developer.android.com/reference/android/telephony/SmsManager#RESULT_ERROR_GENERIC_FAILURE" },
        "16": { "description": "Failed because of a modem error", "link": "https://developer.android.com/reference/android/telephony/SmsManager#RESULT_MODEM_ERROR" },
        "111": { "description": "The vendor RIL received an unexpected or incorrect response.", "link": "https://developer.android.com/reference/android/telephony/SmsManager#RESULT_RIL_MODEM_ERR" },
        "17": { "description": "Failed because of a network error.", "link": "https://developer.android.com/reference/android/telephony/SmsManager#RESULT_NETWORK_ERROR" },
        "100": { "description": "The radio did not start or is resetting.", "link": "https://developer.android.com/reference/android/telephony/SmsManager#RESULT_RIL_RADIO_NOT_AVAILABLE" },
        "101": { "description": "The radio failed to send the sms and needs to retry.", "link": "https://developer.android.com/reference/android/telephony/SmsManager#RESULT_RIL_SMS_SEND_FAIL_RETRY" },
    }

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    announceAction(action: MoActionData) {
        this.actionSubject.next(action);
    }

    getFileFromExportResult(result) {
        // split by "/" and return last item
        return result.filePath.split('/').slice(-1);
    }

    results(params: AllRequestParams): Observable<MoTestingResultsCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: ['id,desc'],
            userIds: []
        };
        if (params.userIds && params.userIds.length) {
            queryParams.userIds = params.userIds;
        } else {
            delete queryParams.userIds;
        }

        let url = this.utils.buildUrl('ROLE/motc/test-results', queryParams);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<MoTestingResultsCollection>(url, options).pipe(map(response => {
            response.content = response.content.map(res => this.prepareTestResult(res));
            return response;
        }));
    }

    formatDestination(dest: Destination, icon = false): string {
        if ((dest.mnc === null && dest.mcc === null) || dest.isManualNumber) {
            return (dest.countryName ? dest.countryName : 'Unknown') + '/' + dest.phone;
        }
        let str = (dest.countryName ? dest.countryName : 'Unknown') + '(' + dest.mcc + ')/' + (dest.providerName ? dest.providerName : 'Unknown') + '(' + dest.mnc + ')';
        if (dest.originalMnc) {
            let iconStr = icon ? '<i class="icon-ported"></i>' : '<=';
            let provider = dest.originalProviderName ? dest.originalProviderName : 'Unknown';
            let originalStr = ' ' + iconStr + ' ' + provider + '(' + dest.originalMnc + ')';
            if (icon) {
                originalStr = `<span title="Ported from ${provider}">${originalStr}</span>`;
            }
            str += originalStr;
        }

        return str;
    }

    prepareTestResult(result: MoTestingResult) {
        if (result.textDelivered && result.telqId) {
            result.textDelivered = result.textDelivered.replace(TextTemplateComponent.PLACEHOLDER_MARKER, result.telqId);
        }
        return result;
    }

    searchResults(params: AllRequestParams, searchParams: MoTestingSearch): Observable<MoTestingResultsCollection> {
        let queryParams = {
            page: params.page,
            size: params.size,
            sort: ['id,desc']
        };
        queryParams.sort = ['id,desc'];
        let url = this.utils.buildUrl('ROLE/motc/test-results/search', queryParams);

        for (let i in queryParams) {
            searchParams[i] = queryParams[i];
        }

        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post<MoTestingResultsCollection>(url, searchParams, options).pipe(map(_ => {
            let response = _;
            response.content = response.content.map(res => this.prepareTestResult(res));
            return response;
        }));
    }

    run(group: MoTestingRequest): Observable<any> {
        let url = this.utils.buildUrl(`ROLE/motc/test-groups`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.post(url, group, options);
    }

    recentDestinations(): Observable<Destination[]> {
        let url = this.utils.buildUrl('ROLE/motc/recent-origins', {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<Destination[]>(url, options);
    }

    parsePdu(raw: string): string {
        let text = [];
        text.push('PDU: ' + raw);
        try {
            let decodedPdu = this.pdu.parse(raw, true);
            text.push('');
            text.push('SMSC in hex: ' + decodedPdu['smsc_hex']);
            text.push('SMSC translated value: ' + decodedPdu['smsc']);
            text.push('SMSC Type: ' + decodedPdu['smsc_type']);
            text.push('');
            text.push('Sender ID in hex: ' + decodedPdu['sender_hex']);
            text.push('Sender ID translated value: ' + decodedPdu['sender']);
            text.push('Sender ID Type: ' + decodedPdu['sender_type']);
            text.push('');
            text.push('Protocol Identifier in hex: ' + decodedPdu['protocol_identifier']);
            text.push('');
            text.push('Encoding in hex: ' + decodedPdu['encoding_hex']);
            text.push('Encoding translated value: ' + decodedPdu['encoding']);
            text.push('');
            text.push('Time stamp in hex: ' + decodedPdu['time_hex']);
            text.push('Time stamp translated value: ' + decodedPdu['time']);
            text.push('');
            text.push('Text in hex: ' + decodedPdu['text_hex']);
            text.push('Text translated value: ' + decodedPdu['text']);
            text.push('');
            if (decodedPdu['udh'] == null) {
                text.push('* No UDH is present in the PDU *');
            } else {
                text.push('UDH in hex: ' + decodedPdu['udh_hex']);
                text.push('  UDH length: ' + decodedPdu['udh']['length']);
                text.push('  UDH IEI: ' + decodedPdu['udh']['iei']);
                text.push('  UDH Header Length: ' + decodedPdu['udh']['header_length']);
                text.push('  UDH Reference Number: ' + decodedPdu['udh']['reference_number']);
                text.push('  UDH Parts: ' + decodedPdu['udh']['parts']);
                text.push('  UDH Current part: ' + decodedPdu['udh']['current_part']);
            }
        } catch (e) {
            text.push('* Parsing error *')
        }
        return text.join("\n");
    }

    testDetails(id: number): Observable<MoTestDetails> {
        let url = this.utils.buildUrl(`ROLE/motc/test-results/${id}/details`, {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<MoTestDetails>(url, options).pipe(
            map(details => {
                details.statuses = details.statuses.map(status => {
                    status.phoneTimestamp = new Date(status.phoneTimestamp);
                    status.serverReceivedAt = new Date(status.serverReceivedAt);
                    if (this.STATUS_CODE_MAP[status.statusCode]) {
                        const codeObject = this.STATUS_CODE_MAP[status.statusCode];
                        status.codeDescription = codeObject.description;
                        status.codeReferenceLink = codeObject.link;
                    }
                    return status;
                });
                return details;
            })
        );
    }

    create(): MoTestingRequest {
        return {
            destinations: [],
            dynamicTexts: [],
            origins: [],
            protocolId: null,
            encodingId: null,
            amountX: 1,
            commentText: null,
            ttl: 60 * 60
        };
    }

    static generateTextId(text: MoText): string {
        let parts = [text.text];
        if (text.hasTelqId) {
            parts.push(text.telqIdType);
            if (text.telqIdType === 'ALPHA' || text.telqIdType === 'ALPHA_NUMERIC') {
                parts.push(text.telqIdCase);
            }
            if (text.telqIdType !== 'WHATSAPP_CODE') {
                parts.push(String(text.telqIdLength));
            }
        }
        return parts.join('_');
    }
}

export class AllRequestParams {

    size: number = 20;
    page: number = 1;
    search: string;

    sort: string[] = [];

    userIds: number[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}

export interface MoActionData {
    name: string;
    row: MoTestingResult;
    column: string;
}