import { Injectable } from '@angular/core';
import { catchError, Observable, retry, RetryConfig, throwError, timer } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { BackendChannelService } from './backend-channel.service';

@Injectable({
    providedIn: 'root'
})
export class LiveRealtimeService {

    private socket$: WebSocketSubject<any>;
    private URL: string = '';
    private RECONNECT_INTERVAL: number = 30000;
    private MAX_RETRY_ATTEMPTS: number = 5;

    constructor(private backendChannel: BackendChannelService) {
        let url = this.backendChannel.getBaseUrl(this.backendChannel.$current.value) + '/realtime/status-updates';
        this.URL = url.replace('http', 'ws');
    }

    public connect(): Observable<any> {
        this.socket$ = webSocket(this.URL);
        const retryConfig: RetryConfig = {
            count: this.MAX_RETRY_ATTEMPTS,
            delay: this.RECONNECT_INTERVAL,
        };

        return this.socket$.pipe(
            retry(retryConfig),
            catchError((error) => throwError(() => error)),
        );
    }

    public sendMessage(msg: TrackTestIds) {
        if (this.socket$ && this.socket$.closed) {
            console.debug('Cannot send message. Socket is closed.');
            return;
        }
        this.socket$.next(msg);
    }

    public close() {
        this.socket$.complete();
        this.socket$.unsubscribe();
    }

}


interface TrackTestIds {
    trackedTestIds: number[];
}
