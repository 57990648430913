
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from '@angular/router';
import { CellClickedEvent, ColDef, ColumnMovedEvent, ColumnResizedEvent, ColumnVisibleEvent, GridReadyEvent, ICellRendererParams, RowSelectedEvent } from 'ag-grid-community';
import { debounceTime } from 'rxjs';
import { AGTableBase } from '../../../shared/components/table/ag-table-base';
import {
    AppscriptsNumber,
    AppscriptsNumberDetails,
    AppscriptsNumbersCollection,
    AppscriptsNumbersFilter,
    AppscriptsUpdateNumber,
    AppscriptsUpdateUser
} from '../../../shared/models/appscripts-user.model';
import { MoNumberVerificationInformation } from '../../../shared/models/mo-number-verification-information.model';
import { AppscriptsNumberActionData, AppscriptsUserRequestParams, AppscriptsUserService } from '../../../shared/services/appscripts-user.service';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { UsersService } from '../../../shared/services/users.service';
import { ValidationService, Validators as Vld } from "../../../shared/services/validation.service";
import { AppscriptsUserTableActionsComponent } from './appscripts-user-table-actions/appscripts-user-table-actions.component';
declare var moment: any;

@Component({
    selector: 'app-appscripts-user-table',
    templateUrl: 'appscripts-user-table.component.html',
    styleUrls: ['appscripts-user-table.component.scss']
})

export class AppscriptsUserTableComponent extends AGTableBase implements OnInit, OnDestroy {

    @Output() changeBatch = new EventEmitter<AppscriptsNumber[]>();
    @Input() filter: AppscriptsNumbersFilter;

    @ViewChild('infoModalTpl', { read: TemplateRef, static: false }) infoModalTpl: any;
    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;
    @ViewChild('errorsModalTpl', { read: TemplateRef, static: false }) errorsModalTpl: any;
    @ViewChild('balanceModalTpl', { read: TemplateRef, static: false }) balanceModalTpl: any;
    @ViewChild('commentModalTpl', { read: TemplateRef, static: false }) commentModalTpl: any;
    commentModalDialogRef: DialogRef;

    storageContextName = "appscripts-numbers";

    balanceUserId;
    currentRow: AppscriptsNumber;
    commentTextFormControl: FormControl = new FormControl();

    numberDetailsPhone: string;
    numberDetails: AppscriptsNumberDetails;
    numberVerificationInfo: MoNumberVerificationInformation;
    numberModel: AppscriptsUpdateNumber;
    userModel: AppscriptsUpdateUser;
    numberForm: FormGroup;
    userForm: FormGroup;
    currentSection: string = '';
    userData = {
        registeredAt: null,
        balance: 0,
        lastActive: null
    }

    loading: boolean = true;
    showNumberVerificationResetButton: boolean = true;

    requestParams = new AppscriptsUserRequestParams()
    private balanceModal: DialogRef;
    batchSelectedIds: Set<number> = new Set();

    // ng grid
    columnDefs: ColDef[] = [
        {
            headerName: '', field: 'batch', checkboxSelection: true,
            maxWidth: 40, headerCheckboxSelection: true, pinned: 'left',
            lockPinned: true, lockPosition: 'left', lockVisible: true,
            suppressColumnsToolPanel: true, suppressMenu: true, suppressMovable: true
        },
        {
            headerName: 'ID', field: 'id',
            flex: 0.3,
            sortable: true, comparator: () => 0, initialSort: 'desc', sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                return `<span class="one-line">${params.data.id}</span>`;
            }
        },
        {
            headerName: 'Phone', field: 'phoneNumber',
            flex: 0.7,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                return `<div class="one-line clickable"><span>${params.data.phoneNumber}</span></div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event)
        },
        {
            headerName: 'Network', field: 'network',
            flex: 0.8,
            cellRenderer: (params: ICellRendererParams) => {
                return this.formatNetwork(params.data);
            }
        },
        {
            headerName: 'Last active', field: 'lastPing',
            flex: 0.8, minWidth: 90, maxWidth: 90,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                return this.formatDateInTwoLines(params.data.lastPing);
            }
        },
        {
            headerName: 'Balance', field: 'ub.balance',
            flex: 0.35,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const balance = (params.data.balance > 0) ? (params.data.balance / 100) : 0;
                return `<div class="one-line clickable"><span>${balance}</span></div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event)
        },
        {
            headerName: 'MT SMS', field: 'mtSmsCount',
            flex: 0.3,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
        },
        {
            headerName: 'MO SMS', field: 'moSmsCount',
            flex: 0.3,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
        },
        {
            headerName: 'Voice Count', field: 'voiceCallCount',
            flex: 0.3,
        },
        {
            headerName: 'Show MT', field: 'showNumberMt',
            flex: 0.3, cellClass: 'justify-content-center',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const isChecked = params.data.showNumberMt ? 'checked' : '';
                return `<div class="form-check form-switch d-flex justify-content-center">
                            <input type="checkbox" class="form-check-input medium" ${isChecked}/>
                        </div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event)
        },
        {
            headerName: 'Show MO', field: 'showNumberMo',
            flex: 0.3, cellClass: 'justify-content-center',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const isChecked = params.data.showNumberMo ? 'checked' : '';
                return `<div class="form-check form-switch d-flex justify-content-center">
                            <input type="checkbox" class="form-check-input medium" ${isChecked}/>
                        </div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event)
        },
        {
            headerName: 'Allow MO', field: 'moEnabledForUser',
            flex: 0.3, cellClass: 'justify-content-center',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const isChecked = params.data.moEnabledForUser ? 'checked' : '';
                return `<div class="form-check form-switch d-flex justify-content-center">
                            <input type="checkbox" class="form-check-input medium" ${isChecked}/>
                        </div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event)
        },
        {
            headerName: 'Allow VT', field: 'voiceEnabledForUser',
            flex: 0.3, cellClass: 'justify-content-center',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const isChecked = params.data.voiceEnabledForUser ? 'checked' : '';
                return `<div class="form-check form-switch d-flex justify-content-center">
                            <input type="checkbox" class="form-check-input medium" ${isChecked}/>
                        </div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event)
        },
        {
            headerName: 'Blocked', field: 'blocked',
            flex: 0.35, cellClass: 'justify-content-center',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const isChecked = params.data.blocked ? 'checked' : '';
                return `<div class="form-check form-switch d-flex justify-content-center">
                            <input type="checkbox" class="form-check-input medium" ${isChecked}/>
                        </div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event)
        },
        {
            headerName: 'Update HLR', field: 'updateHlr',
            flex: 0.3, cellClass: 'justify-content-center',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const isChecked = params.data.updateHlr ? 'checked' : '';
                return `<div class="form-check form-switch d-flex justify-content-center">
                            <input type="checkbox" class="form-check-input medium" ${isChecked}/>
                        </div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event)
        },
        {
            headerName: 'Actions', field: 'actions', maxWidth: 120, minWidth: 120,
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
            tooltipField: 'actions',
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: AppscriptsUserTableActionsComponent,
            onCellClicked: (event: CellClickedEvent) => this.onActionClick(event),
        }
    ];

    rowData!: AppscriptsNumber[];
    // ng grid

    constructor(
        public notificationService: NotificationService,
        public service: AppscriptsUserService,
        public router: Router,
        public modal: ModalService,
        private datePipe: DatePipe,
        private formBuilder: FormBuilder,
        private localStorage: LocalStorageService,
        private userService: UsersService,
        public validationService: ValidationService
    ) {
        super();
        this.numberForm = formBuilder.group({
            country: ['', Vld.compose([Vld.required])],
            mcc: ['', Vld.compose([Vld.required, Vld.digits(true), Vld.maxLength(3)])],
            mnc: ['', Vld.compose([Vld.required, Vld.digits(true), Vld.maxLength(3)])],
            originalMnc: ['', Vld.compose([Vld.digits(true), Vld.maxLength(3)])],
            provider: ['', Vld.compose([Vld.required])],
            originalProvider: [''],
            phoneNumber: ['', Vld.compose([Vld.required])],
            blocked: [''],
            showNumberMt: [''],
            showNumberMo: [''],
            updateHlr: [''],
        });

        this.userForm = formBuilder.group({
            email: ['', Vld.compose([Vld.email(true)])],
            paymentMethodName: [''],
            paymentAddress: [''],
            referrerCode: [''],
            referralCode: ['', Vld.compose([Vld.required, Vld.maxLength(255)])],
        });
    }

    ngOnInit() {
        this.actionSubscription = this.service.action$.subscribe(
            action => {
                this.onAction(action);
            }
        );
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            const currentColumnState = this.gridApi.getColumnState();
            this.localStorage.set(`appscripts_numbers_table_state_${this.userService.authUser.id}`, currentColumnState);
        });
        this.paginationPageSize = this.localStorage.get(`appscripts_numbers_table_component_size_${this.userService.authUser.id}`) || 20;
    }

    update() {
        this.requestParams.size = this.paginationPageSize;
        this.requestParams.page = this.currentPageNumber - 1;
        this.requestParams.sort = this.sortState.length === 0 ? ['id,desc'] : this.sortState;

        if (this.filter) {
            this.requestParams.filter = this.filter;
        }

        this.loading = true;
        this.service.all(this.requestParams).subscribe({
            next: (data: AppscriptsNumbersCollection) => {
                this.rowData = data.content;
                this.totalRowsCount = data.totalElements;
                this.loading = false;
            },
            error: e => {
                this.notificationService.error({
                    title: 'Appscripts numbers',
                    message: 'An error occurred while loading numbers',
                    service: 'APPSRIPTS',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                this.loading = false;
            }
        });
    }

    onCellClick(event: CellClickedEvent) {
        const colId = event.column.getColId();
        this.currentRow = event.data as AppscriptsNumber;
        const targetClassList = (event.event.target as HTMLElement).classList;

        switch (colId) {
            case 'phoneNumber':
                this.handlePhoneNumberClick(event.data);
                break;
            case 'mtSmsCount':
                this.navigateTo('/appscripts/sms', { phonenumber: event.data.phoneNumber });
                break;
            case 'moSmsCount':
                this.navigateTo('/appscripts/outgoing-sms', { phonenumber: event.data.phoneNumber });
                break;
            case 'ub.balance':
                this.openBalanceModal(event.data.userId);
                break;
            case 'moEnabledForUser':
            case 'voiceEnabledForUser':
                if (targetClassList.contains('form-check-input'))
                    this.handleToggle(colId);
                break;
            default:
                if (targetClassList.contains('form-check-input'))
                    this.updateNumber(colId);
                break;
        }
    }

    private handlePhoneNumberClick(data: AppscriptsNumber) {
        this.onInfo(data);
    }

    private navigateTo(route: string, queryParams: object) {
        this.router.navigate([route], { queryParams });
    }

    private openBalanceModal(userId: string) {
        this.balanceUserId = userId;
        this.balanceModal = this.modal.alert().dialogClass('modal-dialog medium-modal').component(this.balanceModalTpl).open();
    }

    private handleToggle(colId: string) {
        this.loading = true;
        const row = this.currentRow;
        let val = colId === 'moEnabledForUser' ? !row.moEnabledForUser : !row.voiceEnabledForUser;
        const updateMethod = colId === 'moEnabledForUser'
            ? this.service.updateMoEnabledForUserById(row.userId, val)
            : this.service.updateVoiceEnabledForUserById(row.userId, val);

        updateMethod.subscribe({
            next: () => {
                this.notificationService.success('Number updated', 'Appscripts');
                this.update();
            },
            error: e => {
                this.handleUpdateError(colId, val, e);
            }
        });
    }

    private handleUpdateError(colId: string, val: boolean, error: any) {
        const row = this.currentRow;
        if (colId === 'moEnabledForUser') {
            row.moEnabledForUser = !val;
        } else if (colId === 'voiceEnabledForUser') {
            row.voiceEnabledForUser = !val;
        }

        this.notificationService.error({
            title: 'Appscripts numbers',
            message: 'Number updating an error.',
            service: 'APPSRIPTS',
            requestMessage: error.statusText,
            requestCode: error.status,
            ts: error.timestamp ? error.timestamp : null
        });
    }

    private updateNumber(colId: string) {
        const row = this.currentRow;
        const data: AppscriptsUpdateNumber = {
            phoneNumber: row.phoneNumber,
            mcc: row.mcc,
            mnc: row.mnc,
            country: row.country,
            provider: row.provider,
            originalProvider: row.originalProvider,
            originalMnc: row.originalMnc,
            showNumberMt: colId === 'showNumberMt' ? !row.showNumberMt : row.showNumberMt,
            showNumberMo: colId === 'showNumberMo' ? !row.showNumberMo : row.showNumberMo,
            blocked: colId === 'blocked' ? !row.blocked : row.blocked,
            updateHlr: colId === 'updateHlr' ? !row.updateHlr : row.updateHlr,
            comment: this.commentTextFormControl?.value
        };

        this.service.updateNumber(row.id, data).subscribe({
            next: () => {
                this.notificationService.success('Number updated', 'Appscripts');
                this.update();
                this.commentModalDialogRef?.close();
                this.commentModalDialogRef = null;
            },
            error: e => {
                this.notificationService.error({
                    title: 'Appscripts numbers',
                    message: 'Number updating an error.',
                    service: 'APPSRIPTS',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
            }
        });
    }

    onAfterSaveBalance() {
        this.balanceModal.close(true);
        this.update();
    }

    onAction(event: AppscriptsNumberActionData): void {
        this.currentRow = event.row;
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row);
                }
            });
        }
        if (event.name === 'info') {
            this.onInfo(event.row);
        }
        if (event.name === 'comment') {
            this.commentTextFormControl.setValue(this.currentRow.comment);
            this.commentModalDialogRef = this.modal.alert().size('sm').component(this.commentModalTpl).open();
        }
        if (event.name === 'uploadLogsToFirebase') {
            this.loading = true;
            this.service.uploadLogsToFirebase(event.row.phoneNumber).subscribe({
                next: () => {
                    this.notificationService.success('Logs uploaded', 'Appscripts');
                    this.update();
                },
                error: e => {
                    this.notificationService.error({
                        title: 'Appscripts numbers',
                        message: 'An error occurred while uploading logs.',
                        service: 'APPSRIPTS',
                        requestMessage: e.statusText,
                        requestCode: e.status,
                        ts: e.timestamp ? e.timestamp : null
                    });
                    this.loading = false;
                }
            });
        }
    }

    public onCommentSave(): void {
        this.loading = true;
        this.currentRow.comment = this.commentTextFormControl.value;
        this.updateNumber('comment');
    }

    private onInfo(row: AppscriptsNumber) {
        this.numberDetailsPhone = row.phoneNumber
        this.numberDetails = null;
        this.numberVerificationInfo = null;
        this.userModel = null;
        this.numberModel = null;
        this.currentSection = 'appscripts-numbers-details-main';

        this.service.numberDetails(row.id).subscribe({
            next: details => this.numberDetails = details,
            error: e => { }
        });
        this.service.getVerificationCount(row.phoneNumber).subscribe({
            next: d => this.numberVerificationInfo = d,
            error: e => { }
        });

        this.service.one(row.id).subscribe((model) => {
            this.userModel = {
                id: model.userId,
                email: model.email,
                referrerCode: model.referrerCode,
                referralCode: model.referralCode,
                paymentMethodName: model.paymentMethodName,
                paymentAddress: model.paymentAddress
            };
            this.numberModel = {
                id: model.id,
                phoneNumber: model.phoneNumber,
                mcc: model.mcc,
                mnc: model.mnc,
                country: model.country,
                provider: model.provider,
                originalProvider: model.originalProvider,
                originalMnc: model.originalMnc,
                showNumberMt: model.showNumberMt,
                showNumberMo: model.showNumberMo,
                blocked: model.blocked,
                updateHlr: model.updateHlr
            };
            this.userData.balance = model.balance;
            this.userData.registeredAt = model.registeredAt ? new Date(model.registeredAt) : null;
            this.userData.lastActive = model.lastPing ? new Date(model.lastPing) : null;
        });

        this.modal.alert().dialogClass('modal-dialog large-modal').component(this.infoModalTpl).open();
    }

    delete(user: AppscriptsNumber) {
        this.loading = true;
        this.service
            .deleteUser(user.userId)
            .subscribe(() => {
                this.update();
                this.notificationService.success('User removed', 'Appscripts')
            }, e => {
                this.loading = false;
            });
    }

    formatDate(date, format = 'medium') {
        if (typeof date === 'number' && date) {
            return this.datePipe.transform(new Date(date * 1000), format);
        }

        if (typeof date === 'string' && date) {
            return this.datePipe.transform(new Date(date), format);
        }

        if (date instanceof Date) {
            return this.datePipe.transform(date, format);
        }
        return '-'
    }

    formatDateInTwoLines(item: string) {
        if (item) {
            const date = moment(item).format('DD/MM/YY HH:mm:ss').split(" ");
            return `<div class="two-lines"><span>${date[1]} <br>${date[0]}</span></div>`;
        }
        return 'N/A';
    }


    formatNetwork(row: AppscriptsNumber) {
        let text = `${row.country}(${row.mcc})/${row.provider}(${row.mnc})`;
        if (row.originalProvider) {
            text += ` <i class="icon-ported"></i> ${row.originalProvider}(${row.originalMnc})`;
        }
        return `<span class="two-lines">${text}</span>`;
    }

    onClickReset(): void {
        this.showNumberVerificationResetButton = false;
        this.service.resetVerificationCount(this.numberVerificationInfo.phoneNumber).subscribe({
            next: (res) => {
                this.service.getVerificationCount(this.numberVerificationInfo.phoneNumber).subscribe((res) => {
                    this.notificationService.success('Verification count reset successful', 'Modems Users');
                    this.numberVerificationInfo = res;
                    this.showNumberVerificationResetButton = true;
                });
            },
            error: (e) => {
                this.notificationService.error({
                    title: 'Appscripts numbers',
                    message: 'An error occurred while resetting.',
                    service: 'MO-TESTING',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                this.showNumberVerificationResetButton = true;
            }
        })
    }

    onSubmitNumber() {
        this.loading = true;
        this.service.updateNumber(this.numberModel.id, this.numberModel)
            .subscribe((res) => {
                this.loading = false;
                this.notificationService.success('Number updated', 'Appscripts');
            }, (error) => {
                this.loading = false;
                this.validationService.handleRequestError(error, 'Appscripts');
            });
    }

    onSubmitUser() {
        this.loading = true;
        this.service.updateUser(this.userModel.id, this.userModel)
            .subscribe((res) => {
                this.loading = false;
                this.notificationService.success('User updated', 'Appscripts');
            }, (error) => {
                this.loading = false;
                this.validationService.handleRequestError(error, 'Appscripts');
            });
    }

    scrollTo(sectionId: string) {
        document.querySelector('#' + sectionId).scrollIntoView();
        this.currentSection = sectionId;
    }

    // ng grid
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        const columnState = this.localStorage.get(`appscripts_numbers_table_state_${this.userService.authUser.id}`);
        this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
    }

    resetBatch() {
        this.batchSelectedIds.clear();
        this.gridApi.deselectAll();
    }

    onRowSelected(event: RowSelectedEvent) {
        const selectedRows = this.gridApi.getSelectedRows();
        this.batchSelectedIds = new Set(selectedRows.map(row => row.id));
        this.changeBatch.emit(selectedRows);
    }
    // ng grid

    ngOnDestroy() {
        this.actionSubscription.unsubscribe();
    }
}
