import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subject, Subscription, debounceTime, distinctUntilChanged, tap } from "rxjs";
import { map } from "rxjs/operators";
import { ContentTemplate, ContentTemplateText, ContentText, ContentTextExcelItem } from "../../../shared/models/content-templates";
import { DataCoding } from "../../../shared/models/data-coding.model";
import { DefaultTelqId } from '../../../shared/models/telqId.model';
import { AllRequestParams, ContentTemplateService } from "../../../shared/services/content-template.service";
import { CustomUtils } from "../../../shared/services/custom-utils";
import { LiveNumberTestingService } from "../../../shared/services/live-number-testing.service";
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { TemplatesService } from "../../../shared/services/templates.service";
import { ValidationService, Validators as Vld } from "../../../shared/services/validation.service";
import { TextTemplateComponent } from "../../text-template/text-template.component";

@Component({
    selector: 'app-lnt-content-templates',
    templateUrl: './lnt-content-templates.component.html',
    styleUrls: ['./lnt-content-templates.component.scss']
})
export class LntContentTemplatesComponent implements OnInit, AfterViewInit, OnDestroy {

    MAX_TEXT_LEN = 1600;

    @Input() form: FormGroup;

    @Output() changedCount = new EventEmitter<number>();

    dragOver = false;
    senderModel = ''
    @ViewChild('senderInput', { static: false }) senderInput: ElementRef;
    senders: string[] = [];
    @ViewChild('contentTemplatesModalTpl', { read: TemplateRef, static: false }) contentTemplatesModalTpl: any;
    contentTemplatesModal: DialogRef;

    @ViewChild('contentTemplateImportModalTpl', { read: TemplateRef, static: false }) contentTemplateImportModalTpl: any;
    contentTemplateImportModal: DialogRef;

    @ViewChild('openModalForAdditionalTextsTpl', { read: TemplateRef, static: false }) openModalForAdditionalTextsTpl: any;
    openModalForAdditionalTextsModal: DialogRef;

    importContentTemplateFileList;

    @ViewChild(TextTemplateComponent, { static: false }) textTemplate: TextTemplateComponent;
    textModel: ContentTemplateText = {
        value: '',
        telqIdType: TemplatesService.TELQ_ID_TYPE_DEFAULT,
        telqIdCase: TemplatesService.TELQ_ID_CASE_DEFAULT,
        telqIdLength: TemplatesService.TELQ_ID_LEN_DEFAULT,
    };
    textLength = 0;
    private text$ = new Subject<string>();
    private textSubscription: Subscription;

    contentTemplates: ContentTemplate[] = [];
    autoCompleteContentTemplates: ContentTemplate[] = [];
    selectedContentForAdditionalTexts: ContentTemplate;

    @ViewChild('telqIdModalTpl', { read: TemplateRef, static: false }) telqIdModalTpl: any;
    telqIdModal: DialogRef;
    telqIdSettingsTypes = TemplatesService.TELQ_ID_TYPES;
    telqIdSettingsCases = TemplatesService.TELQ_ID_CASES;
    telqIdSettingsForm: FormGroup;
    telqIdLoading = false;
    telqIdDefault: DefaultTelqId = TemplatesService.TELQ_ID_DEFAULT;
    telqIdSettingsModel: {telqIdType: string, telqIdCase: string, telqIdLength: number, text: string, selectedText: ContentTemplateText} = {
        telqIdType: null,
        telqIdCase: null,
        telqIdLength: null,
        text: '',
        selectedText: null
    }
    simulatedTestId = '';

    selectedCount = 0;

    dataCoding: DataCoding;
    dataCodingLoading = false;

    constructor(
        private contentTemplateService: ContentTemplateService,
        public modal: ModalService,
        public notificationService: NotificationService,
        private templateService: TemplatesService,
        private lntService: LiveNumberTestingService,
        public validationService: ValidationService,
        public formBuilder: FormBuilder,
    ) {
        this.telqIdSettingsForm = formBuilder.group({
            telqIdType: [''],
            telqIdCase: [''],
            telqIdLength: ['', Vld.compose([Vld.min(TemplatesService.TELQ_ID_LEN_MIN, true), Vld.max(TemplatesService.TELQ_ID_LEN_MAX, true)])],
        });
    }

    ngOnInit(): void {
        if (!this.form.contains('sender')) {
            const validator = Vld.senderId(11, 20, true)
            this.form.addControl('sender', new FormControl('', validator))
        }
        this.telqIdSettingsForm.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => this.getSimulatedTestId());
        this.templateService.getDefaultTelqIdDetails().subscribe({
            next: (def: DefaultTelqId) => {
                if (!def) { return; }
                this.telqIdDefault = def;
                this.textModel.telqIdLength = def.telqIdLength;
                this.textModel.telqIdCase = def.telqIdCase;
                this.textModel.telqIdType = def.telqIdType;
                this.textModel = { value: '', ...def };
            },
            error: () => { }
        });

        this.textSubscription = this.text$.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            tap(text => this.detectEncoding(text))
        ).subscribe();
    }

    ngAfterViewInit() {
        this.form.get('sender')
            .valueChanges
            .pipe(map(val => {
                if (!val.length) {
                    this.autoCompleteContentTemplates = [];
                }
                return val;
            }))
            .pipe(debounceTime(250), distinctUntilChanged())
            .subscribe(dataValue => {
                const senderText = dataValue;
                if (senderText?.length > 1) {
                    const searchParams = new AllRequestParams();
                    searchParams.page = 0;
                    searchParams.withText = true;
                    searchParams.search = senderText;
                    searchParams.searchSenderOnly = true;
                    this.contentTemplateService.all(searchParams).subscribe({
                        next: res => {
                            res.content.forEach(t => {
                                let selected = this
                                    .contentTemplates
                                    .filter(_ => _.id === t.id)
                                    .filter(_ => _.smsTemplateTextDtos.filter(t => t.selected).length > 0);
                                t.selected = selected.length > 0;
                            });
                            this.autoCompleteContentTemplates = res.content;
                        },
                        error: () => { this.autoCompleteContentTemplates = []; }
                    });
                } else {
                    this.autoCompleteContentTemplates = [];
                }
                this.calculateCount();
            });
    }

    clearSenderField(): void {
        this.senderModel = '';
    }

    onSelectFromAutoComplete(t: ContentTemplate) {
        t.selected = !t.selected;
        if (t.selected) {
            let template: ContentTemplate = {
                id: t.id,
                name: t.name,
                description: t.description,
                expand: false,
                selected: false,
                fromServer: true,
                index: this.contentTemplates.length + 1,
                smsTemplateTextDtos: t.smsTemplateTextDtos.map(_ => Object.assign({}, _))
            }
            template.smsTemplateTextDtos.forEach(t => t.selected = false);
            template.smsTemplateTextDtos[0].selected = true;
            this.contentTemplates.push(template);
        } else {
            this.contentTemplates = this.contentTemplates.filter(_ => _.id !== t.id);
        }
        this.senderModel = '';
        this.autoCompleteContentTemplates = [];
        this.calculateCount();
    }

    getContentTexts(): ContentText[] {
        let selected: ContentText[] = [];
        this.contentTemplates.forEach(ct => {
            ct.smsTemplateTextDtos.filter(_ => _.selected).forEach(text => {
                selected.push({
                    custom: !text.id,
                    senderId: ct.name,
                    text: text.value,
                    fromServer: ct.fromServer,
                    telqIdType: text.telqIdType,
                    telqIdCase: text.telqIdCase,
                    telqIdLength: text.telqIdLength
                });
            });
        });
        let senders = this.senders.map(_ => _);
        if (this.senderModel.length > 0) {
            senders.push(this.senderModel)
        }
        if (senders.length > 0 && this.textLength > 0 && this.textLength <= this.MAX_TEXT_LEN) {
            senders.forEach(sender => {
                selected.push({
                    custom: true,
                    senderId: sender,
                    text: this.textModel.value,
                    fromServer: false,
                    telqIdType: this.textModel.telqIdType,
                    telqIdCase: this.textModel.telqIdCase,
                    telqIdLength: this.textModel.telqIdLength
                });
            });
        }
        return selected;
    }

    reset() {
        this.senders = [];
        this.contentTemplates = [];
        this.selectedCount = 0;
        this.senderModel = '';
        this.textTemplate.setEditorContent('');
        this.calculateCount();
    }

    setContentTexts(contentTexts: ContentText[]) {
        this.contentTemplates = [];
        contentTexts.forEach(ct => {
            this.contentTemplates.push({
                id: null,
                name: ct.senderId,
                description: null,
                selected: true,
                userId: null,
                fromServer: ct.fromServer,
                index: this.contentTemplates.length + 1,
                smsTemplateTextDtos: [{
                    id: null,
                    value: ct.text,
                    selected: true,
                    telqIdType: ct.telqIdType,
                    telqIdCase: ct.telqIdCase,
                    telqIdLength: ct.telqIdLength
                }]
            });
        });
        this.calculateCount();
    }

    openContentTemplatesModal() {
        this.contentTemplatesModal = this.modal.alert()
            .dialogClass('modal-dialog large-modal')
            .component(this.contentTemplatesModalTpl)
            .open();
    }

    openModalForAdditionalTexts(template): void {
        this.selectedContentForAdditionalTexts = template;
        this.openModalForAdditionalTextsModal = this.modal.alert().component(this.openModalForAdditionalTextsTpl).open();
    }

    addSender() {
        if (!this.senderModel?.length) return;
        if (this.senders.includes(this.senderModel)) { return; }
        this.senders.push(this.senderModel);
        this.senderModel = '';
    }

    onTabPressOnSenderInput(event: KeyboardEvent): void {
        this.addSender();
    }

    editSender(sender: string) {
        this.senderModel = sender;
        this.senders = this.senders.filter(_ => _ !== sender);
    }

    removeSender(sender: string) {
        this.senders = this.senders.filter(_ => _ !== sender);
    }

    onChangeText(e) {
        this.textModel.value = e;
        this.textLength = this.getTextLength();
        this.text$.next(e ? e : '');
        this.calculateCount();
    }

    private getTextLength(): number {
        if (this.textModel.value) {
            let text = this.textModel.value;
            text = text.replace(TextTemplateComponent.PLACEHOLDER_MARKER, '');
            return text.length + this.textModel.telqIdLength;
        }
        return 0;
    }

    onClickTelqIdMarker() {
        this.telqIdModal = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.telqIdModalTpl).open();
        this.telqIdSettingsModel.telqIdType = this.textModel.telqIdType;
        this.telqIdSettingsModel.telqIdCase = this.textModel.telqIdCase;
        this.telqIdSettingsModel.telqIdLength = this.textModel.telqIdLength;
        this.telqIdSettingsModel.text = this.textModel.value;
        this.telqIdSettingsModel.selectedText = null;

        this.telqIdModal.result.then(result => {
            this.telqIdModal = null;
        });
    }

    resetTelqIdSettings() {
        const { telqIdType, telqIdCase, telqIdLength } = this.telqIdDefault;
        this.textModel.telqIdLength = telqIdLength;
        this.textModel.telqIdCase = telqIdCase;
        this.textModel.telqIdType = telqIdType;
    }

    addContentTemplate() {
        if (this.form.get('sender').invalid) {
            this.notificationService.error({
                title: 'Content templates',
                message: 'The sender you are trying to add is invalid.',
                serviceName: 'Live number testing'
            });
            return;
        }
        let senders = this.senders.map(_ => _);
        if (this.senderModel && this.senderModel.length > 0) {
            senders.push(this.senderModel);
        }
        if (!senders.length) {
            this.notificationService.error({
                title: 'Content templates',
                message: 'Please add the senders first.',
                serviceName: 'LNT'
            });
            return;
        }
        senders.forEach(sender => {
            let text = Object.assign({
                id: null,
                selected: true,
                description: null
            }, this.textModel) as ContentTemplateText;
            const template = {
                id: null,
                name: sender,
                userId: null,
                selected: true,
                expand: false,
                fromServer: false,
                index: this.contentTemplates.length + 1,
                smsTemplateTextDtos: [text]
            } as ContentTemplate;
            this.contentTemplates.push(template);
        });
        this.senderModel = '';
        this.textTemplate.setEditorContent('');
        this.senders = [];
        this.resetTelqIdSettings();
        this.calculateCount();
    }

    getHtmlContentForText(text: ContentTemplateText): string {
        let title = CustomUtils.escapeHTML(text.value);
        title = title.replace(
            TextTemplateComponent.PLACEHOLDER_MARKER,
            `<span class="icon-telq-id align-bottom"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>`);
        return ' ' + title;
    }

    onClickTemplateText(event: any, template: ContentTemplate, text: ContentTemplateText) {
        const areSenderAndTextAlreadyPresent = this.senderModel.trim().length > 0 && this.textModel.value.trim().length > 0
        if (areSenderAndTextAlreadyPresent) {
            this.addContentTemplate();
            this.notificationService.info('Sender and text added.', 'Live number testing.');
        }
        if (event.target.classList.contains('path3')) {
            this.onClickSelectedTextTelqId(text);
            return;
        }
        this.senderModel = template.name;
        this.textModel.telqIdType = text.telqIdType;
        this.textModel.telqIdCase = text.telqIdCase;
        this.textModel.telqIdLength = text.telqIdLength;
        this.textTemplate.setEditorContent(CustomUtils.escapeHTML(text.value));
        template.smsTemplateTextDtos = template.smsTemplateTextDtos.filter(_ => _ !== text);
        if (!template.smsTemplateTextDtos.filter(_ => _.selected).length) {
            this.removeContentTemplate(template);
        }
    }

    onClickSelectedTextTelqId(text: ContentTemplateText) {
        this.telqIdSettingsModel.telqIdLength = text.telqIdLength;
        this.telqIdSettingsModel.telqIdCase = text.telqIdCase;
        this.telqIdSettingsModel.telqIdType = text.telqIdType;
        this.telqIdSettingsModel.text = text.value;
        this.telqIdSettingsModel.selectedText = text;

        this.telqIdModal = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.telqIdModalTpl).open();
        this.telqIdModal.result.then(result => {
            this.telqIdModal = null;
        });
    }

    onChangeSelectText(template: ContentTemplate, text: ContentTemplateText) {
        text.selected = !text.selected;
        if (template.smsTemplateTextDtos.every(_ => !_.selected)) {
            this.contentTemplates = this.contentTemplates.filter(_ => _ !== template);
        }
        this.calculateCount();
    }

    onChangeAdditionalTextSelect(text: ContentTemplateText): void {
        const selectedTextsCount = this.selectedContentForAdditionalTexts.smsTemplateTextDtos.filter(stt => stt.selected).length;
        if (selectedTextsCount === 1 && text.selected) {
            this.notificationService.error({
                title: 'LNT Content Templates',
                message: 'At least one text needs to be selected.',
                service: 'LNT'
            });
            return;
        }
        this.onChangeSelectText(this.selectedContentForAdditionalTexts, text);

    }

    removeContentTemplate(template: ContentTemplate) {
        this.contentTemplates = this.contentTemplates.filter(_ => _ !== template);
        this.calculateCount();
    }

    removeTextFromContentTemplate(template: ContentTemplate, text: ContentTemplateText) {
        template.smsTemplateTextDtos = template.smsTemplateTextDtos.filter(_ => _.id !== text.id);
        this.calculateCount();
    }

    removeContentTemplateFromAdditionalTexts(): void {
        this.removeContentTemplate(this.selectedContentForAdditionalTexts);
    }

    onSelectedChange(templates: ContentTemplate[]) {
        this.contentTemplates = templates;
        this.calculateCount();
    }

    inputListText(text: string): string {
        return CustomUtils.text(text, 40);
    }

    onChangeTelqIdSettingsType(type: string) {
        this.telqIdSettingsModel.telqIdType = type;
        if (typeof TemplatesService.TELQ_ID_LENGTH_DEFAULT[type] !== 'undefined') {
            const length = TemplatesService.TELQ_ID_LENGTH_DEFAULT[type];
            this.telqIdSettingsModel.telqIdLength = length;
            this.telqIdSettingsForm.controls.telqIdLength.patchValue(length);
        }
        if (typeof TemplatesService.TELQ_ID_CASES_DEFAULT[type] !== 'undefined') {
            const caseId = TemplatesService.TELQ_ID_CASES_DEFAULT[type];
            this.telqIdSettingsModel.telqIdCase = caseId;
            this.telqIdSettingsForm.controls.telqIdCase.patchValue(caseId);
        }
    }

    getTelqIdSettingsFormValid() {
        return this.telqIdSettingsForm.valid;
    }

    telqIdSettingsShowCase(type) {
        return TemplatesService.showCaseForType(type);
    }

    telqIdSettingsShowLength(type) {
        return TemplatesService.showLengthForType(type);
    }

    onApplyTelqIdSettings() {
        if (!this.telqIdModal) {
            return;
        }
        if (this.telqIdSettingsModel.selectedText) {
            let selectedText = this.telqIdSettingsModel.selectedText;
            selectedText.telqIdLength = this.telqIdSettingsModel.telqIdLength;
            selectedText.telqIdCase = this.telqIdSettingsModel.telqIdCase;
            selectedText.telqIdType = this.telqIdSettingsModel.telqIdType;
        } else {
            this.textModel.telqIdLength = this.telqIdSettingsModel.telqIdLength;
            this.textModel.telqIdCase = this.telqIdSettingsModel.telqIdCase;
            this.textModel.telqIdType = this.telqIdSettingsModel.telqIdType;
            this.textLength = this.getTextLength();
        }
        this.telqIdModal.close(true);
    }

    onSaveAsDefault() {
        this.telqIdLoading = true;
        this.templateService.saveDefaultTelqIdDetails({
            telqIdType: this.telqIdSettingsModel.telqIdType,
            telqIdCase: this.telqIdSettingsModel.telqIdCase,
            telqIdLength: this.telqIdSettingsModel.telqIdLength
        }).subscribe({
            next: (_) => {
                this.notificationService.success('Default Test Id Text Set', 'Live Number Testing');
                this.telqIdLoading = false;
                const { telqIdType, telqIdCase, telqIdLength } = this.telqIdSettingsModel;
                this.telqIdDefault.telqIdLength = telqIdLength;
                this.telqIdDefault.telqIdCase = telqIdCase;
                this.telqIdDefault.telqIdType = telqIdType;
                this.onApplyTelqIdSettings();
            },
            error: e => {
                this.notificationService.error({
                    title: 'Live number testing',
                    message: 'An error occurred while setting default Test Id Text',
                    service: 'LNT',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                this.telqIdLoading = false;
            }
        });
    }

    onClickImportFromFile(): void {
        this.importContentTemplateFileList = null;
        this.contentTemplateImportModal = this.modal.alert().component(this.contentTemplateImportModalTpl).open();
    }

    onImportDragOver(event): void {
        event.preventDefault();
        event.stopPropagation();
        this.dragOver = true;
    }

    onImportDragLeave(event): void {
        event.preventDefault();
        event.stopPropagation();
        this.dragOver = false;
    }

    onImportDrop(event): void {
        event.preventDefault();
        event.stopPropagation();
        let files = event.dataTransfer.files;
        if (files.length) {
            this.importContentTemplateFileList = event.dataTransfer.files;
            this.contentTemplateImportModal = this.modal.alert().component(this.contentTemplateImportModalTpl).open();
        }
        this.dragOver = false;
    }

    mapSenderToAll(contentTemplates: ContentTextExcelItem[]): ContentTextExcelItem[] {
        const combinationContentTemplates: ContentTextExcelItem[] = [];
        contentTemplates.forEach(ct => {
            if (ct.senderId?.length > 0) {
                if (ct.mapSenderToAll) {
                    contentTemplates.forEach(ctf => {
                        if (ctf.text?.length > 0) {
                            const newContentText: ContentTextExcelItem = {
                                id: CustomUtils.generateRandomString(5),
                                senderId: ct.senderId,
                                description: ctf.description,
                                favourite: ctf.favourite,
                                telqIdType: ctf.telqIdType,
                                telqIdCase: ctf.telqIdCase,
                                telqIdLength: ctf.telqIdLength,
                                text: ctf.text,
                                messageType: ctf.messageType
                            }
                            combinationContentTemplates.push(newContentText);
                        }
                    });
                } else {
                    combinationContentTemplates.push(ct);
                }
            }
        });
        return combinationContentTemplates;
    }

    onImportContentTemplates(contentTemplates: ContentTextExcelItem[]): void {
        let importTemplates: ContentTemplate[] = [];
        contentTemplates = this.mapSenderToAll(contentTemplates);
        contentTemplates.forEach(ct => {
            let text: ContentTemplateText = {
                id: null,
                selected: true,
                value: ct.text,
                telqIdCase: ct.telqIdCase,
                telqIdLength: ct.telqIdLength,
                telqIdType: ct.telqIdType,
            }
            let exists = importTemplates.filter(_ => _.name === ct.senderId);
            if (exists.length) {
                exists[0].smsTemplateTextDtos.push(text);
            } else {
                importTemplates.push({
                    id: null,
                    name: ct.senderId,
                    selected: true,
                    expand: false,
                    description: null,
                    smsTemplateTextDtos: [text]
                });
            }
        });
        if (importTemplates.length) {
            this.contentTemplates = importTemplates;
            this.calculateCount();
        }

        this.contentTemplateImportModal.close();
    }

    private calculateCount() {
        const count = this.getContentTexts().length;
        if (count !== this.selectedCount) {
            this.selectedCount = count;
            this.changedCount.emit(count);
        }
    }

    private detectEncoding(text: string) {
        if (!text) {
            this.dataCoding = null;
            this.dataCodingLoading = false;
            return;
        }
        this.dataCodingLoading = true;
        this.lntService.detectEncoding(text).subscribe({
            next: (dataCoding: DataCoding) => {
                this.dataCoding = dataCoding;
                this.dataCodingLoading = false;
            },
            error: () => {
                this.dataCoding = null;
                this.dataCodingLoading = false;
            }
        });
    }

    getSimulatedTestId() {
        if (this.telqIdSettingsForm.valid) {
            const { telqIdCase, telqIdLength, telqIdType, text } = this.telqIdSettingsModel;
            this.simulatedTestId = CustomUtils.generateId(telqIdCase, telqIdLength, telqIdType, text);
        } else {
            this.simulatedTestId = 'N/A';
        }
    }

    showAutoCompleteContentTemplates(): boolean {
        const textModalValue = this.textModel.value?.trim();
        const isOnlyIdPresent = textModalValue === TextTemplateComponent.PLACEHOLDER_MARKER;
        if (isOnlyIdPresent) {
            return this.autoCompleteContentTemplates.length > 0 && isOnlyIdPresent;
        } else {
            return this.autoCompleteContentTemplates.length > 0 && textModalValue.length === 0;
        }
    }

    ngOnDestroy() {
        if (this.textSubscription) {
            this.textSubscription.unsubscribe();
        }
    }
}

