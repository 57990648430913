import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthUser, Role } from "./models/user.model";
import { HttpErrors } from './services/http-errors';
import { UsersService } from './services/users.service';
import { Observable, throwError, catchError } from "rxjs";
import { CustomUtils } from "./services/custom-utils";

type RequirePermission = 'mo-testing' | 'cli-testing';

@Injectable()
export class AuthGuard implements CanActivate {

    defaultUrl = ['/dashboard'];
    loginUrl = ['/login'];
    forbiddenUrl = ['/403'];

    constructor(public userService: UsersService, public router: Router) {
        HttpErrors.status.subscribe((status: number) => this.onHttpError(status));
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const roles: string[] = route.data.roles;
        const requirePermission: RequirePermission = typeof route.data.requirePermission === "undefined" ? null : route.data.requirePermission;
        if (roles.indexOf(Role.GUEST) !== -1 && !this.userService.isAuth) {
            return true;
        }
        return new Promise(resolve => {
            this.userService.getAuthUser().then(user => {
                const onlyGuests = roles.length === 1 && roles[0] === Role.GUEST && user.role !== Role.GUEST;
                if (onlyGuests) {
                    this.router.navigate(this.defaultUrl).then();
                    resolve(false);
                    return;
                }
                const canAccess = roles.indexOf(user.role) !== -1;
                if (!canAccess) {
                    this.router.navigate(this.forbiddenUrl).then();
                    resolve(canAccess);
                    return;
                }
                this.checkPermission(user, requirePermission).then(result => {
                    if (!result) {
                        this.router.navigate(this.forbiddenUrl).then();
                    }
                    resolve(result);
                });

            }).catch(e => {
                resolve(false);
            });
        });
    }

    onHttpError(status: number) {
        // server is not available
        if (status === 0 && !this.userService.isAuth && !this.isGuestCurrentUrl()) {
            this.router.navigate(this.loginUrl).then();
            return;
        }

        if (status === 401 && !this.isGuestCurrentUrl()) {
            if (this.userService.isAuth) {
                this.userService.clearAuthUser();
            }
            this.router.navigate(this.loginUrl).then();
        }
    }

    isGuestCurrentUrl() {
        let allowUrls = CustomUtils.getGuestUrls();
        let uri = this.getUri();
        for (let i in allowUrls) {
            let url = allowUrls[i];
            if (uri.indexOf(url) !== -1) {
                return true;
            }
        }

        return false;
    }

    private getUri(): string {
        return window.location.hash.split('?')[0].replace('#', '');
    }

    private checkPermission(user: AuthUser, permission?: RequirePermission): Promise<boolean> {
        return new Promise(resolve => {
            if (!permission) {
                resolve(true);
                return;
            }
            switch (permission) {
                case "mo-testing":
                    this.userService.isMoAvailableForUser().pipe(
                        catchError(e => {
                            resolve(false);
                            return throwError(() => e);
                        })
                    ).subscribe(res => resolve(res))
                    break;
                case "cli-testing":
                    this.userService.isVoiceAvailableForUser().pipe(
                        catchError(e => {
                            resolve(false);
                            return throwError(() => e);
                        })
                    ).subscribe(res => resolve(res))
                    break;
            }
        });
    }
}