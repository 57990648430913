
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CellClickedEvent, ColDef, ColumnMovedEvent, ColumnResizedEvent, ColumnVisibleEvent, GridReadyEvent, ICellRendererParams, RowSelectedEvent } from 'ag-grid-community';
import { debounceTime } from 'rxjs';
import { AGTableBase } from '../../shared/components/table/ag-table-base';
import { CellClickEvent } from "../../shared/components/table/table.component";
import { PhoneReport, PhoneReportCollection } from "../../shared/models/phone-report.model";
import { BrowserUtils } from "../../shared/services/browser-utils";
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { PhoneReportActionData, PhoneReportRequestParams, PhoneReportService } from "../../shared/services/phone-report.service";
import { UsersService } from '../../shared/services/users.service';
import { PhonesReportsTableActionsComponent } from './phones-reports-table-actions/phones-reports-table-actions.component';

@Component({
    selector: 'app-phone-reports-table',
    templateUrl: './phone-reports-table.component.html',
})

export class PhoneReportsTableComponent extends AGTableBase implements OnInit, OnDestroy {

    @Output() actions: EventEmitter<PhoneReportActionData> = new EventEmitter();
    @Output() onAfterDelete: EventEmitter<void> = new EventEmitter();
    @Output() changeBatch = new EventEmitter<PhoneReport[]>();

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;

    requestParams = new PhoneReportRequestParams();
    loading: boolean = true;
    batchSelectedIds: Set<number> = new Set();

    // ng grid
    columnDefs: ColDef[] = [
        {
            headerName: '', field: 'batch', checkboxSelection: true,
            maxWidth: 40, headerCheckboxSelection: true, pinned: 'left',
            lockPinned: true, lockPosition: 'left', lockVisible: true,
            suppressColumnsToolPanel: true, suppressMenu: true, suppressMovable: true
        },
        {
            headerName: 'ID', field: 'id',
            maxWidth: 80, minWidth: 80,
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.id}</div>`;
            }
        },
        {
            headerName: 'Date', field: 'createdAt',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.formatDate(_.createdAt)}</div>`;
            }
        },
        {
            headerName: 'User', field: 'username',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.formatUser(_)}</div>`;
            }
        },
        {
            headerName: 'Company', field: 'companyName',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.companyName}</div>`;
            }
        },
        {
            headerName: 'Phone', field: 'phone',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.phone}</div>`;
            }
        },
        {
            headerName: 'Issue', field: 'issue',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.service.issues[_.issue]}</div>`;
            }
        },
        {
            headerName: 'Description', field: 'description',
            sortable: true, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data.description;
                return `<div class="two-lines">${_ ? _ : ''}</div>`;
            }
        },
        {
            headerName: 'Actions', field: 'actions', maxWidth: 100, minWidth: 100,
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
            tooltipField: 'actions',
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: PhonesReportsTableActionsComponent,
            onCellClicked: (event: CellClickedEvent) => this.onActionClick(event),
        }
    ];

    rowData!: PhoneReport[];
    // ng grid

    constructor(
        public notificationService: NotificationService,
        public service: PhoneReportService,
        public userService: UsersService,
        public modal: ModalService,
        private datePipe: DatePipe,
        private localStorage: LocalStorageService
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        this.actionSubscription = this.service.action$.subscribe(
            action => {
                this.onAction(action);
            }
        );
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            const currentColumnState = this.gridApi.getColumnState();
            this.localStorage.set(`phone_report_table_state_${this.userService.authUser.id}`, currentColumnState);
        });
        this.paginationPageSize = this.localStorage.get(`phone_report_table_component_size_${this.userService.authUser.id}`) || 20;
    }

    onAction(event: PhoneReportActionData) {
        this.actions.emit(event);
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row);
                }
            });
        }
    }

    update() {
        this.loading = true;
        this.requestParams.size = this.paginationPageSize;
        this.requestParams.page = this.currentPageNumber - 1;
        this.requestParams.sort = this.sortState;

        this.service.all(this.requestParams).subscribe({
            next: (collection: PhoneReportCollection) => {
                this.rowData = collection.content;
                this.totalRowsCount = collection.totalElements;
                this.loading = false;
            },
            error: (error) => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Phone reports',
                    message: 'An error occurred while loading the reports',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            }
        });
    }

    delete(report: PhoneReport) {
        this.loading = true;
        this.service.delete(report.id).subscribe({
            next: () => {
                this.notificationService.success('Report removed', 'Phone reports');
                this.update();
            },
            error: (error) => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Phone reports',
                    message: 'An error occurred while loading the reports',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            }
        });
    }

    onCellClick(event: CellClickEvent) {
        if (event.column.prop === 'description') {
            this.modal.alert().size('sm')
                .isBlocking(false)
                .title('Description Report')
                .body(BrowserUtils.escapeHtml(event.row.data.description))
                .open();
        }
    }

    formatDate(date) {
        return this.datePipe.transform(date, 'dd MMM HH:mm:ss');
    }

    formatUser(row) {
        return row.userEmail + '<' + row.userFullName + '>'
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
        this.localStorage.set(`phone_report_table_component_size_${this.userService.authUser.id}`, size);
        this.update();
    }

    resetBatch() {
        this.batchSelectedIds.clear();
        this.gridApi.deselectAll();
    }

    // ng grid ...
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.update();
        const columnState = this.localStorage.get(`phone_report_table_state_${this.userService.authUser.id}`);
        this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
    }

    onRowSelected(event: RowSelectedEvent) {
        const selectedRows = this.gridApi.getSelectedRows();
        this.batchSelectedIds = new Set(selectedRows.map(row => row.id));
        this.changeBatch.emit(selectedRows);
    }
    // ng grid ...

    ngOnDestroy(): void {
        this.actionSubscription.unsubscribe();
    }
}