import { Component, ViewChild } from '@angular/core';
import { AppscriptsCliTableComponent } from '../appscripts-cli-table/appscripts-cli-table.component';
import { AppscriptsVoiceCall, AppscriptsVoiceCallsFilter } from '../../../shared/models/appscripts-sms.model';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-appscripts-cli-index',
    templateUrl: './appscripts-cli-index.component.html',
    styleUrls: ['./appscripts-cli-index.component.scss'],
})
export class AppscriptsCliIndexComponent {

    @ViewChild(AppscriptsCliTableComponent, { static: false }) appscriptsCliTableComponent: AppscriptsCliTableComponent;

    constructor(private titleService: Title) {
        titleService.setTitle('CLI');
    }

    onSubmitSearchForm(event: AppscriptsVoiceCallsFilter) {
        if (this.appscriptsCliTableComponent.loading) {
            return;
        }
        this.appscriptsCliTableComponent.currentPageNumber = 1;
        this.appscriptsCliTableComponent.requestParams.filter = event;
        this.appscriptsCliTableComponent.update();
    }

}
